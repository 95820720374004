import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "../components/navBar";
import TaxListBody from "../components/taxListBody";
import CurrentProviderComponent from "../components/currentProviderComponent";

const index = 5;

class TaxPage extends React.Component {
  render() {
    return (
      <div className="mainBox">
      <Navbar index={index} />
      <CurrentProviderComponent />
      <TaxListBody/>
    </div>
    )
  }
}


export default TaxPage;