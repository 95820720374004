import { createSlice } from "@reduxjs/toolkit";

export const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    currentContact: null,
    contactoRelacionado: null,
  },
  reducers: {
    setCurrentContact: (state, action) => {
      state.currentContact = action.payload
    },
    setContactoRelacionado: (state, action) => {
      state.contactoRelacionado = action.payload
    },

  },
});

export const { setCurrentContact, setContactoRelacionado } = contactsSlice.actions;
export default contactsSlice.reducer;