import React, { useState } from 'react';
import { Typography, makeStyles, Box, Button } from '@material-ui/core';
import DocumentsNoticesContext from '../contexts/documentsNoticesContext';
import { AlertTitle, Alert } from '@material-ui/lab';
import { uploadPdf } from './Utils/files';
const NoticesViewExt = (props) => {
  const { notice, saveConfirmity } = props
  const [fileState, setFileState] = useState(null);
  const [fileErrors, setFileErrors] = useState("");
  const useStyles = makeStyles(() => ({
    bold: {
      fontWeight: 'bold',
    },
    title: {
      fontWeight: 'bold',
      fontSize: '1.8rem',
      marginTop: '2%',
      marginLeft: '1%'
    },
    backButton: {
      display: 'inline-block',
      marginBottom: 16
    },
    bodyT: {
      fontSize: '1rem',
      marginTop: '0.5%',
      marginLeft: '1%'
    },
    btnActionStyle: {
      width: '200px',
      heigth: '50px',
      cursor: 'pointer',
      textTransform: 'capitalize',
      marginLeft: '1%',
      backgroundColor: '#009639',
      borderRadius: '5px',
      border: 'none',
      color: 'white',
      fontSize: '14px',
    },
    errorText: {
      color: '#ff1200',
    }
  }));

  const classes = useStyles();

  function getFiles() {
    let element = document.getElementById("inputFiles");
    element.click();
  }

  function sendFiles(document) {
    DocumentsNoticesContext.createDocumentNotice(document[0])
      .then((fileId) => {
        setFileState([]);
        saveConfirmity(fileId)
      });
  }

  const uploadFiles= async (files) => {
    let response = await uploadPdf(files);
    response.files = response.files.map(file => {
      return {
        ...file,
        Id_usuario_carga: JSON.parse(localStorage.getItem("user")).id_usuario,
      }
    })
    response.files = response.files.map(file => 
      { 
        return {
          Imagen: file.Imagen,
          Nombre_archivo: file.Nombre_archivo,
          Tipo_archivo: file.Tipo_archivo,
          Id_usuario_carga: file.Id_usuario_carga,
          Id_aviso: notice.id_aviso
        }
      }
    )
    setFileErrors(response.errors)
    setFileState(response.files);
  }

  return (
    notice ?
      <Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body1">{notice.adjunto}</Typography>
        </Box>
        <Box display="flex" alignItems={"center"} style={{ gap: 8 }} my={2}>
          <button className={classes.btnActionStyle} onClick={getFiles}>
            <input hidden className="inputUploadDigDoc" id="inputFiles" type="file" onChange={(e) => uploadFiles(e.target.files)}></input>
            Seleccionar archivos
          </button>
          <Typography variant="body1">
            {fileState?.[0]?.Nombre_archivo}
          </Typography>
        </Box>
        <Button className={classes.btnActionStyle} disabled={!fileState?.[0] || fileErrors.length > 0} onClick={() => sendFiles(fileState)} color="primary" variant="contained" disableElevation>
          <b>Finalizar</b>
        </Button>
        <Box mt={2}>
          { fileErrors.length > 0 && <Alert severity="error">
            <AlertTitle>Archivo no válido</AlertTitle>
            {fileErrors}
          </Alert>}
        </Box>
      </Box>
      :
      <Box></Box>
  );
};

export default NoticesViewExt;