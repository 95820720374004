
import { formatDateObjectToUS } from "../components/Utils/formatDisplay";
import api from "../services/api";

export class ContactContext {
  static async createContact(Pregunta: string, IdRelacion: number | null, Motivo: number, IdNotaPedido: number | null, Recepciones: any) {
    let IdUsuario: any = JSON.parse(window.localStorage.getItem("user") ?? "").id_usuario;
    let IdProveedor = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    console.log(Pregunta, IdRelacion, IdProveedor, IdUsuario, Motivo, IdNotaPedido, Recepciones)
    const response = await api.post(`/contacto/create`, { Pregunta, IdRelacion, IdProveedor, IdUsuario, Motivo, IdNotaPedido, Recepciones });
    if (response) return response.data;
    else return null;
  }

  static async fetchContacts() {
    let userId: any = JSON.parse(window.localStorage.getItem("user") ?? "").id_usuario;
    let providerId = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response = await api.get(`/contacto/getAllByProvUser`, { params: { providerId, userId } });
    if (response) return response.data;
    else return null;
  }

  static async fetchContactById(contactId: number) {
    let providerId = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response = await api.get(`/contacto/getByProvUserById`, { params: { providerId, contactId } });
    if (response) return response.data;
    else return null;
  }

  static async deleteContact(contactId: number) {
    const response = await api.delete(`/contacto/delete/${contactId}`);

    if (response?.data?.status) {
      return response.data;
    }
    else if (response?.data?.error === true) {
      console.error(`/contacto/delete`, response.data);
      return null;
    }
    else {
      return null;
    }
  }

  static async alertNewContactProv() {
    let lastLogin = JSON.parse(window.localStorage.getItem("user") || "").fecha_ult_ingreso;
    let providerId = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    lastLogin = formatDateObjectToUS(lastLogin);
    const response = await api.post(`/contacto/alertNewContactProv`, { lastLogin, providerId });
    if (response?.data?.status) {
      return response.data;
    }
    else if (response?.data?.error === true) {
      console.error(`/contacto/alertNewContactProv`, response.data);
      return null;
    } else {
      return null;
    }
  }
}

export default ContactContext;