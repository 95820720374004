import React from "react";
import "../resources/styles/navBar.css";
import { AppBar, Toolbar, makeStyles } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "./NavbarComponents/Logo";
import UserMenu from "./NavbarComponents/UserMenu";

const useStyles = makeStyles({
  style: {
    backgroundColor: "white",
    cursor: "pointer",
  },
  logoStyle: {
    maxWidth: "250px",
    margin: "-25px",
    display: "inlineBlock",
    marginLeft: "-15%",
  },
});

const NavbarNoTabs = () => {
  const classes = useStyles();

  return (
    <div className="navBarContainer">
      <AppBar
        elevation={0}
        position="fixed"
        color="primary"
        className={classes.style}
      >
        <Toolbar className="nav-maintenance">
          <Logo />
          <UserMenu />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavbarNoTabs;