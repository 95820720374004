import { makeStyles, Box } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";

const useStyles = makeStyles({
  pages: {
    display: "flex",
    justifyContent: "center",
    margin: "3em auto",
  },
  pageNumber: {
    fontWeight: "bold",
  },
});

const StyledPagination = (props) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.pages} ${props.className}`}>
      <Pagination
        count={props.pageCount}
        page={props.page}
        onChange={props.onChange}
        color="primary"
        renderItem={(item) => {
          return <PaginationItem className={classes.pageNumber} {...item} />;
        }}
      />
    </Box>
  );
};

export default StyledPagination;
