import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// Botón reusable con diseño principal

const useStyles = makeStyles({
  button: {
    padding: "0.5em 2em",
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  red: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
  grey:{
    backgroundColor: '#bdbdbd',
    "&:hover": {
      backgroundColor: '#9e9e9e',
    },
  },
  orange: {
    backgroundColor: '#ff9800',
    "&:hover": {
      backgroundColor: '#f57c00',
    },
  },
});

const BasicButton = (props) => {
  const classes = useStyles();

  const getColor = () => {
    switch (props.color) {
      case "red":
        return classes.red;
      case "grey":
        return classes.grey;
      case "orange":
        return classes.orange;
      case "warning":
        return classes.orange;
      default:
        return "";
    }
  };

  return (
    <Button
      className={`${classes.button} ${getColor()} ${props.className}`}
      color="primary"
      variant={props.variant || "contained"}
      onClick={props.onClick}
      disabled={props.disabled}
      size={props.size || "medium"}
    >
      <Typography>
        <strong>{props.children}</strong>
      </Typography>
    </Button>
  );
};

export default BasicButton;
