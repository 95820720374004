import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import TableFilter from "../UI/TableFilter";
import BasicButton from "../UI/BasicButton";
import BasicModal from "../UI/BasicModal";
import StyledPagination from "../UI/StyledPagination";
import LoadingIcon from "../UI/LoadingIcon";
import { formatCurrencyDisplay, convertDate } from "../Utils/formatDisplay";
import DocumentsContext from "../../contexts/documentsContext";
import StatesContext from "../../contexts/statesContext";

const useStyles = makeStyles({
  actionBar: {
    display: "flex",
    flexDirection: "row",
  },
  actionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "2em",
  },
  actionButton: {
    cursor: "pointer",
  },
  actionSearch: {
    flexGrow: "1",
    justifyContent: "flex-end",
  },
  searchInput: {
    maxWidth: "16em",
  },
  filterMenuBox: {
    display: "flex",
    flexDirection: "column",
  },
  filterMenuButtonBox: {
    display: "flex",
    flexDirection: "row",
  },
  filterMenuSelect: {
    width: "12em",
    marginBottom: "1em",
  },
  filterMenuButton: {
    marginRight: "0.5em",
  },
  tableHeaderText: {
    color: "gray",
    fontSize: 16,
    fontWeight: "bold",
  },
  tableCellText: {
    color: "gray",
    fontSize: 16,
    fontWeight: 600,
  },
  pages: {
    display: "flex",
    justifyContent: "center",
    margin: "3em auto",
  },
  pagesNumbers: {
    fontWeight: "bold",
  },
  modal: {
    width: "35em",
    height: "25em",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
  },
  modalBottomBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  scroller: {
    height: "170px",
    overflowY: "auto",
  }
});

const columns = [
  {
    id: "fecha_documento",
    label: "Fecha de doc.",
    minWidth: 125,
    align: "left",
  },
  {
    id: "Estado",
    label: "Estado",
    minWidth: 150,
    align: "left",
  },
  {
    id: "Tipo",
    label: "Tipo",
    minWidth: 100,
    align: "left",
  },
  {
    id: "Numero",
    label: "Numero",
    minWidth: 175,
    align: "left",
  },
  {
    id: "NP",
    label: "N.P.",
    minWidth: 150,
    align: "left",
  },
  {
    id: "Monto",
    label: "Monto",
    minWidth: 125,
    align: "left",
  },
  {
    id: "Detalle_pago",
    label: "Detalle del pago",
    minWidth: 75,
    align: "left",
  },
];

const MyDocuments = (props) => {
  const rowsPerPage = 7;

  const initialized = useRef(false);

  const [docs, setDocs] = useState([]);
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pageCount, setPageCount] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState({
    page: 1,
    id_estado: null,
    docNumber: "",
  });

  const [paymentDetails, setPaymentDetails] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (row) => {
    setPaymentDetails(row);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);
  const version = window.localStorage.getItem("version");
  const reportDocumentUrl = "/documents/report?v=" + version;
  const classes = useStyles();
  const history = useHistory();

  const fetchData = useCallback(async () => {
    const states = await StatesContext.fetchStates();

    const documentStates = states
      .filter((x) => x.id_tipo_estado === 2)
      .map((s) => {
        return { value: s.id_estado, label: s.descripcion_abreviada };
      });
    documentStates.unshift({ value: "", label: "Cualquiera" });

    setStates(documentStates);

    fetchFilteredData();
    initialized.current = true;
  }, []);

  const fetchFilteredData = async (filter = null) => {
    setIsLoading(true);
    const docsData = await DocumentsContext.fetchDocumentsViewPagination(
      filter?.page || 1,
      rowsPerPage,
      filter
    );

    if (docsData) {
      const processedDocs = [];
      docsData.data?.forEach((doc) => {
        if (!doc.numero_pago || !doc.estado_pago || !doc.detalle_pago_monto) {
          doc.numero_pago = "Sin detalle";
          doc.estado_pago = "Sin detalle";
          doc.detalle_pago_monto = "Sin detalle";
        }
        processedDocs.push(doc);
      });

      const docPageCount = Math.ceil(docsData.totalRecords / rowsPerPage);

      setPageCount(docPageCount);
      setDocs(processedDocs);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (initialized.current) {
      fetchFilteredData(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (initialized.current) {
      const timer = setTimeout(() => {
        searchCommitHandler(searchText);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchText]);

  const searchCommitHandler = (newValue) => {
    setFilter((previousFilter) => {
      return { ...previousFilter, page: 1, docNumber: newValue };
    });
  };
  const statusFilterHandler = (newFilter) => {
    setFilter((previousFilter) => {
      return { ...previousFilter, page: 1, id_estado: newFilter.status };
    });
  };
  const pageChangeHandler = (e, newPage) => {
    setFilter((previousFilter) => {
      return { ...previousFilter, page: newPage };
    });
  };

  const documentReportRedirect = () => {
    history.push(reportDocumentUrl);
  };

  return (
    <div>
      <Box mt={1} mb={2} className={classes.actionBar}>
        <Button
          size="large"
          className={`${classes.actionItem} ${classes.actionButton}`}
          onClick={documentReportRedirect}
          startIcon={<AssignmentReturnedIcon fontSize="large" />}
          disabled={docs.length === 0}
        >
          Reporte
        </Button>
        <TableFilter
          className={classes.actionSearch}
          options={states}
          currentSearch={searchText}
          searchPlaceholder="Buscar por N° de documento"
          onFilterChange={statusFilterHandler}
          onSearchChange={(value) => setSearchText(value)}
        />
      </Box>

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  className={classes.tableHeaderText}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {docs &&
              docs.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell className={classes.tableCellText}>
                    {convertDate(row.fecha_documento)}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.estado}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.tipo}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.numero_documento}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.nota_pedido}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {formatCurrencyDisplay(row.monto)}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Ver Detalles" placement="top" arrow>
                      <AspectRatioIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleOpenModal(row)}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            {docs.length === 0 && !isLoading && (
              <TableRow hover role="checkbox">
                <TableCell colSpan={columns.length}>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    No existen documentos para este proveedor.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <LoadingIcon />}

      {docs.length > 0 && (
        <StyledPagination
          page={filter.page}
          pageCount={pageCount}
          onChange={pageChangeHandler}
        />
      )}

      <BasicModal
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
      >
        <Typography variant="h4">Detalle del pago.</Typography>
        <Typography>{`Acerca del documento N° ${paymentDetails.numero_documento}`}</Typography>
        <div className={classes.modalBottomBox}>
          <TableContainer component={Paper} className={classes.scroller}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderText} align="center">N° de pago</TableCell>
                  <TableCell className={classes.tableHeaderText} align="center">Monto pagado</TableCell>
                  <TableCell className={classes.tableHeaderText} align="center">Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentDetails?.pagos_detalle?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCellText} scope="row" align="center">{row.numero_pago}</TableCell>
                    <TableCell className={classes.tableCellText} align="left">{isNaN(row.detalle_pago_monto) ? "-" : formatCurrencyDisplay(row.detalle_pago_monto)}</TableCell>
                    <TableCell className={classes.tableCellText} align="center">{row.estado_pago}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Box style={{ margin: "0 auto" }}>
          <BasicButton onClick={handleCloseModal}>Cerrar</BasicButton>
        </Box>
      </BasicModal>
    </div>
  );
};

export default MyDocuments;
