import { AxiosResponse } from "axios";
import api from "../services/api";

export class RecepcionContext {
  static async getRecepcionById(idNotaPedido: number) {
    const response: any = await api.get<Response, AxiosResponse<Response>>(
      `/recepcion/${idNotaPedido}`);
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  }
  static async getAllAvailable() {
    let prv: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response: any = await api.get<Response, AxiosResponse<Response>>(
      `/recepcion/getAvailable/${prv}`);
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  }

  static async getItems(idRecepcion : number) {
    const response: any = await api.get<Response, AxiosResponse<Response>>(
      `/recepcion/getItems/${idRecepcion}`);
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  }

  static async getRechazos(idItem : number) {
    const response: any = await api.get<Response, AxiosResponse<Response>>(
      `/recepcion/getRechazos/${idItem}`);
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  }
}

export default RecepcionContext;