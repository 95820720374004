import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import NoticesBody from "../components/noticesBody";
import Navbar from "../components/navBar";
import CurrentProviderComponent from "../components/currentProviderComponent";

const index = 4;

class NoticesPage extends React.Component {
  render() {
    return (
      <div className="mainBox">
        <Navbar index={index} />
        <CurrentProviderComponent />
        <NoticesBody />
      </div>
    );
  }
}
export default NoticesPage;
