import { AxiosResponse } from "axios";
import api from "../services/api";

export class AfipContext {
  static async ValidateQr(qrForm: any) {
    const response: any = await api.post<Response, AxiosResponse<Response>>(
      `/afip`, qrForm
    );
    if (response !== undefined && response.data !== undefined) {
      return response.data;
    } else {
      return null;
    }
  }
}