import api from "../services/api";

export class NoticesContext {
  static async fetchNoticesByCuit() {
    let providerCuit: any = JSON.parse(window.localStorage.getItem("provider") ?? "").cuit;
    const response = await api.get(
      `/notices/getByCuit`, {params:{ providerCuit }});
    if (response !== undefined && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async setModifyNotice(notice: any) {
    const response = await api.put(
      `/notices/modifyNotice`, notice);
    if (response !== undefined && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }
}

export default NoticesContext;