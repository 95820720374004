import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import Route from "./routes/routes";
import "./resources/styles/globalStyles.css";
import Footer from "./components/footer";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";

import { store } from './store/store'
import { Provider } from 'react-redux'

const container = document.getElementById("root");

// Theme para todo el sitio
const globalTheme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
    body2: {
      fontSize: 16,
      fontWeight: 600,
      color: "gray",
    },
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#009639",
    },
    secondary: {
      main: "#009639",
    },
    danger: {
      main: "#ff3933",
    }
  },
  overrides: {
    MuiInput: {
      root: {
        backgroundColor: "whitesmoke",
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "whitesmoke",
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: "whitesmoke",
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={globalTheme}>
      <div style={{ minHeight: "93vh" }}>
        <Route />
      </div>
      <Footer />
    </ThemeProvider>
  </Provider>,
  container
);
