import React, { useState, useEffect, Fragment } from 'react';
import {
  TableBody, TableCell, TableRow, makeStyles, TableContainer,
  TableHead, Table, Box, Typography, TextField, Paper, Checkbox, Tooltip
} from "@material-ui/core";
import StyledPagination from "./UI/StyledPagination";
import LoadingIcon from "./UI/LoadingIcon";
import DocumentosImpuestoContext from '../contexts/documentosImpuestoContext';
import VisitsContext from "../contexts/visitsContext";
import JSZip from "jszip";
import BasicButton from './UI/BasicButton';
import BasicModal from './UI/BasicModal';
import PDFBase64Viewer from './UI/PDFBase64Viewer';
import { convertDate } from "./Utils/formatDisplay";
import { GrView, GrDocumentDownload } from 'react-icons/gr';

const useStyles = makeStyles({
  actionSearch: {
    flexGrow: "1",
    justifyContent: "flex-end",
    margin: "0",
  },
  tableHeader: {
    color: "gray",
    fontSize: 16,
    fontWeight: "bold",
  },
  tableCell: {
    color: "gray",
    fontSize: 16,
    fontWeight: 600,
  },
  iconButton: {
    fontSize: 28,
    color: "#797a7a",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
  viewerModal: {
    width: "70%",
    height: "80%",
  }
});

export default function TaxListBody() {
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const rowsPerPage = 8;

  const [taxData, setTaxData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const [checkedTaxes, setCheckedTaxes] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [openDocView, setOpenDocView] = useState(false);
  const [pdfContent, setPdfContent] = useState("");
  const [pdfName, setPdfName] = useState("");
  const handleOpenDocView = () => setOpenDocView(true);
  const handleCloseDocView = () => setOpenDocView(false);

  async function fetchData() {
    setIsLoading(true);
    const data = await DocumentosImpuestoContext.getDocumentosImpuestoActive();
    setTaxData(data);
    VisitsContext.createVisit(8).then((e) => { });
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [])

  const downloadDocumentFromImage = (image, fileName) => {
    const linkSource = `data:application/pdf;base64,${image}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadFile = async (id) => {
    const response = await DocumentosImpuestoContext.downloadDocuments([id]);
    const file = response[0];
    downloadDocumentFromImage(file.file, file.fileName);
  };

  const downloadMultipleFiles = async (ids) => {
    const response = await DocumentosImpuestoContext.downloadDocuments(ids);
    if (response && response.length > 0) {
      let zip = new JSZip();
      for (const doc of response) {
        const formattedName = doc.fileName.slice(0, -4);
        zip.file(
          `${formattedName}_${doc.id}.pdf`,
          doc.file,
          { base64: true }
        );
      }
      const zipFile = await zip.generateAsync({ type: "base64" });
      const linkSource = `data:application/zip;base64,${zipFile}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "impuestos.zip";
      downloadLink.click();
    }
  }

  const viewDocumentHandler = async (id) => {
    const response = await DocumentosImpuestoContext.downloadDocuments([id]);
    if (response && response.length === 1) {
      setPdfContent(response[0].file);
      setPdfName(response[0].filename);
      handleOpenDocView();
    }
  };

  const downloadDocumentView = () => {
    downloadDocumentFromImage(pdfContent, pdfName);
  };

  const searchFilterHandler = (newSearch) => {
    setPage(1);
    setSearchFilter(newSearch);
  };
  const pageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const hasData = taxData?.length > 0;
  const firstIndexInPage = (page - 1) * rowsPerPage;

  const lowercaseSearch = searchFilter.toLowerCase();
  const searchFilteredData =
    searchFilter === ""
      ? taxData
      : taxData.filter((tax) => tax.jurisdiccion.descripcion.toLowerCase().includes(lowercaseSearch));

  const pageCount = Math.ceil(searchFilteredData?.length / rowsPerPage);
  const paginatedData = searchFilteredData?.slice(
    firstIndexInPage,
    firstIndexInPage + rowsPerPage
  );

  const checkTaxHandler = (e) => {
    const docId = +e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setCheckedTaxes((prev) => {
        if (prev.includes(docId)) return prev;
        else return [...prev, docId];
      });
    } else {
      setCheckedTaxes((prev) => {
        return prev.filter((x) => x !== docId);
      });
    }
    setCheckAll(false);
  };

  const checkAllHandler = (e) => {
    const isChecked = e.target.checked;
    setCheckAll(isChecked);
    if (isChecked) {
      const allIds = paginatedData.map((doc) => doc.id);
      setCheckedTaxes(allIds);
    } else {
      setCheckedTaxes([]);
    }
  };

  return (
    <Box my={8} style={{ minHeight: "59vh" }}>
      <Box mb={2}>
        <Box p={2} display="flex" justifyContent="center">
          <Typography variant="h4">
            Documentación impositiva vigente
          </Typography>
        </Box>
      </Box>
      <Box display="flex" mb={2} justifyContent="flex-end">
        <Box mb={2}>
          <TextField
            value={searchFilter}
            label="Búsqueda por jurisdicción"
            style={{ minWidth: "250px" }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => searchFilterHandler(e.target.value)}>
          </TextField>
        </Box>
      </Box>

      <TableContainer mt={2} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="left">Alcance</TableCell>
              <TableCell align="left">Impuesto</TableCell>
              <TableCell align="left">Jurisdicción</TableCell>
              <TableCell align="right">Porcentaje</TableCell>
              <TableCell align="left">Vigencia desde:</TableCell>
              <TableCell align="left">Vigencia hasta:</TableCell>
              <TableCell align="left">Última modificación</TableCell>
              <TableCell align="center">Archivo</TableCell>
              <TableCell align="center"> <Checkbox
                checked={checkAll}
                onChange={checkAllHandler}
              /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="documentTable">
            {hasData ?
              paginatedData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell className={classes.tableCell} align="left">{row.tipoImpuesto.descripcion_tipo} - {row.tipoImpuesto.descripcion_subtipo}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{row.alcance.descripcion}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{row.impuesto.descripcion}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{row.jurisdiccion.descripcion}</TableCell>
                  <TableCell className={classes.tableCell} align="right">{row.porcentaje && `${row.porcentaje.toFixed(2)}%`}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{convertDate(row.vigencia_desde)}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{convertDate(row.vigencia_hasta)}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{convertDate(row.fecha_um)}</TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <Box display="flex" flexDirection="row" style={{gap:10}} justifyContent="space-evenly">
                      <Tooltip title="Ver Documento" placement='top' arrow>
                        <Box>
                          <GrView className={classes.iconButton} onClick={() => viewDocumentHandler(row.id)} />
                        </Box>
                      </Tooltip>
                      <Tooltip title="Descargar Documento" placement="top" arrow>
                        <Box>
                          <GrDocumentDownload className={classes.iconButton} onClick={() => downloadFile(row.id)} />
                        </Box>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center"><Checkbox
                    value={row.id}
                    checked={checkedTaxes.includes(row.id)}
                    onChange={checkTaxHandler}
                  /></TableCell>
                </TableRow>
              ))
              :
              <TableRow>
                <TableCell colSpan={12} align="center" >
                  <strong>No hay impuestos disponibles</strong>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <LoadingIcon />}

      <StyledPagination
        page={page}
        pageCount={pageCount}
        onChange={pageChangeHandler}
      />

      {checkedTaxes.length > 1 && (
        <Fragment>
          <Box
            mt={3}
            mb={6}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <BasicButton onClick={() => downloadMultipleFiles(checkedTaxes)}>
              Descargar seleccionados como zip
            </BasicButton>
          </Box>
        </Fragment>
      )}

      <BasicModal
        className={classes.viewerModal}
        open={openDocView}
        onClose={handleCloseDocView}
      >
        <Typography variant="body2">{`Previsualizando: ${pdfName}`}</Typography>
        <Box my={2} style={{ width: "100%", height: "100%" }}>
          <PDFBase64Viewer fileContent={pdfContent} fileName={pdfName} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          style={{ width: "350px" }}
        >
          <BasicButton onClick={() => downloadDocumentView()}>
            Descargar
          </BasicButton>
          <BasicButton variant="outlined" onClick={() => handleCloseDocView()}>
            Cerrar
          </BasicButton>
        </Box>
      </BasicModal>
    </Box>
  )
}
