import api from "../services/api";
import { AxiosResponse } from "axios";
import QueryString from "qs";

interface Response {
  data: IDocuments;
}

export interface IDocuments {
  Id_documento: any;
  Id_proveedor: any;
  Id_tipo_documento: any;
  Numero_pago: any;
  Letra_documento: any;
  Prefijo_documento: any;
  Numero_documento: any;
  Fecha_documento: any;
  Monto: any;
  Nota_pedido: any;
  Id_estado: any;
  estado: any;
  tipo: any;
  detalle_pago_monto: any;
  fecha_actualizacion: any;
}

export class DocumentsContext {
  static async fetchDocumentsPagination(PageNumber: number = 1, PageSize: number = 10, filter: any = null) {
    let ProviderCuit: any = JSON.parse(window.localStorage.getItem("provider") ?? "").cuit;
    const FileName = filter?.fileName || null;
    const StateId = filter?.id_estado || null;
    const FromDate = filter?.fromDate ? `${filter?.fromDate.split("-")[1]}/${filter?.fromDate.split("-")[2]}/${filter?.fromDate.split("-")[0]}` : null;
    const ToDate = filter?.toDate ? `${filter?.toDate.split("-")[1]}/${filter?.toDate.split("-")[2]}/${filter?.toDate.split("-")[0]}` : null;
    const NumeroComprobante = filter?.numero_comprobante || null;
    const response = await api.get(
      `/digitaldocuments/getByIdPag`,
      { params: { PageSize, PageNumber, ProviderCuit, FileName, StateId, FromDate, ToDate, NumeroComprobante } });
    if (response && response.data) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async fetchDocumentsViewById(pageNumber: number = 1, pageSize: number = 10) {
    let providerCuit: any = JSON.parse(window.localStorage.getItem("provider") || "").cuit;
    const response = await api.get(`/digitaldocuments/getViewById`, { params: { pageSize, pageNumber, providerCuit } });
    if (response && response.data) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async setDocument(files: any) {
    const response = await api.post<Response, AxiosResponse<Response>>(`/digitalDocuments/set`, files);
    return response.data
  }

  static async setDocumentComex(idDoc: number, comex: boolean) {
    const response = await api.put(`/digitalDocuments/comex/${idDoc}/${comex}`);
    return response.data
  }

  static async setDocumentNotAfip(files: any) {
    const response = await api.post<Response, AxiosResponse<Response>>(
      `/digitalDocuments/saveNotAfip`,
      files
    );
    return response.data
  }

  static async downloadDocuments(documentsIds: any) {
    const response = await api.get(`/digitalDocuments/download`, {
      params: { documentsIds },
      paramsSerializer: params => { return QueryString.stringify(params, { arrayFormat: 'repeat' }) }
    });
    if (response) return response.data;
    else return null;
  }

  static async setDigDogIncorporated(idDocument: number) {
    const response = await api.put(`/digitaldocuments/setDigDogIncorporated/${idDocument}`);
    if (response?.data?.status) {
      return response.data;
    }
    else {
      console.log(`/digitaldocuments/setDigDogIncorporated`, response.data)
      return null;
    }
  }

  static async changeDigDocState(idDocument: number, stateId: number) {
    const response = await api.put(`/digitaldocuments/changeDigDogState/${idDocument}/${stateId}`);
    if (response?.data?.status) {
      return response.data;
    }
    else {
      console.log(`/digitaldocuments/setDigDogIncorporated`, response.data)
      return null;
    }
  }
}

export default DocumentsContext;
