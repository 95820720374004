import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { TextField, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BasicButton from "./UI/BasicButton";
import BasicModal from "./UI/BasicModal";
import BackButton from "./UI/BackButton";
import LoadingIcon from "./UI/LoadingIcon";
import ContactContext from "../contexts/contactContext";
import { ContactoMotivoContext } from "../contexts/contactoMotivoContext";
import { useSelector, useDispatch } from "react-redux";
import { setContactoRelacionado } from "../store/contacts/contactsSlice";
import {
  setNotaPedidoForContact,
  setSelectedNotaPedido,
} from "../store/documents/documentsSlice";
import documentoElectronicoQrContext from "../contexts/documentoElectronicoQrContext";

const useStyles = makeStyles({
  formBody: {
    width: "80%",
    minWidth: "300px",
    margin: "15px auto 20px auto",
    display: "flex",
    flexDirection: "column",
  },
  formElement: {
    marginBottom: "1em",
  },
  formButton: {
    width: "12em",
    margin: "0 auto",
  },
  modal: {
    maxWidth: "450px",
  },
  formInput: {
    marginBottom: ".75em",
  },
  button: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
  },
  buttonError: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
    backgroundColor: "#ff3933",
    "&:hover": {
      backgroundColor: "red",
    },
  },
});

export default function ContactFormBodyNew({ modal }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const version = window.localStorage.getItem("version");
  const provider_id = JSON.parse(
    window.localStorage.getItem("provider")
  ).id_proveedor;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [modalMsg, setModalMsg] = useState("");
  const [motivos, setMotivos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newMsg, setNewMsg] = useState({
    pregunta: "",
    id_relacion: null,
    motivo: "",
  });
  const [createResponse, setCreateResponse] = useState(false);
  const [preguntaError, setPreguntaError] = useState(false);
  const [motivoError, setMotivoError] = useState(false);

  const notaPedidoForContact = useSelector(
    (state) => state.documents.notaPedidoForContact
  );
  const selectedRecepciones = useSelector(
    (state) => state.documents.selectedRecepciones
  );
  const selectedQr = useSelector((state) => state.documents.selectedQr);
  useEffect(() => {
    fetchData();
  }, []);

  const deleteDocument = async () => {
    const qr = await documentoElectronicoQrContext.fetchDigDocsQrd(provider_id);
    const qrDoc = qr.filter(
      (item) => item.id_documento === selectedQr?.id_documento_electronico
    )[0].id;
    const response = await documentoElectronicoQrContext.delete(
      qrDoc,
      selectedQr?.id_documento_electronico
    );
  };

  const goToContacts = async () => {
    if (createResponse) {
      dispatch(setContactoRelacionado(null));
      dispatch(setSelectedNotaPedido(null));
      dispatch(setNotaPedidoForContact(null));
      await deleteDocument();
      history.push("/contact?v=" + version);
    }
    handleCloseModal();
  };

  const goToDocuments = async () => {
    if (createResponse) {
      dispatch(setContactoRelacionado(null));
      dispatch(setSelectedNotaPedido(null));
      dispatch(setNotaPedidoForContact(null));
      await deleteDocument();
      history.push("/documents/upload?v=" + version);
    }
    handleCloseModal();
  };
  const contacto_relacionado = useSelector(
    (state) => state.contacts.contactoRelacionado
  );
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    if (contacto_relacionado) {
      setNewMsg({
        pregunta: "",
        id_relacion: contacto_relacionado?.id,
        motivo: contacto_relacionado?.contactoMotivo?.id,
      });
      contacto_relacionado?.contactoMotivo?.id && setMotivoError(false);
    }

    const dataMotivo = await ContactoMotivoContext.fetchContactReasons();
    const motivo = dataMotivo?.map((m) => {
      return {
        value: m.id,
        label: m.descripcion,
        areaIdResponsable: m.id_area_responsable,
        ingresoDocumento: m.ingresoDocumento,
        area: m.area.denominacion,
        responsable: m.responsableArea
          ? m.responsableArea
          : m.area.usuarios.mail,
      };
    });
    motivo.unshift({ value: "", label: "Debe seleccionar el motivo" });
    setMotivos(motivo);

    setIsLoading(false);
  }, []);

  const createContact = async () => {
    const isDataEmpty = !newMsg.pregunta || !newMsg.motivo;
    if (preguntaError || motivoError || isDataEmpty) {
      setModalMsg(
        "Debe completar todos los campos para poder enviar el mensaje."
      );
      handleOpenModal();
    } else {
      const response = await ContactContext.createContact(
        newMsg.pregunta,
        parseInt(newMsg.id_relacion),
        parseInt(newMsg.motivo),
        notaPedidoForContact?.id,
        selectedRecepciones
      );
      setCreateResponse(response);
      if (response?.status) {
        setModalMsg("Contacto generado con éxito");
        handleOpenModal();
      } else {
        setModalMsg("Error al generar el contacto.");
        handleOpenModal();
      }
    }
  };

  const handleChange = (name, value) => {
    setNewMsg((newMsg) => {
      return { ...newMsg, [name]: value };
    });
    if (name === "pregunta") {
      value === "" || value.lenght === 0
        ? setPreguntaError(true)
        : setPreguntaError(false);
    }
    if (name === "motivo") {
      value === "" ? setMotivoError(true) : setMotivoError(false);
    }
  };

  const goToContactPage = () => {
    history.push("/contact?v=" + version);
  };

  return (
    <Box m={6}>
      {!modal && <BackButton onClick={goToContactPage} />}
      <Box className={classes.formBody}>
        <Typography variant="h5" component="h1" className={classes.formElement}>
          Contáctanos.
        </Typography>
        <Typography className={classes.formElement}>
          Ud. puede hacernos conocer sus inquitudes, las cuales serán
          respondidas a la brevedad.
        </Typography>
        {motivos && (
          <Box display="flex" flexDirection="column" alignContent="center">
            <TextField
              id="outlined-name"
              label="Motivo"
              value={newMsg?.motivo}
              select
              onChange={(e) => handleChange("motivo", e.target.value)}
              variant="outlined"
              className={classes.formInput}
              required
              InputLabelProps={{ shrink: true }}
              error={Boolean(motivoError)}
              helperText={motivoError && "Debe seleccionar un motivo."}
              SelectProps={{
                native: true,
              }}
            >
              {motivos?.map((option, index) => {
                if (!modal || option.ingresoDocumento || index === 0) {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                }
              })}
            </TextField>
            {newMsg?.motivo && newMsg?.motivo !== "" && (
              <Box className={classes.formElement}>
                <Typography>
                  <b>Area:</b>
                  {" " +
                    (motivos.find((m) => m.value === parseInt(newMsg.motivo))
                      ?.area || "No disponible")}
                </Typography>
                <Typography>
                  <b>Usuario responsable:</b>
                  {" " +
                    (motivos.find((m) => m.value === parseInt(newMsg.motivo))
                      ?.responsable || "No disponible")}
                </Typography>
              </Box>
            )}
            <TextField
              id="outlined-name"
              label="Mensaje (max. 500 caracteres)"
              value={newMsg.pregunta}
              onChange={(e) => handleChange("pregunta", e.target.value)}
              variant="outlined"
              className={classes.formInput}
              required
              rows={8}
              multiline
              inputProps={{
                maxLength: 500,
              }}
              error={Boolean(preguntaError)}
              helperText={
                preguntaError &&
                "Debe ingresar un mensaje de hasta 500 caracteres"
              }
            />
            <BasicButton className={classes.formButton} onClick={createContact}>
              Enviar
            </BasicButton>
          </Box>
        )}

        {isLoading && <LoadingIcon />}

        <BasicModal
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
        >
          {modalMsg}
          <BasicButton
            className={createResponse ? classes.button : classes.buttonError}
            onClick={goToContacts}
          >
            {!modal || !createResponse ? "Cerrar" : "Ir a Contactos"}
          </BasicButton>
          {modal && createResponse && (
            <BasicButton
              className={createResponse ? classes.button : classes.buttonError}
              onClick={goToDocuments}
            >
              Ir a Documentos
            </BasicButton>
          )}
        </BasicModal>
      </Box>
    </Box>
  );
}
