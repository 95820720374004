import React, { useState, useEffect, useCallback } from "react";
import "../resources/styles/providerSelectBody.css";
import {
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  TableContainer,
  TableHead,
  Table,
  Box,
  Typography,
} from "@material-ui/core";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { formatCuitDisplay } from "./Utils/formatDisplay";
import TableFilter from "./UI/TableFilter";
import LoadingIcon from "./UI/LoadingIcon";
import StyledPagination from "./UI/StyledPagination";

const useStyles = makeStyles({
  searchBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "2em 0",
  },
  selectButton: {
    cursor: "pointer",
    color: "#009639",
    "&:hover": {
      color: "red",
    },
  },
});

const columns = [
  {
    id: "proveedor",
    label: "Proveedor",
  },
  {
    id: "razon_social",
    label: "Razón social",
  },
  {
    id: "cuit",
    label: "CUIT",
  },
  {
    id: "select",
    label: "",
  },
];

export default function ProviderSelectBody() {
  const classes = useStyles();
  const history = useHistory();

  const [allProviders, setAllProviders] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const rowsPerPage = 6;

  const searchFilterHandler = (newSearch) => {
    setSearchFilter(newSearch);
  };
  const pageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const fetchData = useCallback(async () => {
    const currentUser = JSON.parse(window.localStorage.getItem("user"));
    if (currentUser) {
      const providers = currentUser.usersAssignments.map((assign) => {
        return {
          ...assign.providers,
          cuitString: formatCuitDisplay(assign.providers.cuit),
        };
      });

      const sortedProviders = providers
        .filter((prv) => prv.id_proveedor > 0)
        ?.sort((a, b) => {
          if (a.razon_social < b.razon_social) return -1;
          if (a.razon_social > b.razon_social) return 1;
          return 0;
        });

      setAllProviders(sortedProviders || []);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function ProviderSelected(row) {
    window.localStorage.setItem("provider", JSON.stringify(row))
    let version = window.localStorage.getItem("version");
    history.push("/portal/providers?v="+version);
  }

  const hasData = allProviders.length > 0;
  const firstIndexInPage = (page - 1) * rowsPerPage;

  const lowercaseSearch = searchFilter.toLowerCase();
  const searchFilteredData =
    searchFilter === ""
      ? allProviders
      : allProviders.filter(
          (prov) =>
            prov.codigo_proveedor.toString().includes(lowercaseSearch) ||
            prov.cuit.toString().includes(lowercaseSearch) ||
            prov.cuitString.includes(lowercaseSearch) ||
            prov.razon_social.toLowerCase().includes(lowercaseSearch)
        );

  const pageCount = Math.ceil(searchFilteredData.length / rowsPerPage);
  const paginatedData = searchFilteredData.slice(
    firstIndexInPage,
    firstIndexInPage + rowsPerPage
  );

  return (
    <div>
      <Typography variant="h4" component="h1">
        Seleccionar proveedor.
      </Typography>

      <Box className={classes.searchBox}>
        <TableFilter
          currentSearch={searchFilter}
          searchPlaceholder="Buscar proveedor"
          onSearchChange={searchFilterHandler}
        />
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.id}>{col.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {hasData &&
              paginatedData.map((row) => (
                <TableRow key={row.id_proveedor}>
                  <TableCell>{row.codigo_proveedor}</TableCell>
                  <TableCell>{row.razon_social}</TableCell>
                  <TableCell>{row.cuitString}</TableCell>
                  <TableCell>
                    <IoChevronForwardCircleSharp
                      className={classes.selectButton}
                      size="32"
                      onClick={() => ProviderSelected(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {!hasData && !isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    Este usuario no registra proveedores asociados.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <LoadingIcon />}

      {hasData && (
        <StyledPagination
          page={page}
          pageCount={pageCount}
          onChange={pageChangeHandler}
        />
      )}
    </div>
  );
}
