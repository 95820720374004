import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { IoIosArrowBack } from "react-icons/io";

const useStyles = makeStyles({
  backButton: {
    cursor: "pointer",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    margin: "1em 0",
  },
  backIcon: {
    fontSize: 32,
    marginRight: "4px"
  },
});

const BackButton = (props) => {
  const classes = useStyles();

  const ClickHandler = () => {
    props.onClick();
  };

  return (
    <Box className={classes.backButton} onClick={ClickHandler}>
      <IoIosArrowBack className={classes.backIcon} />
      <Typography variant="h6" component="span">
        Volver
      </Typography>
    </Box>
  );
};

export default BackButton;