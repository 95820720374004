import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import DocumentBody from "../components/documentBody";
import Navbar from "../components/navBar";
import CurrentProviderComponent from "../components/currentProviderComponent";

const index = 2;

class DocumentPage extends React.Component {
  render() {
    return (
      <div className="mainBox">
        <Navbar index={index} />
        <CurrentProviderComponent />
        <DocumentBody />
      </div>
    );
  }
}
export default DocumentPage;