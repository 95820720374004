import api from "../services/api";

export class DocumentsContext {
  static async fetchDocumentsView() {
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;   
    const response = await api.get(`/documents/getViewById`, {
      params: { providerId }
    });
        if (response !== undefined && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async fetchDocumentsViewPayment(id_pago: number) {
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    let paymentId: any = id_pago || null;
    const response = await api.get(
      `/documents/getViewByIdPayments`,{params: { providerId, paymentId }});
      if (response !== undefined && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async fetchDocumentsViewPagination(pageNumber: number = 1, pageSize: number = 10, filter: any = null) {
    const providerId: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const stateId = filter?.id_estado || null;
    const documentFilter = filter?.docNumber || null;
    const response = await api.get(
      `/documents/getViewByIdPag`, {params: { pageSize, pageNumber, providerId, stateId, documentFilter }});
    if (response && response.data) {
      return response.data;
    }
    else {
      return null;
    }
  }
}

export default DocumentsContext;