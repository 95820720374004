import { AxiosResponse } from "axios";
import api from "../services/api";

export class PieImpuestoContext {

  static async fetchAll(qr: any) {
    const response: any = await api.get<Response, AxiosResponse<Response>>(
        `/pieimpuesto`);
    if (response && response.data) {
        return response.data;
    } else {
        return null;
    }
}

}