import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Typography, makeStyles, Box, Link, CircularProgress, useTheme, Divider, Checkbox, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import NoticesViewExt from './noticesViewExt';
import NoticesContext from '../contexts/noticesContext';
import DocumentsNoticesContext from '../contexts/documentsNoticesContext';
import ConformityProvidedContext from '../contexts/conformityProvidedContext'
import useQuery from '../hooks/useQuery';
import BasicModal from "./UI/BasicModal";
import BasicButton from "./UI/BasicButton";
import BackButton from "./UI/BackButton";
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNotice } from '../store/notices/noticesSlice';

const CommonNoticesView = (props) => {
	const dispatch = useDispatch()

	const [notice, setNotice] = useState(null);
	const [noticeDocument, setNoticeDocument] = useState(null)
	const [loadingDocument, setLoadingDocument] = useState(false)
	const [showAccordance, setShowAccordance] = useState(false)
	const [checked, setChecked] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => {
		setOpenModal(false);
		goToPreviousPage();
	}

	const { show } = useQuery();
	const mustRenderAccordance = show === 'accordance';

	const hasConformity = Boolean(notice?.id_conformidad_brindada);

	const theme = useTheme();
	const history = useHistory();

	useEffect(() => {
		GetNotice();
	}, []);

	useEffect(() => {
		if (notice?.id_documento_adjunto && !noticeDocument) {
			setLoadingDocument(true)
			DocumentsNoticesContext.fetchDocumentsNoticesById(notice.id_documento_adjunto)
				.then((file) => {
					setNoticeDocument(file?.data[0])
				})
				.finally(() => setLoadingDocument(false))
		}
	}, [notice, noticeDocument])
	const currentNotice = useSelector(state => state.notices.currentNotice)
	const GetNotice = () => {
		setNotice(currentNotice);
	}

	const useStyles = makeStyles(() => ({
		title: {
			fontWeight: 'bold',
			fontSize: '1.8rem',
		},
		btnActionStyle: {
			width: '200px',
			heigth: '35px',
			cursor: 'pointer',
			textTransform: 'capitalize',
			marginLeft: '-0.5%'
		},
		btnActionStyle2: {
			width: '200px',
			heigth: '50px',
			cursor: 'pointer',
			textTransform: 'capitalize',
			marginLeft: '0%',
			backgroundColor: '#009639',
			borderRadius: '5px',
			border: 'none',
			color: 'white',
			fontSize: '14px'
		},
		button: {
			width: "20em",
			height: "3em",
			marginTop: "1em",
		},
		modalText: {
			marginBottom: "2em",
		},
	}));

	const classes = useStyles();

	const goToPreviousPage = () => {
		history.goBack()
	}

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const saveConfirmity = (fileId) => {
		let conformity = {
			Mail: JSON.parse(localStorage.getItem("user")).mail,
			Cuit: JSON.parse(localStorage.getItem("provider")).cuit,
			IdAdjunto: fileId || null,
			IdAviso: notice.id_aviso
		}

		ConformityProvidedContext.setConformityProvided(conformity)
			.then((conformityId) => {
				const newNotice = { ...notice, id_conformidad_brindada: conformityId }
				NoticesContext.setModifyNotice(newNotice)
					.then(() => {
						setNotice(newNotice)
						dispatch(setCurrentNotice(newNotice))
						handleOpenModal();
					})
			})
	}

	function setAccordance() {
		if (notice?.adjunto != "" || notice?.adjunto) {
			setShowAccordance(true)
		} else {
			saveConfirmity(null)
		}
	}

	return (
		notice
			?
			<Box m={6} style={{ minHeight: "69vh" }}>
				<BackButton onClick={goToPreviousPage} />
				<Typography className={classes.title}>{notice.titulo_aviso}</Typography>
				<Typography variant="h6">{notice.cuerpo_aviso}</Typography>
				{
					notice.id_documento_adjunto &&
					<Box>
						{
							loadingDocument
								?
								<Box color={theme.palette.text.disabled} display="flex" alignItems="center" style={{ gap: 8 }}>
									Cargando adjunto
									<CircularProgress color="inherit" size={16} />
								</Box>
								:
								<Link
									disabled={!noticeDocument}
									download={noticeDocument?.nombre_archivo}
									href={`data:${noticeDocument?.tipo_archivo};base64,${noticeDocument?.imagen}`}
								>
									Descargar adjunto
									<GetAppIcon className={classes.iconButton} />
								</Link>
						}
					</Box>
				}
				{
					mustRenderAccordance &&
					<>
						<Box my={2}>
							<Divider />
						</Box>
						{
							notice?.conformidad && !showAccordance && !hasConformity &&
							<>
								<Box display="flex" alignItems="center">
									<Checkbox
										checked={checked}
										onChange={handleChange}
									/>
									<Typography variant="h6">{notice.conformidad}</Typography>
								</Box>
								<Button className={classes.btnActionStyle2} disabled={!checked} onClick={setAccordance} color="primary" variant="contained">
									<b>Brindar conformidad</b>
								</Button>
							</>
						}
						{
							(showAccordance || notice?.conformidad.length === 0) && !hasConformity &&
							<NoticesViewExt
								notice={notice}
								saveConfirmity={saveConfirmity}
							/>
						}
						{
							hasConformity &&
							<Box>
								<Typography variant="h6">
									Conformidad ya brindada
								</Typography>
							</Box>
						}
					</>
				}
				<BasicModal open={openModal} onClose={handleCloseModal}>
					<Box display="flex" flexDirection="column" alignContent="center" justifyContent="center" >
						<Typography variant="h5">¡Listo!</Typography>
						<Typography className={classes.modalText} variant="body1">Conformidad brindada.</Typography>
					</Box>
					<BasicButton className={classes.button} onClick={handleCloseModal}>
						Continuar
					</BasicButton>
				</BasicModal>
			</Box>
			:
			<Box></Box>
	)
}

export default CommonNoticesView;