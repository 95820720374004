import { createSlice } from "@reduxjs/toolkit";

export const noticesSlice = createSlice({
  name: "notices",
  initialState: {
    currentNotice: null,
  },
  reducers: {
    setCurrentNotice: (state, action) => {
      state.currentNotice = action.payload
    },
  },
});

export const { setCurrentNotice } = noticesSlice.actions;
export default noticesSlice.reducer;