import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Tooltip,
} from "@material-ui/core";
import { GrDocumentDownload } from "react-icons/gr";
import { formatCurrencyDisplay, convertDate } from "../Utils/formatDisplay";
import TableFilter from "../UI/TableFilter";
import StyledPagination from "../UI/StyledPagination";
import LoadingIcon from "../UI/LoadingIcon";
import PaymentsFormsContext from "../../contexts/paymentsFormsContext";

const useStyles = makeStyles({
  actionBar: {
    display: "flex",
    flexDirection: "row",
  },
  actionSearch: {
    flexGrow: "1",
    justifyContent: "flex-end",
  },
  tableHeaderText: {
    color: "gray",
    fontSize: 16,
    fontWeight: "bold",
  },
  tableCellText: {
    color: "gray",
    fontSize: 16,
    fontWeight: 600,
  },
  pages: {
    display: "flex",
    justifyContent: "center",
    margin: "3em auto",
  },
  pagesNumbers: {
    fontWeight: "bold",
  },
});

const columns = [
  {
    id: "numero_pago",
    label: "Número de pago",
    minWidth: 150,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "fecha_emision",
    label: "Fecha de emisión",
    minWidth: 150,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "fecha_pago",
    label: "Fecha de pago",
    minWidth: 150,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "tipo",
    label: "Tipo",
    minWidth: 150,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "numero",
    label: "Número",
    minWidth: 150,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "importe",
    label: "Importe",
    minWidth: 150,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "comprobante",
    label: "Comprobante",
    minWidth: 75,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

const MyRetentions = (props) => {
  const rowsPerPage = 7;

  const initialized = useRef(false);

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState({
    page: 1,
    fromDate: "",
    toDate: "",
    search: "",
  });

  const classes = useStyles();

  const fetchFilteredData = async (filter = null) => {
    setIsLoading(true);

    const payFormsData =
      await PaymentsFormsContext.fetchPaymentsFormsByProvider(
        filter?.page || 1,
        rowsPerPage,
        filter
      );

    if (payFormsData) {
      const docPageCount = Math.ceil(payFormsData.totalRecords / rowsPerPage);

      setPageCount(docPageCount);
      setTableData(payFormsData.data);
    }
    initialized.current = true;
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFilteredData();
  }, []);

  useEffect(() => {
    if (initialized.current) {
      fetchFilteredData(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (initialized.current) {
      const timer = setTimeout(() => {
        searchCommitHandler(searchText);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchText]);

  const searchCommitHandler = (newValue) => {
    setFilter((previousFilter) => {
      return { ...previousFilter, page: 1, search: newValue };
    });
  };
  const dateFilterHandler = (newFilter) => {
    setFilter((previousFilter) => {
      return {
        ...previousFilter,
        page: 1,
        fromDate: newFilter.startDate,
        toDate: newFilter.endDate,
      };
    });
  };
  const pageChangeHandler = (e, newPage) => {
    setFilter((previousFilter) => {
      return { ...previousFilter, page: newPage };
    });
  };

  return (
    <div>
      <Box mt={1} mb={2} className={classes.actionBar}>
        <Typography variant="h5" component="p">
          Según detalle de "Formas de pago".
        </Typography>
        <TableFilter
          className={classes.actionSearch}
          currentSearch={searchText}
          searchPlaceholder="Buscar por N° de OP o tipo"
          onFilterChange={dateFilterHandler}
          onSearchChange={(value) => setSearchText(value)}
          showDatePicker
        />
      </Box>

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.tableHeaderText}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id_pagos_formas}
                  >
                    <TableCell className={classes.tableCellText}>
                      {row.numero_pago}
                    </TableCell>
                    <TableCell className={classes.tableCellText}>
                      {convertDate(row.fecha_emision)}
                    </TableCell>
                    <TableCell className={classes.tableCellText}>
                      {convertDate(row.fecha_pago)}
                    </TableCell>
                    <TableCell className={classes.tableCellText}>
                      {row.tipo}
                    </TableCell>
                    <TableCell className={classes.tableCellText}>
                      {row.numero}
                    </TableCell>
                    <TableCell className={classes.tableCellText}>
                      {formatCurrencyDisplay(row.importe)}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Descargar Documento" placement="top" arrow>
                        <Box display="flex" justifyContent="center">
                          <GrDocumentDownload
                            style={{ cursor: "pointer" }}
                            fontSize={24}
                            onClick={(e) => props.onDownload(row.id_pagos_formas)}
                          />
                        </Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            {tableData.length === 0 && !isLoading && (
              <TableRow hover role="checkbox">
                <TableCell colSpan={columns.length}>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    No existen formas de pago registradas para este proveedor.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <LoadingIcon />}

      {tableData.length > 0 && (
        <StyledPagination
          page={filter.page}
          pageCount={pageCount}
          onChange={pageChangeHandler}
        />
      )}
    </div>
  );
};

export default MyRetentions;
