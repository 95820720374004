import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import TableFilter from "../UI/TableFilter";
import StyledPagination from "../UI/StyledPagination";
import LoadingIcon from "../UI/LoadingIcon";
import { formatCurrencyDisplay, convertDate } from "../Utils/formatDisplay";
import StatesContext from "../../contexts/statesContext";
import PaymentsContext from "../../contexts/paymentsContext";
import { useDispatch } from "react-redux";
import { setCurrentDetailPayment } from "../../store/payments/paymentsSlice";

const useStyles = makeStyles({
  actionBar: {
    display: "flex",
    flexDirection: "row",
  },
  actionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "2em",
  },
  actionButton: {
    cursor: "pointer",
  },
  actionSearch: {
    flexGrow: "1",
    justifyContent: "flex-end",
  },
  searchInput: {
    maxWidth: "16em",
  },
  filterMenuBox: {
    display: "flex",
    flexDirection: "column",
  },
  filterMenuButtonBox: {
    display: "flex",
    flexDirection: "row",
  },
  filterMenuSelect: {
    width: "12em",
    marginBottom: "1em",
  },
  filterMenuButton: {
    marginRight: "0.5em",
  },
  tableHeaderText: {
    color: "gray",
    fontSize: 16,
    fontWeight: "bold",
  },
  tableCellText: {
    color: "gray",
    fontSize: 16,
    fontWeight: 600,
  },
  pages: {
    display: "flex",
    justifyContent: "center",
    margin: "3em auto",
  },
  pagesNumbers: {
    fontWeight: "bold",
  },
});

const columns = [
  {
    id: "numero_pago",
    label: "Número de pago",
    minWidth: 150,
    align: "left",
  },
  {
    id: "retirar_en",
    label: "Retirar en",
    minWidth: 150,
    align: "left",
  },
  {
    id: "a_partir_de",
    label: "A partir de",
    minWidth: 125,
    align: "left",
  },
  {
    id: "estado_pago",
    label: "Estado",
    minWidth: 125,
    align: "left",
  },
  {
    id: "total_pago",
    label: "Total pago",
    minWidth: 150,
    align: "left",
  },
  {
    id: "detalle_pago",
    label: "Detalle del pago",
    minWidth: 75,
    align: "center",
  },
];

const MyPayments = (props) => {
  const dispatch = useDispatch()
  const rowsPerPage = 7;

  const history = useHistory();
  const classes = useStyles();

  const initialized = useRef(false);

  const [states, setStates] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState({
    page: 1,
    estadoId: null,
    numeroOp: "",
  });

  const fetchData = useCallback(async () => {
    const states = await StatesContext.fetchStates();

    const documentStates = states
      .filter((x) => x.id_tipo_estado === 1)
      .map((s) => {
        return { value: s.id_estado, label: s.descripcion_abreviada };
      });
    documentStates.unshift({ value: "", label: "Cualquiera" });

    setStates(documentStates);

    fetchFilteredData();
    initialized.current = true;
  }, []);

  const fetchFilteredData = async (filter = null) => {
    setIsLoading(true);
    const paysData =
      await PaymentsContext.fetchPaymentsWithFormDetailsPagination(
        filter?.page || 1,
        rowsPerPage,
        filter
      );

    if (paysData) {
      const docPageCount = Math.ceil(paysData.totalRecords / rowsPerPage);

      setPageCount(docPageCount);
      setTableData(paysData.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (initialized.current) {
      fetchFilteredData(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (initialized.current) {
      const timer = setTimeout(() => {
        searchCommitHandler(searchText);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchText]);

  const searchCommitHandler = (newValue) => {
    setFilter((previousFilter) => {
      return { ...previousFilter, page: 1, numeroOp: newValue };
    });
  };
  const statusFilterHandler = (newFilter) => {
    setFilter((previousFilter) => {
      return {
        ...previousFilter,
        page: 1,
        estadoId: newFilter.status,
      };
    });
  };
  const pageChangeHandler = (e, newPage) => {
    setFilter((previousFilter) => {
      return { ...previousFilter, page: newPage };
    });
  };

  const redirectorDetail = () => {
    let version = window.localStorage.getItem("version");
    history.push("/payments/report/detail?v=" + version);
  };
  const redirectorNoDetail = () => {
    let version = window.localStorage.getItem("version");
    history.push("/payments/report/nodetail?v=" + version);
  };

  const showPaymentDetail = (props) => {
    dispatch(setCurrentDetailPayment(props))
    let version = window.localStorage.getItem("version");
    history.push("/payments/forms?v=" + version);
  };

  return (
    <div>
      <Box mt={1} mb={2} className={classes.actionBar}>
        {tableData.length > 0 && !isLoading &&
          <div
            className={`${classes.actionItem} ${classes.actionButton}`}
            onClick={redirectorDetail}
          >
            <AssignmentReturnedIcon fontSize="large" />
            <Typography variant="body2">Reporte - Detallado</Typography>
          </div>
        }
        {tableData.length > 0 && !isLoading &&
          <div
            className={`${classes.actionItem} ${classes.actionButton}`}
            onClick={redirectorNoDetail}
          >
            <AssignmentReturnedIcon fontSize="large" />
            <Typography variant="body2">Reporte - Sin detalle</Typography>
          </div>
        }
        <TableFilter
          className={classes.actionSearch}
          options={states}
          currentSearch={searchText}
          searchPlaceholder="Buscar por N° de OP"
          onFilterChange={statusFilterHandler}
          onSearchChange={(value) => setSearchText(value)}
        />
      </Box>

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  className={classes.tableHeaderText}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id_pago}>
                  <TableCell className={classes.tableCellText}>
                    {row.numero_pago}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.retirar_en}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {convertDate(row.a_partir_de)}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.estado_pago}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {formatCurrencyDisplay(row.total_pago)}
                  </TableCell>
                  <TableCell>
                    {row.tiene_detalle && (
                      <Tooltip title="Ver Detalles" placement="top" arrow>
                        <AspectRatioIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => showPaymentDetail(row)}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {tableData.length === 0 && !isLoading && (
              <TableRow hover role="checkbox">
                <TableCell colSpan={columns.length}>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    No existen pagos relacionados a este proveedor.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <LoadingIcon />}

      {tableData.length > 0 && (
        <StyledPagination
          page={filter.page}
          pageCount={pageCount}
          onChange={pageChangeHandler}
        />
      )}
    </div>
  );
};

export default MyPayments;
