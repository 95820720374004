import { Box, Button, Typography } from "@material-ui/core";
import BasicModal from "../../UI/BasicModal";
import { convertDateHour, formatCurrencyDisplay } from "../../Utils/formatDisplay";

const DetalleRechazoRecepcionModal = ({ open, onClose, rechazo }) => {
  return ( 
    <BasicModal
      open={open}
      onClose={onClose}
    >
      <Button onClick={onClose} style={{ fontWeight: "bold", alignSelf: "flex-end" }}>X</Button>
      <Box margin="15px">
        <Box>
          <Typography variant="h5" style={{marginBottom: "20px"}}>Detalles del Rechazo</Typography>
        </Box>
        <Box>
          <Typography style={{marginBottom: "20px"}}><b>Numero: </b>{rechazo ? rechazo.numeroRechazo : "-"}</Typography>
          <Typography style={{marginBottom: "20px"}}><b>Motivo: </b>{rechazo ? rechazo.motivoRechazo : "-"}</Typography>
          <Typography style={{marginBottom: "20px"}}><b>Cantidad: </b>{rechazo ? rechazo.cantidad : "-"}</Typography>
          <Typography style={{marginBottom: "20px"}}><b>Precio: </b>{rechazo ? formatCurrencyDisplay(rechazo.precio) : "-"}</Typography>
          <Typography style={{marginBottom: "20px"}}><b>Fecha: </b>{rechazo ? convertDateHour(rechazo.fechaRechazo) : "-"}</Typography>
          <Typography style={{marginBottom: "20px"}}><b>Observaciones: </b>{rechazo ? rechazo.observacion : "-"}</Typography>
        </Box>
      </Box>
    </BasicModal>
   );
}
 
export default DetalleRechazoRecepcionModal;