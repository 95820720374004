import api from "../services/api";

export class DocumentoContactoContext {
  static async fetchDocumentsNoticesById(id_documento_adjunto: number) {
    try {
      const response = await api.get(`/documentoContacto/${id_documento_adjunto}`);
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      }
      else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default DocumentoContactoContext;