import { AxiosResponse } from "axios";
import api from "../services/api";

export class DocumentoAfipContext {
  static async saveDocumentoAfip(QrForm: any, DocumentId: number, Recepciones: any, IdNotaPedido: number, CheckAnticipo: boolean, IdsRelacionAfip: any) {
    const response: any = await api.post(`/documentoAfip`, { QrForm, DocumentId, Recepciones, IdNotaPedido, CheckAnticipo, IdsRelacionAfip });
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  }

  static async getAdjustmentByProvider() {
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") || "").id_proveedor;
    const response: any = await api.get<Response, AxiosResponse<Response>>(
      `/documentoAfip/getByProvider/${providerId}`);
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  }
}

export default DocumentoAfipContext;