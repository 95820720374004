import React, { useMemo, useState, useEffect } from "react";
import "../../resources/styles/documentSearchBody.css";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { RiDeleteBin6Line } from "react-icons/ri";
import DigitalDocumentsContext from "../../contexts/digitalDocumentsContexts";
import { useDispatch } from "react-redux";
import {
  setSelectedDocumentId,
  setSelectedQr,
  setQrForm,
  setSelectedNotaPedido,
  setNotaPedidoList,
  setCheckAnticipo,
  setSelectedRecepciones,
  setListRelationsIds,
  setCheckSinNotaPedido,
  setNotaPedidoForContact,
  setTolerancia,
} from "../../store/documents/documentsSlice";
import { PDF_QR_JS, PDF_JS } from "pdf-qr";
import BasicModal from "./../UI/BasicModal";
import BasicButton from "./../UI/BasicButton";
import { useHistory } from "react-router-dom";
import SettingsContext from "../../contexts/settingsContext";
import UserContext from "../../contexts/userContext";

PDF_JS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDF_JS.version}/pdf.worker.min.js`;
const dJSON = require("dirty-json");

const useStyles = makeStyles({
  textBox: {
    margin: "1em"
  },
  text: {
    fontSize: 20,
  },
  button: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
  },
  buttonError: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
    backgroundColor: "#ff3933",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  fileBox: {
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    fontSize: 20,
    marginRight: "1em",
  },
  fileDeleteIcon: {
    cursor: "pointer",
    fontSize: 24,
    color: "red",
  },
  submitBox: {
    maxWidth: "40em",
    display: "center",
    justifyContent: "flex-end",
  },
  selectButton: {
    marginRight: "4em",
  },
  modalText: {
    marginBottom: ".5em",
  },
  modalBody: {
    maxHeight: "350px",
    overflowY: "auto",
    display: "flex",
    paddingLeft: "1em",
    paddingRight: "1em",
    width: "100%",
    flexDirection: "column",
  },
  loadingIcon: {
    width: "20px",
    height: "20px",
  },
});

const UploadDocuments = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [rejected, setRejected] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [settings, setSettings] = useState([]);
  const toleranciaId = 3;

  const userObj = JSON.parse(window.localStorage.getItem("user"));
  const userId = userObj.id_usuario;
  const version = window.localStorage.getItem("version");

  const [qrCount, setQrCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [fileState, setFileState] = useState([]);
  const [qrList, setQrList] = useState([]);

  const [inputFilesKey, setInputFilesKey] = useState(Date.now());

  const [openModal, setOpenModal] = useState(false);

  const tituloModal = useMemo(() => {
    if (rejected.length > 0 && accepted.length === 0) {
      return "Se rechazó la documentación subida";
    }
    if (rejected.length > 0 && accepted.length > 0) {
      return "Se rechazo el documento";
    }
    if (rejected.length === 0 && accepted.length > 0) {
      return "La documentación se subió exitosamente";
    }
  }, [rejected, accepted]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    }).then();
  }

  async function getTolerancia() {
    await SettingsContext.fetchSettings().then((settings) => {
      if (settings) {
        setSettings(settings);
      }
    });
  }

  useEffect(() => {
    getTolerancia();
  }, []);

  const callback = function (result, current) {
    dispatch(setSelectedNotaPedido({}));
    dispatch(setNotaPedidoList([]));
    dispatch(setCheckAnticipo(false));
    dispatch(setSelectedRecepciones([]));
    dispatch(setListRelationsIds([]));
    dispatch(setCheckSinNotaPedido(false));
    dispatch(setNotaPedidoForContact({}));
    dispatch(
      setTolerancia(
        settings.find(
          (configuracion) => configuracion.id_configuracion === toleranciaId
        )?.accion
      )
    );
    if (result.success === true) {
      let nroDocRecString = "";
      let moneda = "";
      try {
        let data = result.codes[0]?.split("?p=");
        if (data) {
          let decode = data && atob(data[1]);
          const jsonQr = dJSON.parse(decode);
          nroDocRecString = jsonQr.nroDocRec.toString();
          moneda = jsonQr.moneda.trim();
          if (Object.keys(jsonQr).length > 0) {
            setQrList((qrList) => [...qrList, decode]);
          }
        } else {
          //Si no leo el qr, lo agrego a la lista de qr pero con un nulo para validarlo mas adelante
          setQrList((qrList) => [...qrList, null]);
        }
      } catch (error) {
        console.error(error); //para control
      } finally {
        setQrCount((qrCount) => qrCount - 1);
        setFileState((files) => [
          ...files,
          { ...current, nroDocRec: nroDocRecString, moneda: moneda },
        ]);
      }
    } else {
      setQrCount((qrCount) => qrCount - 1);
    }
  };

  useEffect(() => {
    if (qrCount === 0) {
      setIsLoading(false);
    }
  }, [qrCount]);

  const configs = {
    scale: {
      once: false,
      value: 1,
      start: 0.2,
      step: 0.2,
      stop: 3,
    },
    resultOpts: {
      singleCodeInPage: true,
      multiCodesInPage: false,
      maxCodesInPage: 1,
    },
    improve: true,
    jsQR: {},
  };

  function uploadFile(e) {
    //Limpio la lista vieja
    setQrList([]);
    setFileState([]);
    setQrCount(0);

    setIsLoading(true);
    let targetFile = e.target.files[0];
    getBase64(targetFile).then((data) => {
      const image64 = data.split("64,", 2);

      let obj = {
        date: targetFile.lastModifiedDate,
        id: userId,
        type: targetFile.type,
        name: targetFile.name,
        size: targetFile.size,
        img: image64[1],
        cuit: JSON.parse(window.localStorage.getItem("provider")).cuit,
        state: 12,
      };
      let fileObject = {
        files: [targetFile],
      };
      setQrCount((qrCount) => qrCount + 1);
      PDF_QR_JS.decodeDocument(fileObject, configs, (result) =>
        callback(result, obj)
      );
    });
  }

  function deleteSelectedFiles(e) {
    let files = [...fileState];
    for (let i = 0; i < files.length; i++) {
      if (e === files[i].name) {
        files.splice(i, 1);
        const newQrList = qrList.filter((qr, index) => index !== i);
        setQrList(newQrList);
      }
    }
    setFileState(files);
    setInputFilesKey(Date.now());
  }

  const sendFiles = async () => {
    //Unifico los elementos de la lista de qr y los de la lista de archivos
    //agregandole el QR y en caso de que en la lista de qr no haya un qr, lo agrego con un null y lo envio al back
      const file = { ...fileState[0], qr: qrList[0] };
      const result = await DigitalDocumentsContext.setDocument(file);
      setAccepted(result.uploaded);
      if (result.uploaded.length > 0) {
          dispatch(
              setSelectedDocumentId(result.uploaded[0].id_documento_electronico)
          );
          dispatch(
              setSelectedQr({
                  ...file,
                  id_documento_electronico: result?.uploaded[0].id_documento_electronico,
              })
          );
      }
      setRejected(result.rejecteds);
      dispatch(setQrForm({}));
      if (result.uploaded.length > 0 && result.rejecteds.length === 0) {
          history.push(`/afip?v=${version}`);
      } else {
          setOpenModal(true);
      }
      setFileState([]);
      setInputFilesKey(Date.now());
  };
   
  const handleModal = (value) => {
    setOpenModal(value);
    if ((accepted.length > 0) & (rejected.length === 0)) {
      history.push(`/afip?v=${version}`);
    }
  };

  function oknop() {
    let element = document.getElementById("inputFiles");
    element.click();
  }

  const fileList = (
    <Box m={6}>
      {fileState.map((file) => (
        <Box className={classes.fileBox} key={file.name}>
          <Typography className={classes.fileName}>{file.name}</Typography>
          <RiDeleteBin6Line
            key={file.id}
            className={classes.fileDeleteIcon}
            onClick={(e) => deleteSelectedFiles(file.name)}
          />
        </Box>
      ))}
    </Box>
  );

  return (
    <div>
      <Typography className={classes.textBox} variant="h4" component="h1">
        Cargar documentos electrónicos
      </Typography>
      <Box className={classes.textBox}>
        <Typography className={classes.text}>
          Incorporar únicamente documentos de pago.
        </Typography>
        <Typography className={classes.text}>
          Cualquier otra documentación, enviarla por mail al sector
          correspondiente.
        </Typography>
      </Box>

      <Box className={classes.textBox}>
        <Typography className={classes.text}>
          <strong>Formato habilitado: </strong>PDF
        </Typography>
        <Typography className={classes.text}>
          <strong>Peso soportado: </strong>Hasta 1 MB
        </Typography>
        <Typography className={classes.text}>
          <strong>Caracteres válidos: </strong>Letras, números, guiones y
          espacios
        </Typography>
      </Box>

      <Box display={"flex"}>
        <Box>
          <BasicButton className={classes.selectButton} onClick={oknop}>
            <input
              key={inputFilesKey}
              hidden
              className={classes.submitBox}
              id="inputFiles"
              type="file"
              multiple
              accept="application/pdf"
              onChange={(e) => uploadFile(e)}
            ></input>
            Seleccionar archivo
          </BasicButton>
          {!isLoading && fileState.length > 0 && (
            <BasicButton
              className={classes.submitBox}
              onClick={() => sendFiles()}
              disabled={isLoading}
            >
              Subir documentación
            </BasicButton>
          )}
        </Box>
        {isLoading && (
          <Box display={"flex"} alignItems={"center"} ml={4}>
            <CircularProgress size={30} thickness={8} />
          </Box>
        )}
      </Box>
      {!isLoading && fileList}

      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography className={classes.modalText} variant="h5">
          {tituloModal}
        </Typography>
        <Box className={classes.modalBody}>
          {/* NO SE UTILIZA MAS YA QUE SI SE ACEPTA SE SALTEA AL FORMULARIO DE AFIP */}
          {/* {accepted.length > 0 && (
            <>
              <Typography variant="h6">Aceptado</Typography>
              <ul>
                {accepted.map((doc, index) => (
                  <li key={index}>
                    <Typography>{doc.name}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )} */}
          {rejected.length > 0 && (
            <>
              <Typography variant="h6">Rechazado</Typography>
              <ul>
                {rejected.map((doc, index) => (
                  <li key={index}>
                    <Typography color="error">{doc.name}</Typography>
                    <Typography color="error">Motivo:</Typography>
                    <ul>
                      {doc.reasons.map((reason, index) => (
                        <li key={index}>
                          <Typography color="error">{reason}</Typography>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Box>
        <BasicButton
          className={classes.button}
          onClick={() => handleModal(false)}
        >
          Continuar
        </BasicButton>
      </BasicModal>
    </div>
  );
};

export default UploadDocuments;
