import React, { useState, useEffect } from "react";
import LoadingIcon from "../UI/LoadingIcon";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AfipContext } from "../../contexts/afipContext";
import Alert from "@material-ui/lab/Alert";
import { convertDateHourAfip } from "../Utils/formatDisplay";
import { useHistory } from "react-router-dom";
import DigitalDocumentsContext from "../../contexts/digitalDocumentsContexts";
import documentoElectronicoQrContext from "../../contexts/documentoElectronicoQrContext";
import ImpuestoDocumentoAfipContext from "../../contexts/impuestoDocumentoAfipContext";
import DocumentoAfipContext from "../../contexts/documentoAfipContext";
import { useSelector } from "react-redux";
import BasicModal from "../UI/BasicModal";
import BasicButton from "../UI/BasicButton";

const useStyles = makeStyles({
  validateButton: {
    color: "#fff",
    backgroundColor: "#009639",
  },
  reply: {
    fontWeight: "bold",
  },
  alertBox: {
    // Estilo del alert en el box.
    marginTop: "16px",
    display: "flex", // Lo alineamos para que el ícono quede al principio.
    justifyContent: "center",
    alignContent: "center",
    alignItems: "start",
    padding: "16px 8px",
  },
  modal: {
    paddingLeft: "36px",
    paddingRight: "36px",
    paddingTop: "42px",
    paddingBottom: "42px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonValidate: {
    marginTop: "5px",
  },
});

export default function StepValidacion(props) {
  const classes = useStyles();
  const history = useHistory();

  //Estados para la confirmacion
  const [openModal, setOpenModal] = useState(false);
  const [saveSucces, setSaveSucces] = useState(false);
  const [message, setMessage] = useState("");
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    if (saveSucces) {
      let version = window.localStorage.getItem("version");
      history.push("/documents?v=" + version);
    }
    setOpenModal(false);
  };
  //Informacion necesaria de todos los steps
  const idDocument = useSelector(
    (state) => state.documents.selectedQr.id_documento
  );
  const idQr = useSelector((state) => state.documents.selectedQr.id);

  const idDocument_ele = useSelector((state) => state.documents.idDocument);

  const qrForm = useSelector((state) => state.documents.qrForm);
  const receptions = useSelector(
    (state) => state.documents.selectedRecepciones
  );
  const checkAnticipo = useSelector((state) => state.documents.checkAnticipo);
  const notaPedido = useSelector((state) => state.documents.selectedNotaPedido);
  const listRelationsIds = useSelector(
    (state) => state.documents.listRelationsIds
  );
  const receptionsBalanced = useSelector(
    (state) => state.documents.receptionsBalanced
  );
  const { checkedQrData, handleContinue } = props;
  const { qrData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState("");
  const [activeButton, setActiveButton] = useState(true);
  const [success, setSuccess] = useState(true);
  const [needValidation, setNeedValidation] = useState(true);

  //Finalizacion del proceso
  const handleClick = async () => {
    const result = await documentoElectronicoQrContext.validateQrDocument(
      idDocument_ele
    );
    if (result) {
      const result2 = await DigitalDocumentsContext.changeDigDocState(
        idDocument_ele,
        receptionsBalanced ? 9 : 16
      );
      if (result2) {
        const result3 =
          await ImpuestoDocumentoAfipContext.saveDocumentTaxesAfip(
            props.listTaxes
          );
        if (result3 || !props.listTaxes.length) {
          const result4 = await DocumentoAfipContext.saveDocumentoAfip(
            qrForm,
            idDocument_ele,
            receptions,
            notaPedido.id,
            checkAnticipo,
            listRelationsIds
          );
          if (result4) {
            setMessage("¡La documentación ha sido subida exitosamente!");
            handleOpenModal();
            setSaveSucces(true);
          } else {
            setMessage("Hubo un error con la validación.");
            handleOpenModal();
          }
        } else {
          setMessage("Hubo un error con la validación.");
          handleOpenModal();
        }
      } else {
        setMessage("Hubo un error con la validación.");
        handleOpenModal();
      }
    } else {
      setMessage("Hubo un error con la validación.");
      handleOpenModal();
    }
  };

  async function validateQr() {
    setIsLoading(true);
    if (checkedQrData) {
      const checked = await AfipContext.ValidateQr(qrData);
      if (checked) {
        setDataResponse(checked?.body?.comprobanteConstatarResult);
        if (checked?.body?.comprobanteConstatarResult?.resultado === "A") {
          handleContinue(true);
          setSuccess(true);
        } else {
          handleContinue(false);
          setSuccess(false);
        }
        setActiveButton(false);
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    !dataResponse && handleContinue(false);
    if (qrData.tipoCodAut === "X") {
      handleContinue(true);
      setActiveButton(false);
      setSuccess(true);
    }
    setNeedValidation(!["N", "C", "CE"].includes(qrData.tipoCodAut));
  }, []);

  function resultado(resultado) {
    if (resultado === "A") {
      return "Aprobado";
    } else if (resultado === "R") {
      return "Rechazado";
    } else {
      return resultado;
    }
  }

  return (
    <Box m={2} pb={3}>
      {needValidation && (
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Button
            className={`${activeButton && classes.validateButton}`}
            onClick={validateQr}
            disabled={!activeButton}
          >
            Validar
          </Button>
        </Box>
      )}

      {isLoading && <LoadingIcon />}
      {dataResponse && (
        <Alert
          variant="filled"
          severity={success ? "success" : "error"}
          className={classes.alertBox}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="row" justifyContent="center  ">
              <Typography variant="body1">
                El comprobante constatado con AFIP ha sido: &nbsp;
              </Typography>
              <Typography variant="body1" className={classes.reply}>
                {resultado(dataResponse.resultado)}
              </Typography>
            </Box>
            {dataResponse?.fchProceso && (
              <Typography variant="body1">
                Fecha de consulta:{" "}
                {convertDateHourAfip(dataResponse?.fchProceso)}
              </Typography>
            )}
            {dataResponse.observaciones &&
              dataResponse.observaciones.map((obs, index) => {
                return (
                  <Typography variant="body1" key={index}>
                    {obs.msg}
                  </Typography>
                );
              })}
            {dataResponse.errors &&
              dataResponse.errors.map((err, index) => {
                return (
                  <Typography variant="body1" key={index}>
                    {err.msg}
                  </Typography>
                );
              })}
          </Box>
        </Alert>
      )}
      {((dataResponse && success) || !needValidation) && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          marginBottom={4}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <BasicButton className={classes.buttonValidate} onClick={handleClick}>
            Finalizar Proceso
          </BasicButton>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignContent={"center"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <BasicModal
              className={classes.modal}
              open={openModal}
              onClose={handleCloseModal}
            >
              <Box
                display="flex"
                style={{ gap: 30 }}
                justifyContent="center"
                flexDirection="column"
              >
                <Typography variant="h5" marginbottom={"6px"}>
                  {message}
                </Typography>
                <Box display="flex" justifyContent="center">
                  <BasicButton onClick={handleCloseModal}>Cerrar</BasicButton>
                </Box>
              </Box>
            </BasicModal>
          </Box>
        </Box>
      )}
    </Box>
  );
}
