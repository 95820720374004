import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "../components/navBar";
import PortalHomeBody from "../components/portalHomeBody";

const index = 0;

class PortalHomeProvidersPage extends React.Component {
  render() {
    return (
      <div className="mainBox">
        <Navbar index={index} />
        <PortalHomeBody />
      </div>
    );
  }
}

export default PortalHomeProvidersPage;
