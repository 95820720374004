import api from "../services/api";
import { AxiosResponse } from "axios";

interface Response {
  data: ICurrency;
}

export interface ICurrency {
  Id: number;
  Code: string;
  Description: string;
}

export class CurrencyContext {

  static async fetchCurrencies() {
    const response: any = await api.get<Response, AxiosResponse<Response>>(`/currency`);
    if (response && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }
}

export default CurrencyContext;