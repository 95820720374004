import { makeStyles, Typography, Menu, MenuItem } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { useEffect, useState } from "react";
import image1 from "../../resources/images/mhsa-logo.gif";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  userMenu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  userName: {
    marginLeft: "8px"
  }
});

const UserMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [userName, setUserName] = useState("");

  useEffect(() => {
    let tokenUser = window.localStorage.getItem("tknUsr");
    if (tokenUser.includes("go_") && tokenUser.includes("@")) {
      tokenUser = tokenUser.substring(3, tokenUser.length);
    }
    setUserName(tokenUser);
  }, []);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navBarCloseUserMenuSession = (e) => {
    localStorage.clear();
    sessionStorage.clear();
    handleClose();
    history.push("/auth");
  };

  return (
    <div className={props.className}>
      <div className={classes.userMenu} onClick={handleClick}>
        <Avatar alt="no image" src={image1} />
        <Typography className={classes.userName} variant="body2">{userName}</Typography>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <MenuItem
          onClick={navBarCloseUserMenuSession}
          className={classes.menuItemStyleActions}
        >
          Cerrar sesión
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;