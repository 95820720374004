import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setListRelationsIds,
  setSelectedRecepciones,
  setCheckAnticipo,
  setNotaPedidoList,
  setNotaPedidoForContact,
  setReceptionsBalanced,
} from "../store/documents/documentsSlice";
import {
  Box,
  Button,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import StepNotaPedido from "./AfipSteps/stepNotaPedido";
import StepRecepcion from "./AfipSteps/stepRecepcion";
import StepPieImpositivo from "./AfipSteps/stepPieImpositivo";
import StepValidacion from "./AfipSteps/stepValidacion";
import BasicModal from "./UI/BasicModal";
import BasicButton from "./UI/BasicButton";
import ContactoForm from "./AfipSteps/contactoForm";
import DiferenciaForm from "./AfipSteps/diferenciaForm";
import NoAdjustmentModal from "./AfipSteps/Modals/NoAdjustmentModal";
import CancelModal from "./AfipSteps/Modals/CancelModal";
import documentoElectronicoQrContext from "../contexts/documentoElectronicoQrContext";

const useStyles = makeStyles((theme) => ({
  resetBtn: {
    color: "white",
    backgroundColor: theme.palette.danger.main,
    "&:hover": {
      backgroundColor: "#fd0700",
    },
  },
  icon: {
    cursor: "pointer",
  },
  scroller: {
    "@media (max-height:880px)": {
      // eslint-disable-line no-useless-computed-key
      height: "80%",
      overflowY: "auto",
      padding: "0px",
    },
  },
  colorSuccess: {
    color: "green",
  },
  colorError: {
    color: "red",
  },
}));

let steps = [];
let offsetPasos = 0;
const checkedQrData = true;

const AfipUploadBody = ({ caminoCompleto }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  offsetPasos = caminoCompleto ? 0 : 2;
  steps = caminoCompleto
    ? [
        "Selección Nota Pedido",
        "Selección Recepciones",
        "Carga Pie Impositivo",
        "Validar con AFIP",
      ]
    : ["Carga Pie Impositivo", "Validar con AFIP"];

  const [activeStep, setActiveStep] = useState(offsetPasos);
  const [continueStep, setContinueStep] = useState(false);
  const [listTaxes, setListTaxes] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const [balanceModal, setBalanceModal] = useState(false);
  const [noAdjustmentModal, setNoAdjustmentModal] = useState(false);
  const [balanceReception, setBalanceReception] = useState(false);
  const [adjustments, setAdjustments] = useState([]);
  const [adjustmentsBalance, setAdjustmentsBalance] = useState(0);
  const [listRelations, setListRelations] = useState([]);
  const [diffOk, setDiffOk] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const provider = JSON.parse(
    window.localStorage.getItem("provider")
  ).id_proveedor;
  const version = window.localStorage.getItem("version");

  let qrData = useSelector((state) => state.documents.qrForm);
  let checkAnticipo = useSelector((state) => state.documents.checkAnticipo);
  const tolerancia = useSelector((state) => state.documents.tolerancia);
  const checkSinNotaPedido = useSelector(
    (state) => state.documents.checkSinNotaPedido
  );
  const selectedRecepciones = useSelector(
    (state) => state.documents.selectedRecepciones
  );
  const notaPedidoForContact = useSelector(
    (state) => state.documents.notaPedidoForContact
  );
  const selectedQr = useSelector((state) => state.documents.selectedQr);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <StepNotaPedido setContinueStep={setContinueStep} />;
      case 1:
        return (
          <StepRecepcion
            setBalanceReception={setBalanceReception}
            setContinueStep={setContinueStep}
            setAdjustmentsBalance={setAdjustmentsBalance}
            setListTaxes={setListTaxes}
          />
        );
      case 2:
        return (
          <StepPieImpositivo
            qrData={qrData}
            handleContinue={setContinueStep}
            setListTaxes={setListTaxes}
          />
        );
      case 3:
        return (
          <StepValidacion
            checkedQrData={checkedQrData}
            qrData={qrData}
            listTaxes={listTaxes}
            handleContinue={setContinueStep}
          />
        );
      default:
        return (
          <Typography gutterBottom variant="h4">
            Paso no válido
          </Typography>
        );
    }
  };

  //useEffect para limpiar el store dependiendo del paso actual
  useEffect(() => {
    if (activeStep < 1) {
      dispatch(setNotaPedidoList([]));
      dispatch(setNotaPedidoForContact({}));
      dispatch(setCheckAnticipo(false));
    }
    if (activeStep < 2) {
      dispatch(setSelectedRecepciones([]));
      dispatch(setListRelationsIds([]));
      dispatch(setReceptionsBalanced(false));
    }
  }, [activeStep]);

  //useEffect para que al recargar la pagina y no tenga datos, te mande al principio
  useEffect(() => {
    if (Object.keys(qrData).length === 0) {
      const version = window.localStorage.getItem("version");
      history.push("/documents/upload?v=" + version);
    }
  }, []);

  const handleSaveChanges = () => {
    if (diffOk) {
      setBalanceReception(true);
      dispatch(setListRelationsIds(listRelations));
      // dispatch(setReceptionsBalanced(true));
      handleCloseBalanceModalOk();
      handleNextStep(null, true);
    } else {
      handleCloseBalanceModalOk();
      handleOpenNoAdjustmentModal();
    }
  };

  const handleContinueWithoutAdjustment = () => {
    setBalanceReception(true);
    dispatch(setListRelationsIds(listRelations));
    dispatch(setReceptionsBalanced(false));
    handleCloseNoAdjustmentModal();
    handleNextStep(null, true);
  };

  const handleCloseDiferenciaForm = () => {
    setBalanceModal(false);
  };

  const handleNextStep = (event, balanceRec = false) => {
    if (activeStep === 1) {
      if (balanceReception || balanceRec) {
        setActiveStep(
          (prevActiveStep) =>
            prevActiveStep + 1 + (activeStep === 0 && checkAnticipo ? 1 : 0)
        );
      } else {
        handleOpenBalanceModal(true);
      }
      return;
    }
    if (activeStep === 0) {
      setActiveStep(
        (prevActiveStep) =>
          prevActiveStep + (checkAnticipo || checkSinNotaPedido ? 2 : 1)
      );
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviousStep = () => {
    setBalanceReception(false);
    if (activeStep === offsetPasos) {
      history.goBack();
      return;
    }
    if (activeStep === 2) {
      setActiveStep(
        (prevActiveStep) =>
          prevActiveStep - (checkAnticipo || checkSinNotaPedido ? 2 : 1)
      );
      return;
    }
    if (activeStep > offsetPasos) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      return;
    }
  };

  const handleCloseBalanceModal = () => {
    adjustments?.forEach((element) => {
      element.agregado = false;
    });
    setAdjustments(adjustments);
    setDiffOk(false);
    setBalanceModal(false);
  };

  const handleCloseBalanceModalOk = () => setBalanceModal(false);

  const handleOpenBalanceModal = () => setBalanceModal(true);

  const handleOpenContacto = () => setActiveModal(true);

  const handleCloseContacto = () => setActiveModal(false);

  const handleOpenNoAdjustmentModal = () => setNoAdjustmentModal(true);

  const handleCloseNoAdjustmentModal = () => setNoAdjustmentModal(false);

  const handleResetStep = () => history.goBack();

  const handleCloseCancelModal = () => setCancelModal(false);

  const handleCancelModal = () => {
    setCancelModal(true);
  };

  const handleCancel = async () => {
    const qr = await documentoElectronicoQrContext.fetchDigDocsQrd(provider);
    const qrDoc = qr.filter(
      (item) => item.id_documento === selectedQr?.id_documento_electronico
    )[0].id;
    const response = await documentoElectronicoQrContext.delete(
      qrDoc,
      selectedQr?.id_documento_electronico
    );
    history.push(`/documents?v=${version}`);
  };

  return (
    <>
      <Stepper activeStep={activeStep - offsetPasos}>
        {steps.map((label, index) => {
          return (
            <Step key={label + index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box>{getStepContent(activeStep)}</Box>
      <Box display="flex" justifyContent="space-between" marginBottom={3}>
        <Box display="flex" mt={5} style={{ gap: 10 }}>
          {((offsetPasos === 0 && activeStep < steps.length - 1) ||
            (offsetPasos > 0 &&
              activeStep < steps.length - 1 + offsetPasos)) && (
            <BasicButton
              color="primary"
              variant="contained"
              onClick={handleNextStep}
              disabled={!continueStep}
            >
              Continuar
            </BasicButton>
          )}
          <BasicButton
            variant="contained"
            onClick={handleOpenContacto}
            color="warning"
          >
            Contacto
          </BasicButton>
          <BasicButton
            variant="contained"
            onClick={handlePreviousStep}
            color="grey"
          >
            Volver
          </BasicButton>
          <BasicButton
            variant="contained"
            color="red"
            onClick={() => handleCancelModal()}
          >
            Cancelar
          </BasicButton>
        </Box>
        {((offsetPasos === 0 && activeStep == steps.length - 1) ||
          (offsetPasos > 0 &&
            activeStep == steps.length - 1 + offsetPasos)) && (
          <Box mt={5}>
            <BasicButton
              variant="contained"
              className={classes.resetBtn}
              onClick={handleResetStep}
            >
              Reiniciar
            </BasicButton>
          </Box>
        )}
      </Box>
      <BasicModal
        open={activeModal}
        onClose={handleCloseContacto}
        className={classes.scroller}
      >
        <Button
          onClick={handleCloseContacto}
          style={{ fontWeight: "bold", alignSelf: "flex-end" }}
        >
          X
        </Button>
        <ContactoForm
          notaPedidoForContact={notaPedidoForContact}
          selectedRecepciones={selectedRecepciones}
        />
      </BasicModal>

      <BasicModal
        open={balanceModal}
        onClose={handleCloseBalanceModal}
        width="80%"
      >
        <Button
          onClick={handleCloseBalanceModal}
          style={{ fontWeight: "bold", alignSelf: "flex-end" }}
        >
          X
        </Button>
        <DiferenciaForm
          qrData={qrData}
          balanceModal={balanceModal}
          listRelations={listRelations}
          handleSaveChanges={handleSaveChanges}
          handleCloseDiferenciaForm={handleCloseDiferenciaForm}
          diffOk={diffOk}
          setDiffOk={setDiffOk}
          setListRelations={setListRelations}
          tolerancia={tolerancia}
          adjustments={adjustments}
          setAdjustments={setAdjustments}
          adjustmentsBalance={adjustmentsBalance}
        />
      </BasicModal>

      <BasicModal
        open={noAdjustmentModal}
        onClose={handleCloseNoAdjustmentModal}
        width="80%"
      >
        <Button
          onClick={handleCloseNoAdjustmentModal}
          style={{ fontWeight: "bold", alignSelf: "flex-end" }}
        >
          X
        </Button>
        <NoAdjustmentModal
          handleClose={handleCloseNoAdjustmentModal}
          handleContinue={handleContinueWithoutAdjustment}
        />
      </BasicModal>

      <CancelModal
        open={cancelModal}
        onClose={handleCloseCancelModal}
        cancelConfirmation={handleCancel}
      />
    </>
  );
};

export default AfipUploadBody;
