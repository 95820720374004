const spac = window.localStorage.getItem("AccesoSPAC")

export const ProviderOptionsCuits = [
  { text: "Portal - Proveedores", route: "/portal/providers" },
  { text: "Cambiar proveedor", route: "/portal/providerSelect" }
]

export const ProviderOptions = [
  { text: "Portal - Proveedores", route: "/portal/providers" },
]

if (spac !== null) {
  const newOption = { text: "Acceso SPAC", route: spac };
  ProviderOptionsCuits.push(newOption);
  ProviderOptions.push(newOption);
}