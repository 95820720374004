import { Badge, Box, Button, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import RecepcionContext from "../../../contexts/recepcionContext";
import BasicModal from "../../UI/BasicModal";
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import RechazosRecepcionModal from "./RechazosRecepcionModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DetalleItemRecepcionModal from "./DetalleItemRecepcionModal";
import LoadingIcon from "../../UI/LoadingIcon";
import { formatCurrencyDisplay } from "../../Utils/formatDisplay";

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableContainerFooter: {
    border: '1px solid #212529',
    minHeight: '45vh'
  },
  tableCell: {
    fontSize: '1rem',
    fontWeight: '400'
  },
  icon: {
    cursor: 'pointer',
  },
  tableCellEllipsis: {
    maxWidth: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

const columns = [
  {
    id: 1,
    label: 'Descripcion',
  },
  {
    id: 2,
    label: 'Cantidad',
  },
  {
    id: 3,
    label: 'Precio',
  },
  {
    id: 4,
    label: 'Acciones',
  },
]

const ItemsRecepcionModal = ({ open, onClose, recepcion }) => {
  const classes = useStyles();
  const [openRechazos, setOpenRechazos] = useState(false);
  const [openDetalle, setOpenDetalle] = useState(false);
  const [itemSeleccionado, setItemSeleccionado] = useState(null);

  const handleClickRechazos = (item) => {
    setItemSeleccionado(item);
    setOpenRechazos(true);
  }

  const handleClickDetalles = (item) => {
    setItemSeleccionado(item);
    setOpenDetalle(true);
  }

  return ( 
    <BasicModal
      open={open}
      onClose={onClose}
    >
      <Button onClick={onClose} style={{ fontWeight: "bold", alignSelf: "flex-end" }}>X</Button>
      <Box margin="15px">
        <Box>
          <Typography variant="h5" style={{marginBottom: "20px"}}>Informe de la Recepción</Typography>
        </Box>
        <Box>
          <TableContainer className={classes.tableContainerFooter}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={classes.tableCell}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { recepcion?.items?.length > 0 ? (
                    recepcion.items.map(item => (
                      <TableRow key={item.id}>
                        <TableCell className={classes.tableCellEllipsis}>{item.descripcion}</TableCell>
                        <TableCell>{item.cantidad}</TableCell>
                        <TableCell>{formatCurrencyDisplay(item.precio)}</TableCell>
                        <TableCell>
                          <Box style={{display:'flex', flexDirection:'row', justifyContent:'center', gap:'10px'}}>
                            <Tooltip title="Ver detalles">
                              <VisibilityIcon className={classes.icon} onClick={() => handleClickDetalles(item)}/>
                            </Tooltip>
                            {item.rechazos.length > 0 && (
                              <Tooltip title="Ver rechazos del informe">
                                <Badge badgeContent="!" color="error">
                                  <FormatListBulletedOutlinedIcon onClick={() => handleClickRechazos(item)} className={classes.icon}/>
                                </Badge>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <Typography variant="h6">No hay items para la recepción seleccionada</Typography>
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <RechazosRecepcionModal
        open={openRechazos}
        onClose={() => setOpenRechazos(false)}
        item={itemSeleccionado}
      />
      <DetalleItemRecepcionModal
        open={openDetalle}
        onClose={() => setOpenDetalle(false)}
        item={itemSeleccionado}
      />
    </BasicModal>
   );
}
 
export default ItemsRecepcionModal;