import React, { useMemo, useState } from "react";
import "../resources/styles/documentSearchBody.css";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import DigitalDocumentsContext from "../contexts/digitalDocumentsContexts";
import BasicModal from "./UI/BasicModal";
import BasicButton from "./UI/BasicButton";
import BackButton from "./UI/BackButton";
import { uploadPdf } from "./Utils/files";

const useStyles = makeStyles({
  textBox: {
    marginBottom: "1em",
  },
  text: {
    fontSize: 20,
  },
  button: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
  },
  buttonError: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
    backgroundColor: "#ff3933",
    "&:hover": {
      backgroundColor: "red"
    }
  },
  fileBox: {
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    fontSize: 20,
    marginRight: "1em",
  },
  fileDeleteIcon: {
    cursor: "pointer",
    fontSize: 24,
    color: "red",
  },
  submitBox: {
    maxWidth: "40em",
    display: "center",
    justifyContent: "flex-end",
  },
  selectButton: {
    marginRight: "4em"
  },
  modalText: {
    marginBottom: ".5em",
  },
  modalBody: {
    maxHeight: '350px',
    overflowY: "auto",
    display: 'flex',
    paddingLeft: '1em',
    paddingRight: '1em',
    width: '100%',
    flexDirection: 'column'
  }
});

const DocumentUploadBodyOld = () => {
  const classes = useStyles();
  const history = useHistory();

  const [rejected, setRejected] = useState([]);
  const [accepted, setAccepted] = useState([]);

  const userId = JSON.parse(localStorage.getItem("user")).id_usuario;

  const [fileState, setFileState] = useState([]);

  const [inputFilesKey, setInputFilesKey] = useState(Date.now());

  const [openModal, setOpenModal] = useState(false);

  const tituloModal = useMemo(() => {
    if (rejected?.length > 0 && accepted?.length === 0) {
      return "Se rechazó la documentación subida";
    }
    if (rejected?.length > 0 && accepted?.length > 0) {
      return "Se rechazaron algunos documentos";
    }
    if (rejected?.length === 0 && accepted?.length > 0) {
      return "La documentación se subió exitosamente";
    }
  }, [rejected, accepted]);

  const uploadFiles = async (files) => {
    let response = await uploadPdf(files)
    response.files = response.files.map(file => {
      return {
        ...file,
        cuit: JSON.parse(localStorage.getItem("provider")).cuit,
        state: 9,
        id: userId
      }
    })
    setFileState(response.files);
  }

  function deleteSelectedFiles(e) {
    let files = [];

    for (let i = 0; i < fileState.length; i++) {
      files.push(fileState[i]);
    }
    for (let i = 0; i < files.length; i++) {
      if (e === files[i].name) {
        files.splice(i, 1);
      }
    }
    setFileState(files);
    setInputFilesKey(Date.now());
  }

  function backRedirect() {
    let version = window.localStorage.getItem("version");
    history.push("/documents?v=" + version);
  }

  const sendFiles = async (files) => {
    const result = await DigitalDocumentsContext.setDocumentNotAfip(files);
    setAccepted(result.uploaded);
    setRejected(result.rejecteds);
    setOpenModal(true);
    setFileState([]);
    setInputFilesKey(Date.now());
  }

  function oknop() {
    let element = document.getElementById("inputFiles");
    element.click();
  }

  const fileList = (
    <Box m={6}>
      {fileState.map((file) => (
        <Box className={classes.fileBox} key={file.name}>
          <Typography className={classes.fileName}>{file.name}</Typography>
          <RiDeleteBin6Line
            className={classes.fileDeleteIcon}
            onClick={(e) => deleteSelectedFiles(file.name)}
          />
        </Box>
      ))}
    </Box>
  );

  return (
    <div>
      <BackButton onClick={backRedirect} />

      <Typography className={classes.textBox} variant="h4" component="h1">
        Cargar documentos electrónicos
      </Typography>

      <Box className={classes.textBox}>
        <Typography className={classes.text}>
          Incorporar únicamente documentos de pago.
        </Typography>
        <Typography className={classes.text}>
          Cualquier otra documentación, enviarla por mail al sector
          correspondiente.
        </Typography>
      </Box>

      <Box className={classes.textBox}>
        <Typography className={classes.text}>
          <strong>Formato habilitado: </strong>PDF
        </Typography>
        <Typography className={classes.text}>
          <strong>Peso soportado: </strong>Hasta 1 MB
        </Typography>
        <Typography className={classes.text}>
          <strong>Caracteres válidos: </strong>Letras, números, guiones y espacios
        </Typography>
      </Box>

      <Box>
        <BasicButton className={classes.selectButton} onClick={oknop}>
          <input
            key={inputFilesKey}
            hidden
            className={classes.submitBox}
            id="inputFiles"
            type="file"
            multiple
            accept="application/pdf"
            onChange={(e) => uploadFiles(e.target.files)}
          ></input>
          Seleccionar archivos
        </BasicButton>
        {fileState.length > 0 && (
          <BasicButton
            className={classes.submitBox}
            onClick={() => sendFiles(fileState)}
          >
            Subir documentación
          </BasicButton>
        )}
      </Box>
      {fileList}

      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography className={classes.modalText} variant="h5">
          {tituloModal}
        </Typography>
        <Box className={classes.modalBody}>
          {accepted.length > 0 &&
            <>
              <Typography variant="h6">
                Aceptados
              </Typography>
              <ul>
                {accepted.map((doc, idx) => (
                  <li key={idx}>
                    <Typography>{doc.name}</Typography>
                  </li>
                ))}
              </ul>
            </>
          }
          {rejected.length > 0 &&
            <>
              <Typography variant="h6">
                Rechazados
              </Typography>
              <ul>
                {rejected.map((doc, idx) => (
                  <li key={idx}>
                    <Typography color="error"  >{doc.name}</Typography>
                    <Typography color="error" >Motivo:</Typography>
                    <ul>
                      {doc.reasons.map(reason =>
                        <li>
                          <Typography color="error">{reason}</Typography>
                        </li>
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          }
        </Box>
        <BasicButton className={classes.button} onClick={() => setOpenModal(false)}>
          Continuar
        </BasicButton>
      </BasicModal>
    </div>
  );
};

export default DocumentUploadBodyOld;