import React, { Fragment, useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  button: {
    marginRight: "2em",
    height: "3em",
  },
  black: {
    color: "black",
  }
});

export default function CurrentProviderComponent() {
  const classes = useStyles();
  const [cuit, setCuit] = useState();
  const [razonSocial, setRazonSocial] = useState();

  function getProvider() {
    setCuit(JSON.parse(window.localStorage.getItem("provider")).cuit);
    setRazonSocial(JSON.parse(window.localStorage.getItem("provider")).razon_social);
  }

  useEffect(() => {
    getProvider();
  }, []);

  return (
    <Box className={classes.root}>
    {(cuit && razonSocial) && (
      <Fragment>
          <Typography>
            <strong>Proveedor seleccionado: </strong>
            {razonSocial}
          </Typography>
          <Typography>
            <strong>CUIT: </strong>
            {cuit}
          </Typography>
      </Fragment>
    )}
  </Box>
  )
}
