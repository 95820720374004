import Navbar from '../components/navBar';
import { useHistory } from "react-router";
import { Box, makeStyles, Typography } from "@material-ui/core";
import BasicButton from "../components/UI/BasicButton";

const NotFoundPage = () => {
  const data = window.localStorage.getItem("data");

  const useStyles = makeStyles({
    bold: {
      fontWeight: "bold",
    },
    hLine: {
        border: "1px solid #009639"
    },
  });

  const history = useHistory();


  const returnHandler = () => {
    if(!data){
      history.push("/auth");  
    }else{
      let version = window.localStorage.getItem("version");
      history.push("/portal/providers?v="+version);
    }
  };

  const classes = useStyles();

  return (
    <div>
      { data && <Navbar index={0} /> }
      <Box mx={12} mt={24} style={{ minHeight: "59vh" }}>
        <Box mb={8}>
          <Typography className={classes.bold} variant="h2">
            404
          </Typography>
          <hr className={classes.hLine} />
          <Typography variant="h5">Página no encontrada</Typography>
        </Box>
        <BasicButton variant="outlined" onClick={returnHandler}>
          Volver al Inicio
        </BasicButton>
      </Box>
    </div>
  );
};

export default NotFoundPage;