import React from 'react'
import { useLocation } from 'react-router'

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    if (!search) {
      return {}
    }
    return JSON.parse('{"' + decodeURI(search?.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
  }, [search]);
}

export default useQuery