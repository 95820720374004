import React, { useState } from "react";
import "../resources/styles/documentSearchBody.css";
import UploadDocuments from "./Documents/UploadDocuments";
// import ValidateDocuments from "./Documents/ValidateDocuments";
import BackButton from "./UI/BackButton";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import StyledTabs from "./UI/StyledTabs";
import { Typography } from "@material-ui/core";
import DocumentUploadBodyOld from "./documentUploadBodyOld";

const DocumentUploadBody = () => {
  const afip = JSON.parse(
    window.localStorage.getItem("provider")
  ).cargaDocumentosAfip;
  const history = useHistory();

  const backRedirect = () => {
    let version = window.localStorage.getItem("version");
    history.push("/documents?v=" + version);
  };

  return afip ? (
    <>
      <BackButton onClick={backRedirect} />
      <Box mb={3}>
        <Typography variant="h4">Gestión de documentos electrónicos</Typography>
      </Box>
      <Box ml={2}>
        <Typography variante="h6">
          Aquí podrá cargar documentos de pago, los cuales deberán ser validados
          contra AFIP
        </Typography>
      </Box>
      <UploadDocuments />
    </>
  ) : (
    <DocumentUploadBodyOld />
  );
};

export default DocumentUploadBody;
