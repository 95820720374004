import React from "react";
import "../resources/styles/globalStyles.css";
import "bootstrap/dist/css/bootstrap.css";
import AfipForm from "../components/AfipSteps/afipForm"
import Navbar from "../components/navBar";
export default function AfipFormPage() {
    return (
        <div className="mainBox">
            <Navbar />
            <AfipForm/>
        </div>
    )
}
