import { makeStyles, Box, LinearProgress } from "@material-ui/core";
import "../../resources/styles/loadingLogo.css";
import image from "../../resources/images/logo-tagline.png";

const useStyles = makeStyles({
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "2em",
    height: "700px",
  },
  loadingBar: {
    margin: "0 auto",
    width: "70%",
  },
});

const LoadingLogo = (props) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.loadingBox} ${props.className}`}>
      <Box display="flex" flexDirection="column">
        <img className="loadingLogo" src={image} alt="Cargando..." />
        <LinearProgress className={classes.loadingBar} color="primary" />
      </Box>
    </Box>
  );
};

export default LoadingLogo;
