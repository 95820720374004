//Subir archivos PDF
export const uploadPdf = (e) => {
  return new Promise((resolve, reject) => {
    let errors = [];
    let files = [];
    for (let i = 0; i < e.length; i++) {
      let obj = {
        img: "img",
        id: 0,
        name: "",
        date: "",
        type: "",
        size: "",
      };
      getBase64(e[i]).then((data) => {
        const image64 = data.split("64,", 2);
        obj.Tipo_archivo = e[i].type;
        obj.Nombre_archivo = e[i].name;
        obj.Tamaño_archivo = e[i].size;
        obj.Imagen = image64[1];
        obj.date = e[i].lastModifiedDate;
        obj.id = 0;
        obj.type = e[i].type;
        obj.name = e[i].name;
        obj.size = e[i].size;
        obj.img = image64[1];
        files.push(obj);
        const error = validateFile(obj);
        if (error !== "") {
          errors.push(error);
        }
        if(files.length >= e.length){
          resolve({files, errors});
        }
      });
    }
  });
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  }).then();
};

const validateFile = (obj) => {
  let errors = "";
  const format = /[!@#$%^&*()_+\-=\]{};':"\\|,<>?]+/;
  errors = obj.size / 1024 > 5000 ? "Error de tamaño. (Máx 5MB.)\n" : "" +
    obj.name.toString().length > 60 ? "El nombre no puede ser mayor a 60 caracteres.\n" : "" +
      format.test(obj.name) === true ? "El nombre sólo puede contener letras y números.\n" : "" +
        obj.size < 1 ? "Error al leer el archivo.\n" : "";
  return errors;
}

export const getFileTypeFromBase64 = (base64) => {
  const byteCharacters = atob(base64);
  const byteNumbers = Array.prototype.map.call(byteCharacters, char => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  const firstBytes = byteArray.subarray(0, 4);
  const headerArray = Array.from(firstBytes).map(b => b.toString(16).padStart(2, '0')).join('');

  switch (headerArray) {
      case "49492a00": // TIFF little-endian
      case "4d4d002a": // TIFF big-endian
          return 'tiff';
      case "25504446": // PDF
          return 'pdf';
      default:
          return null; // or 'unknown'
  }
}