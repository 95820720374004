import { createSlice } from "@reduxjs/toolkit";

export const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    currentDetailPayment: null,
  },
  reducers: {
    setCurrentDetailPayment: (state, action) => {
      state.currentDetailPayment = action.payload
    },
  },
});

export const { setCurrentDetailPayment } = paymentsSlice.actions;
export default paymentsSlice.reducer;