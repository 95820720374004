import api from "../services/api";

export class NotificationContext {
  static async setUserLogTime() {
    let id: any = JSON.parse(window.localStorage.getItem("user") ?? "").id_usuario;
    const response: any = await api.put(`/users/setTimeLog`, { id });
    if (response.data.status) {
      return response.data;
    } else {
      console.log("users/setTimeLog", response.data.message);
      return null;
    }
  }

  static async getNewness() {
    let userId: any = JSON.parse(window.localStorage.getItem("user") ?? "").id_usuario;
    const response: any = await api.get(`/users/getNewness`, { params: { userId } });
    if (
      response !== undefined &&
      response.data[0] !== undefined &&
      response.data[0] !== "" &&
      response.data[0] !== " "
    ) {
      return response.data;
    } else {
      return null;
    }
  }
  static async setUserAssignmentLogTime(UsuarioId: any, ProveedorId: any) {
    let UserId: any = JSON.parse(window.localStorage.getItem("user") ?? "").id_usuario || UsuarioId;
    let ProviderId = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor || ProveedorId;
    const response: any = await api.put(`/usersassignment/setTimeLog`, { UserId, ProviderId });
    if (response.data.status) {
      return response.data;
    } else {
      console.log("usersassignment/setTimeLog", response.data.message);
      return null;
    }
  }
}

export default NotificationContext;
