import { Box, Button, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import BasicModal from "../../UI/BasicModal";
import { useEffect, useState } from "react";
import RecepcionContext from "../../../contexts/recepcionContext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DetalleRechazoRecepcionModal from "./DetalleRechazoRecepcionModal";
import LoadingIcon from "../../UI/LoadingIcon";
import { convertDateHour, formatCurrencyDisplay } from "../../Utils/formatDisplay";

const useStyles = makeStyles({
  table: {
    width: '100%'
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableContainerFooter: {
    border: '1px solid #212529',
    minHeight: '40vh'
  },
  tableCell: {
    fontSize: '1rem',
    fontWeight: '400'
  },
  icon: {
    cursor: 'pointer',
  },
  tableCellEllipsis: {
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

const columns = [
  {
    id: 1,
    label: 'N° Rechazo',
  },
  {
    id: 2,
    label: 'Fecha',
  },
  {
    id: 3,
    label: 'Motivo',
  },
  {
    id: 4,
    label: 'Cantidad',
  },
  {
    id: 5,
    label: 'Precio',
  },
  {
    id: 6,
    label: 'Acciones',
  }
];


const RechazosRecepcionModal = ({open, onClose, item}) => {
  const classes = useStyles();
  const [openDetalle, setOpenDetalle] = useState(false);
  const [rechazoSeleccionado, setRechazoSeleccionado] = useState(null);

  const handleClickDetalles = (rechazo) => {
    setRechazoSeleccionado(rechazo);
    setOpenDetalle(true);
  }
  
  return ( 
    <BasicModal
      open={open}
      onClose={onClose}
    >
      <Button onClick={onClose} style={{ fontWeight: "bold", alignSelf: "flex-end" }}>X</Button>
      <Box margin="15px">
        <Box>
          <Typography variant="h5" style={{marginBottom: "20px"}}>Rechazos del Informe</Typography>
        </Box>
        <Box>
          <TableContainer className={classes.tableContainerFooter}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={classes.tableCell}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  item?.rechazos?.length > 0 ? (
                    item.rechazos.map(rechazo => (
                      <TableRow key={rechazo.id}>
                        <TableCell>{rechazo.numeroRechazo}</TableCell>
                        <TableCell>{convertDateHour(rechazo.fechaRechazo)}</TableCell>
                        <TableCell className={classes.tableCellEllipsis}>{rechazo.motivoRechazo}</TableCell>
                        <TableCell>{rechazo.cantidad}</TableCell>
                        <TableCell>{formatCurrencyDisplay(rechazo.precio)}</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Ver detalles">
                            <VisibilityIcon onClick={() => handleClickDetalles(rechazo)} className={classes.icon}/>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        <Typography variant="h6">No hay rechazos para el item seleccionado</Typography>
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <DetalleRechazoRecepcionModal
          open={openDetalle}
          onClose={() => setOpenDetalle(false)}
          rechazo={rechazoSeleccionado}
        />
      </Box>
    </BasicModal>
  );
}
 
export default RechazosRecepcionModal;