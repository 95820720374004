import { useEffect, useState } from "react";

const PDFBase64Viewer = (props) => {
  const { fileContent = "", fileName } = props; // Recibimos imagen del pdf en base 64 y nombre del archivo

  const [viewContent, setViewContent] = useState("");

  const processData = async (data) => {
    if (data) {
      const linkSource = `data:application/pdf;base64,${data}`;
      const res = await fetch(linkSource); // Convertimos base64 a blob y creamos un URL para el viewer (<object>)
      const blob = await res.blob();
      const url = URL.createObjectURL(blob);
      setViewContent(url);
    }
  };

  useEffect(() => {
    if (fileContent) {
      processData(fileContent);

      return () => {
        URL.revokeObjectURL(fileContent); // Liberamos la memoria de URLs anteriores
      };
    }
  }, [fileContent]);

  return (
    <object
      type="application/pdf"
      data={viewContent}
      style={{ width: "100%", height: "100%", border: "1px solid black" }}
      name={fileName}
    >
      {`Su navegador no soporta esta función.\n\nPor favor actualice su navegador para previsualizar documentos electrónicos.`}
    </object>
  );
};

export default PDFBase64Viewer;
