import api from "../services/api";
import { AxiosResponse } from "axios";

interface Response {
  data: any;
}
export class VisitsContext {
  static async createVisit(SectionId: number) {
    let UserId = JSON.parse(window.localStorage.getItem("user") ?? "").id_usuario || 1;
    let ProviderId = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response = await api.post<Response, AxiosResponse<any>>(
      `/visits/create`, { UserId, ProviderId, SectionId }
    );
    if (response !== undefined && response.data !== undefined && response.data.status) {
      return response.data;
    }
    else {
      console.log("/visits/create",response.data.message);   
      return null;
    }
  }
}

export default VisitsContext;