import React from 'react';
import { Box, makeStyles, Typography } from "@material-ui/core";
import BasicButton from "../../UI/BasicButton";

const useStyles = makeStyles((theme) => ({
  scroll: {
    overflow: "auto",
    maxHeight: "50vh",
  },
  table: {
    minWidth: 650,
  },
  icon: {
    cursor: "pointer",
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  colorError: {
    color: theme.palette.error.main,
  },
}));

const NoAdjustmentModal = ({handleClose, handleContinue}) => {
  const classes = useStyles();

  return (
    <>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" alignContent="center">
      <Typography variant="h5">
        ¿Está seguro que desea continuar sin ajustar?
      </Typography>
    </Box>
    <Box mt={1} display="flex" style={{ gap: 10 }}>
      <BasicButton color="primary" variant="contained" onClick={() => handleContinue()}>Continuar</BasicButton>
      <BasicButton color="grey" onClick={() => handleClose()}>Cerrar</BasicButton>
    </Box>
  </>
  )
}

export default NoAdjustmentModal