import React, { useCallback, useEffect, useState } from "react";
import NoticesContext from "../contexts/noticesContext";
import moment from "moment";
import { useHistory } from "react-router";
import MyNotices from "./Notices/MyNotices";
import StyledTabs from "./UI/StyledTabs";
import VisitsContext from "../contexts/visitsContext";
import { useDispatch } from "react-redux";
import { setCurrentNotice } from "../store/notices/noticesSlice";

const TAB_STORAGE = "tab_notices";

function NoticesBody() {
  const dispatch = useDispatch();
  const [initialTab, setInitialTab] = useState(0);
  const [currentNoticesData, setCurrentNoticesData] = useState();
  const [oldNoticesData, setOldNoticesData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const rowsPerPage = 5;

  const history = useHistory();

  const convertDateString = (dateString) => {
    const day = dateString.slice(0, 2);
    const month = dateString.slice(3, 5);
    const year = dateString.slice(-4);
    return new Date(year, month - 1, day);
  };
  const fetchData = useCallback(async () => {
    const notices = await NoticesContext.fetchNoticesByCuit();
    const currentNotices = [];
    const oldNotices = [];
  
    if (notices) {
      const currentDate = new Date().getTime();
      const yesterday = moment().subtract(1, 'days').endOf('day').valueOf();
  
      notices.forEach((notice) => {
        const [startPeriod, endPeriod] = notice.periodo_vigencia.split(" - ");
        const periodFrom = moment(`${startPeriod} 00:00:01`, "DD-MM-YYYY hh:mm:ss").valueOf();
        const periodUntil = endPeriod
          ? moment(`${endPeriod} 23:59:59`, "DD-MM-YYYY hh:mm:ss").valueOf()
          : yesterday;
  
        notice.vigencia_comienzo = convertDateString(startPeriod);
        notice.vigencia_final = convertDateString(endPeriod);
  
        if (currentDate > periodUntil) {
          oldNotices.push(notice);
        } else if (currentDate >= periodFrom) {
          currentNotices.push(notice);
        }
      });
    }
  
    setCurrentNoticesData(currentNotices);
    setOldNoticesData(oldNotices);
    setIsLoading(false);
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  

  useEffect(() => {
    VisitsContext.createVisit(7).then((e) => { });
    const lastTabSelected = sessionStorage.getItem(TAB_STORAGE);
    if (lastTabSelected) {
      setInitialTab(+lastTabSelected);
      sessionStorage.removeItem(TAB_STORAGE);
    }
  }, []);

  const OpenNotice = (notice) => {
    dispatch(setCurrentNotice(notice))
    history.push("/notices/common-view?show=accordance");
  };

  const OpenCommonNotice = (notice) => {
    dispatch(setCurrentNotice(notice))
    let version = window.localStorage.getItem("version");
    history.push("/notices/common-view?v=" + version);
  };

  const tabsContent = [
    {
      label: "Avisos vigentes.",
      content: () =>
        <MyNotices
          data={currentNoticesData}
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
          openNotice={OpenNotice}
          openCommonNotice={OpenCommonNotice}
          tabIndex={0}
        />,
    },
    {
      label: "Biblioteca de avisos.",
      content: () =>
        <MyNotices
          data={oldNoticesData}
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
          openNotice={OpenNotice}
          openCommonNotice={OpenCommonNotice}
          tabIndex={1}
        />,
    },
  ];

  return (
    <div>
      <StyledTabs tabConfig={tabsContent} currentTab={initialTab} />
    </div>
  );
}

export default NoticesBody;
