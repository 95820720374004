import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import PaymentsReportBody from "../components/paymentsReportBody";
import Navbar from "../components/navBar";
import CurrentProviderComponent from "../components/currentProviderComponent";

const index = 1;

class PaymentsReportPage extends React.Component {
  render() {
    return (
      <div className="mainBox">
        <Navbar index={index} />
        <CurrentProviderComponent />
        <PaymentsReportBody />
      </div>
    );
  }
}
export default PaymentsReportPage;
