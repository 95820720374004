import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Paper,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { PieImpuestoContext } from "../../contexts/pieImpuestoContext";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
  tableContainer: {
    border: "2px solid #212529",
  },
  tablaAgregados: {
    border: "2px solid #212529",
    maxHeight: "220px",
  },
  tableContainerFooter: {
    border: "1px solid #212529",
  },
  tableCell: {
    fontSize: "1rem",
    fontWeight: "400",
  },
  text: {
    fontSize: 25,
  },
  textField: {
    width: 150,
  },
  plusIcon: {
    cursor: "pointer",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  colorSuccess: {
    color: "green",
  },
  colorError: {
    color: "red",
  },
});

const initialValues = {
  Importe: "",
  Id_PieImpuesto: "",
};

const StepPieImpositivo = ({ qrData, handleContinue, setListTaxes }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState(initialValues);
  const [impuestosList, setImpuestosList] = useState([]);
  const [impuestoTotal, setImpuestoTotal] = useState(0);
  const [taxesList, setTaxesList] = useState([]);

  const [taxValue, setTaxValue] = useState(initialValues);

  const theme = useTheme();

  const idDocument = useSelector((state) => state.documents.idDocument);

  const sumatoriaImpuestos = useMemo(() => {
    return taxesList.reduce((acc, impuesto) => {
      return acc + parseFloat(impuesto.Importe) || 0;
    }, 0);
  }, [taxesList]);

  useEffect(() => {
    let totalTax = parseFloat(qrData.importeTotalPesosNoTax);
    taxesList.map((t) => {
      return (totalTax += parseFloat(t.Importe));
    });
    totalTax = Math.round(totalTax * 100) / 100;
    setImpuestoTotal(totalTax);
    handleContinue(
      parseFloat(totalTax) === parseFloat(qrData.importeTotalPesos)
    );
  }, [sumatoriaImpuestos]);

  const onChange = (name, value) => {
    setTaxValue({ ...taxValue, [name]: value });
    if (name === "Importe") {
      if (value.match(/^\d*(\.\d+)?$/)) {
        setErrors({
          ...errors,
          [name]: "",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Numero invalido",
        });
      }
    }
    if (name === "Id_PieImpuesto") {
      if (!value) {
        setErrors({
          ...errors,
          [name]: "Debe seleccionar un impuesto",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });
      }
    }
  };

  const handleClick = (tax) => {
    const isEmpty = errors
      ? Object.values(errors).every((x) => x === null || x === "")
      : null;
    const isDataEmpty = tax
      ? tax.Id_PieImpuesto === "" || tax.Importe === ""
      : null;

    if (isEmpty && !isDataEmpty) {
      setListTaxes((arr) => [
        ...arr,
        {
          Id_PieImpuesto: tax.Id_PieImpuesto,
          Id_documento_electronico: idDocument,
          Importe: parseFloat(tax.Importe),
        },
      ]);

      setTaxesList((taxesList) => {
        let Denominacion = impuestosList.find(
          (impuesto) => impuesto.id === tax.Id_PieImpuesto
        )?.denominacion;
        return [
          ...taxesList,
          {
            Id_PieImpuesto: tax.Id_PieImpuesto,
            Denominacion,
            Importe: parseFloat(tax.Importe),
          },
        ];
      });

      setTaxValue(initialValues);
      setErrors(initialValues);

      let impuestosListNew = [...impuestosList];
      impuestosListNew.forEach((impuesto) => {
        if (impuesto.id === tax.Id_PieImpuesto) {
          impuesto.agregado = true;
        }
      });
      setImpuestosList([...impuestosListNew]);
    }
  };

  const handleDeleteClick = (id) => {
    const arr = taxesList.filter((item) => item.Id_PieImpuesto !== id);
    setTaxesList(arr);
    let impuestosListNew = [...impuestosList];
    impuestosListNew.forEach((impuesto) => {
      if (impuesto.id === id) {
        impuesto.agregado = false;
      }
    });
    setImpuestosList([...impuestosListNew]);
  };

  useEffect(() => {
    PieImpuestoContext.fetchAll().then((data) => {
      setImpuestosList(
        data.map((tax) => {
          return { ...tax, agregado: false };
        })
      );
    });
  }, []);

  return (
    <>
      <Box m={2} gap={4}>
        <Typography gutterBottom variant="h4">
          Carga del pie impositivo (si corresponde)
        </Typography>
        <Typography className={classes.text}>
          Para avanzar al siguiente paso, importe total en Pesos debe ser igual
          al importe sin impuestos en Pesos más la sumatoria de los registros
          ingresados en la grilla
        </Typography>
        <Box
          display={"flex"}
          alignItems="flex-start"
          style={{ gap: "15px" }}
          flexDirection={
            useMediaQuery(theme.breakpoints.down("sm")) ? "column" : "row"
          }
        >
          <TableContainer>
            <Table className={classes.tableContainer}>
              <TableHead>
                <TableRow>
                  <TableCell>Impuesto</TableCell>
                  <TableCell align="center">Importe</TableCell>
                  <TableCell align="center">Accion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {impuestosList.some((tax) => !tax.agregado) ? (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Select
                        className={classes.select}
                        value={taxValue?.Id_PieImpuesto}
                        onChange={(e) =>
                          onChange("Id_PieImpuesto", e.target.value)
                        }
                        error={Boolean(errors?.Id_PieImpuesto)}
                        helperText={errors?.Id_PieImpuesto}
                      >
                        {impuestosList.map((row) => {
                          if (!row.agregado) {
                            return (
                              <MenuItem key={row.id} value={row.id}>
                                {row.denominacion}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </TableCell>
                    <TableCell align="center" className={classes.textField}>
                      <TextField
                        value={taxValue?.Importe}
                        variant="outlined"
                        onChange={(e) => onChange("Importe", e.target.value)}
                        error={Boolean(errors?.Importe)}
                        helperText={errors?.Importe}
                        onKeyPress={(e) =>
                          e.key === "Enter" && handleClick(taxValue)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Agregar impuesto">
                        <AddCircleIcon
                          className={classes.plusIcon}
                          onClick={() => handleClick(taxValue)}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      Todos los impuestos han sido agregados!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box gap={4} mt={2}>
              <TableContainer
                component={Paper}
                className={classes.tableContainerFooter}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        Importe total en Pesos:
                      </TableCell>
                      <TableCell align="right">
                        <b>
                          ${parseFloat(qrData?.importeTotalPesos)?.toFixed(2)}
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        Importe sin impuestos en pesos
                      </TableCell>
                      <TableCell align="right">
                        <b>
                          $
                          {parseFloat(qrData?.importeTotalPesosNoTax)?.toFixed(
                            2
                          )}
                        </b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        Sumatoria de impuestos agregados:
                      </TableCell>
                      <TableCell align="right">
                        <b>${sumatoriaImpuestos.toFixed(2)}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className={
                          impuestoTotal ===
                          parseFloat(qrData?.importeTotalPesos)
                            ? `${classes.colorSuccess} ${classes.tableCell}`
                            : `${classes.colorError} ${classes.tableCell}`
                        }
                      >
                        Suma total con impuestos:
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          parseFloat(impuestoTotal) ===
                          parseFloat(qrData?.importeTotalPesos)
                            ? classes.colorSuccess
                            : classes.colorError
                        }
                      >
                        <b>${impuestoTotal.toFixed(2)}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TableContainer>
          <TableContainer className={classes.tablaAgregados}>
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Impuesto</TableCell>
                  <TableCell align="center">Importe</TableCell>
                  <TableCell align="center">Accion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taxesList?.length > 0 ? (
                  taxesList?.map((row, idx) => (
                    <TableRow key={idx + row.Denominacion}>
                      <TableCell component="th" scope="row">
                        <Typography>{row.Denominacion}</Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.textField}>
                        <Typography>{row.Importe.toFixed(2)}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Borrar impuesto">
                          <DeleteIcon
                            className={classes.deleteIcon}
                            onClick={() =>
                              handleDeleteClick(row.Id_PieImpuesto)
                            }
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      Aún no hay impuestos agregados.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default StepPieImpositivo;
