import React, { useState, useEffect, Fragment, useCallback } from "react";
import UserDataComponent from "./userDataComponent";
import VisitsContext from "../contexts/visitsContext";
import { Typography, makeStyles } from "@material-ui/core";
import BasicButton from "./UI/BasicButton";
import BasicModal from "./UI/BasicModal";

const useStyles = makeStyles({
  portalHomeContainer: {
    display: "inline-block",
    position: "relative",
    marginLeft: "2.5%",
    marginTop: "2%",
    marginBottom: "1%",
    minHeight: "75vh",
  },
  modalText: {
    marginBottom: "2em",
  },
  buttonModal: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
  },
});

export default function PortalHomeBody() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const maintenance = JSON.parse(window.localStorage.getItem("mantenimiento"));
  const perms = window.localStorage.getItem("tknPms");
  const handleCloseModal = () => setOpenModal(false);

  const classes = useStyles();

  const fetchData = useCallback(async () => {
    const storedUser = JSON.parse(window.localStorage.getItem("user")).usersAssignments;
    const storedProvider = storedUser.filter((user) =>
      user.providers.id_proveedor ===
      parseInt(JSON.parse(window.localStorage.getItem("provider")).id_proveedor)
    );
    setUser(storedUser);
    GetToken(storedUser, storedProvider[0]?.providers);
  }, []);

  useEffect(() => {
    VisitsContext.createVisit(3).then((e) => { });
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("taxStatus"));
    if (data === true) {
      setOpenModal(true);
    }
  }, [localStorage.getItem("taxStatus")]);

  const GetToken = (user, provider) => {
    const userDat = {
      nombre: user.mail,
      razon_social: provider.razon_social,
      cuit: provider.cuit,
      domicilio: provider.domicilio,
      localidad: provider.localidad,
      codigo_postal: provider.codigo_postal,
      telefono: provider.telefono,
      id_proveedor: provider.id_proveedor,
    };
    setUserData(userDat);
  };

  if (user) {
    return (
      <div className={classes.portalHomeContainer}>
        {maintenance?.estado === true ? (
          <Typography variant="h5">{maintenance.descripcion}</Typography>
        ) : (
          <Fragment>
            <Typography variant="h5" component="h1">
              ¡Hola! Te damos la bienvenida a nuestro Portal - Proveedores.
            </Typography>
            <UserDataComponent props={userData} />
            {perms.includes("ProveedorImpuesto") ?
              
              <BasicModal open={openModal} onClose={handleCloseModal}>
                <Typography className={classes.modalText} variant="body1">
                  Tiene nueva documentación impositiva a revisar.
                </Typography>
                <BasicButton className={classes.buttonModal} onClick={handleCloseModal}>
                  Continuar
                </BasicButton>
              </BasicModal>
              :
              <></>
            }
          </Fragment>
        )}
      </div>
    );
  } else {
    return <Typography>Validando usuario...</Typography>;
  }
}
