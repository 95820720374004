import api from "../services/api";
import { AxiosResponse } from "axios";

interface Response {
  data: IArea;
}

export interface IArea {
  Id_area: any;
  Denominacion: any;
  Contacto: any;
  Usuario_responsable: any;
}

export class AreaContext {
  static async fetchActiveAreas(){
    const response: any = await api.get<Response, AxiosResponse<Response>>(`/areas/active`);
    if(response && response.data[0] !== undefined){
      return response.data;
    }
    else {
      return null;
    }
  }
}

export default AreaContext;