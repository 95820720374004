import React, { useState, useEffect, useCallback } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  makeStyles, TableBody, TableCell, TableRow, TableContainer,
  TableHead, Table, Box, Typography, Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router";
import LoadingIcon from "./UI/LoadingIcon";
import BasicModal from "./UI/BasicModal";
import BasicButton from "./UI/BasicButton";
import StyledPagination from "./UI/StyledPagination";
import TableFilter from "./UI/TableFilter";
import VisitsContext from "../contexts/visitsContext";
import ContactContext from "../contexts/contactContext";
import AreaContext from "../contexts/areaContext";
import { convertDateHour } from "./Utils/formatDisplay";
import { useDispatch } from "react-redux";
import { setCurrentContact } from "../store/contacts/contactsSlice";

const useStyles = makeStyles({
  actionBar: {
    display: "flex",
    flexDirection: "row",
  },
  actionSearch: {
    flexGrow: "1",
    justifyContent: "flex-end",
  },
  tableHeader: {
    color: "gray",
    fontWeight: "bold",
    width: "fit-content",
  },
  tableCell: {
    color: "gray",
    fontWeight: 600,
    width: "fit-content",
  },
  iconButton: {
    color: "#797a7a",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
  roundedButtonSelected: {
    minWidth: "200px",
    borderRadius: "30px",
    textTransform: "none",
    marginRight: "15px",
  },
  roundedButton: {
    border: "0px",
    borderRadius: "30px",
    minWidth: "200px",
    textTransform: "none",
    marginRight: "15px",
  },
  bold: {
    fontWeight: "bold",
  },
  button: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
  },
  buttonError: {
    width: "20em",
    height: "3em",
    marginTop: "1em",
    backgroundColor: "#ff3933",
    "&:hover": {
      backgroundColor: "red"
    }
  },
});

const columns = [
  {
    id: "fecha_creacion",
    label: "Fecha Creación",
    width: 250,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "contactoMotivo.descripcion",
    label: "Motivo del Contacto",
    width: 250,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "area",
    label: "Área",
    width: 75,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "pregunta",
    label: "Pregunta",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "estado",
    label: "Estado",
    minWidth: 150,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "areaDerivada",
    label: "Derivado a",
    minWidth: 150,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "acciones",
    label: "Acciones",
    minWidth: 150,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function ContactBody() {
  const dispatch = useDispatch()

  const history = useHistory();
  const classes = useStyles();
  const version = window.localStorage.getItem("version");
  const NO_FILTER_VALUE = "";
  const rowsPerPage = 6;

  const [page, setPage] = useState(1);
  const [allContacts, setAllContacts] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const [filterOption, setFilterOption] = useState(NO_FILTER_VALUE);
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");

  const [modalMsg, setModalMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [createResponse, setCreateResponse] = useState(false);

  useEffect(() => {
    VisitsContext.createVisit(6).then((e) => { });
  }, []);

  const fetchData = useCallback(async () => {
    const [contacts, areas] = await Promise.all([
      ContactContext.fetchContacts(),
      AreaContext.fetchActiveAreas()
    ]);

    const areaList = areas.map((area) => {
      return {
        value: area.id_area,
        label: area.denominacion,
      };
    });
    areaList.unshift({ value: NO_FILTER_VALUE, label: "Todas" });

    setAllContacts(contacts);
    setAllAreas(areaList);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const searchFilterHandler = (newValue) => {
    setSearchFilter(newValue);
  };
  const statusFilterHandler = (newFilter) => {
    setFilterOption(newFilter.status);
    setStartDateFilter(newFilter.startDate);
    setEndDateFilter(newFilter.endDate);
  };

  const goToCreateContact = () => {
    let version = window.localStorage.getItem("version");
    history.push("/contact/create?v=" + version);
  };

  const formatString = (str) => {
    return str.length > 30 ? str.substring(0, 30) + "..." : str;
  };

  function ViewSelected(id) {
    dispatch(setCurrentContact(id))
    history.push("/contact/contactView?v=" + version);
  }

  const cancelContactHandler = (id) => {
    setContactToDelete(id)
    setModalMsg("¿Está seguro que desea eliminar este contacto?");
    handleOpenModal();
  };

  const confirmDeleteContact = async () => {
    if (contactToDelete) {
      const response = await ContactContext.deleteContact(contactToDelete);
      handleCloseModal();
      setContactToDelete(null);
      setCreateResponse(response);
      if (response) {
        setModalMsg("Contacto eliminado con éxito");
        handleOpenModal();
        fetchData();
      } else {
        setModalMsg("Error al eliminar el contacto.");
        handleOpenModal();
      }
    }
  };

  const pageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const hasData = allContacts && allContacts.length > 0;
  const firstIndexInPage = (page - 1) * rowsPerPage;

  const statusFilteredData =
    filterOption !== NO_FILTER_VALUE
      ? allContacts.filter((n) => {
        const filterString = filterOption.toString();
        return n.contactoMotivo.area.id_area.toString() === filterString;
      })
      : allContacts;

  const dateFilteredData = statusFilteredData.filter((contact) => {
    return (
      (endDateFilter ? (new Date(contact.fechaCreacion).getTime()) <= endDateFilter : true) &&
      (startDateFilter ? (new Date(contact.fechaCreacion).getTime()) >= startDateFilter : true)
    );
  });

  const lowercaseSearchFilter = searchFilter.toLowerCase();
  const searchFilteredData =
    searchFilter === ""
      ? dateFilteredData
      : dateFilteredData.filter((d) =>
        d.contactoMotivo.descripcion.toLowerCase().includes(lowercaseSearchFilter)
      );

  const pageCount = Math.ceil(searchFilteredData.length / rowsPerPage);
  const paginatedData = searchFilteredData.slice(
    firstIndexInPage,
    firstIndexInPage + rowsPerPage
  );

  return (
    <Box p={6} sx={{ margin: "0 auto", width: "90%" }}>
      <Box mb={4}>
        <Typography variant="h4" component="h1">
          Contactos.
        </Typography>
      </Box>
      <Box mb={2} className={classes.actionBar}>
        <Box
          style={{ cursor: "pointer" }}
          onClick={goToCreateContact}
          display="flex"
          alignItems="center"
          mr={2}
        >
          <Box mr={1}>
            <AddCircleOutlineIcon fontSize="large" />
          </Box>
          <Typography className="bold" variant="body1">
            Nuevo Contacto
          </Typography>
        </Box>
        <TableFilter
          className={classes.actionSearch}
          options={allAreas}
          optionLabel="Área"
          currentSearch={searchFilter}
          showDatePicker
          searchPlaceholder="Buscar por motivo de contacto"
          onSearchChange={searchFilterHandler}
          onFilterChange={statusFilterHandler}
        />
      </Box>

      <Box>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    className={classes.tableHeader}
                    style={{ width: column.width }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {hasData &&
                paginatedData.map((row) => (
                  <TableRow className={classes.tableRow} key={row.id}>
                    <TableCell className={classes.tableCell}>
                      {convertDateHour(row?.fechaCreacion)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row?.contactoMotivo?.descripcion}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.contactoMotivo?.area?.denominacion}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {formatString(row?.pregunta)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.estado?.descripcion}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row?.areaDerivada?.denominacion ? row.areaDerivada.denominacion : "-"}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Tooltip title="Detalle del Contacto">
                        <VisibilityIcon
                          onClick={() => ViewSelected(row.id)}
                          className={classes.iconButton}
                          mr={2}
                        />
                      </Tooltip>
                      {!row.respuesta &&
                        <Tooltip title="Cancelar contacto">
                          <DeleteOutlineIcon
                            onClick={() => cancelContactHandler(row.id)}
                            className={classes.iconButton}
                          />
                        </Tooltip>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              {!hasData && !isLoading && (
                <TableRow hover role="checkbox">
                  <TableCell colSpan={columns.length}>
                    <Typography
                      variant="h6"
                      component="p"
                      style={{ textAlign: "center" }}
                    >
                      No hay contactos para mostrar.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {isLoading && <LoadingIcon />}

        {hasData && (
          <StyledPagination
            page={page}
            pageCount={pageCount}
            onChange={pageChangeHandler}
          />
        )}
      </Box>

      <BasicModal
        style={{ width: "400px" }}
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box mb={3}>
          <Typography align="center" variant="body2">{modalMsg}</Typography>
        </Box>
        {contactToDelete ?
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0 auto",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <BasicButton onClick={confirmDeleteContact} color="red">
              Eliminar
            </BasicButton>
            <BasicButton onClick={handleCloseModal} variant="outlined">
              Cancelar
            </BasicButton>
          </Box>
          :
          <BasicButton className={createResponse ? classes.button : classes.buttonError} onClick={handleCloseModal}>
            Cerrar
          </BasicButton>
        }
      </BasicModal>
    </Box>
  )
}
