import React, { useState, useEffect } from "react";
import "../resources/styles/navBar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppBar, Toolbar, makeStyles } from "@material-ui/core";
import { ProviderOptions, ProviderOptionsCuits } from "./Utils/locations";
import SectionSelector from "./NavbarComponents/SectionSelector";
import Logo from "./NavbarComponents/Logo";
import UserMenu from "./NavbarComponents/UserMenu";
import NavLinkStyled from "./NavbarComponents/NavLinkStyled";
import DocumentosImpuestoContext from "../contexts/documentosImpuestoContext";
import NotificationContext from '../contexts/notificationsContext';

const useStyles = makeStyles({
  style: {
    backgroundColor: "white",
    cursor: "pointer",
  },
  tabStyle: {
    display: "block",
    width: "50%",
    marginLeft: "2em",
  },
  logoStyle: {
    maxWidth: "250px",
    margin: "-25px",
    display: "inlineBlock",
    marginLeft: "-15%",
  },
  tabActive: {
    fontWeight: "bold",
  },
  btn0Style: {
    backgroundColor: "transparent",
    textTransform: "none",
    fontSize: "1.1vw",
    minWidth: "auto",
    color: "black",
    "&.indicator": {
      widht: "0.5vm",
    },
  },
  btnWarStyle: {
    backgroundColor: "transparent",
    textTransform: "none",
    fontSize: "1.1vw",
    minWidth: "auto",
    color: "red",
    "&.indicator": {
      widht: "0.5vm",
    },
    marginLeft: "5px",
  },
  dropdownStyle: {
    "&:hover,&:focus,&:active": {
      backgroundColor: "transparent",
      color: "#8c8777",
    },
    "&:enabled": {
      backgroundColor: "transparent",
      color: "#8c8777",
    },
    display: "inlineBlock",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontWeight: "900",
    textTransform: "capitalize",
    fontSize: 17,
    widht: "100px",
    marginLeft: "85%",
    marginTop: "-28%",
    border: "none",
    color: "#8c8777",
  },
  disableRipple: {
    backgroundColor: "transparent !important",
  },
  userMenuItem: {
    display: "flex",
    width: "200px",
    minWidth: "200px",
    justifyContent: "space-between",
  },
  menuItemStyle: {
    display: "block",
    color: "#000000",
    minWidth: "125vm",
    width: "125vm",
    fontSize: "14px",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  menuItemStyleActions: {
    display: "block",
    color: "#000000",
    fontWeight: "bold",
    minWidth: "125vm",
    width: "125vm",
    fontSize: "13px",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  warning: {
    color: "red",
    fontSize: "1.1vw"
  },
  navBar: {
    flexGrow: 1,
  },
  navBarList: {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    marginTop: "34px",
  },
});

const Navbar = () => {
  const classes = useStyles();
  const [dropdownValue, setDropdownValue] = useState(false);
  const [newTax, setNewTax] = useState(false);
  const maintenance = JSON.parse(window.localStorage.getItem("mantenimiento"));
  const user = JSON.parse(window.localStorage.getItem("user"))?.usersAssignments;
  const version = window.localStorage.getItem("version");
  const perms = window.localStorage.getItem("tknPms");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await DocumentosImpuestoContext.hasNewTaxes().then((data) => {
      setNewTax(data);
      localStorage.removeItem("taxStatus");
      localStorage.setItem("taxStatus", data);
      NotificationContext.setUserAssignmentLogTime(JSON.parse(window.localStorage.getItem("user")).id_usuario, JSON.parse(window.localStorage.getItem("provider")).id_proveedor);
    })
  }

  const DropdownAction = () => {
    setDropdownValue(!dropdownValue);
  };

  return (
    <div className="navBarContainer">
      <AppBar
        elevation={0}
        position="fixed"
        color="primary"
        className={classes.style}
      >
        <Toolbar className={maintenance?.estado === true ? "nav-maintenance" : ""}>
          <Logo />
          {maintenance?.estado === true ?
            <></>
            :
            <>
              <SectionSelector
                options={user.length > 1 ? ProviderOptionsCuits : ProviderOptions}
                selected="Portal - Proveedores"
                open={dropdownValue}
                toggle={DropdownAction}
              />

              <nav className={classes.navBar}>
                <ul className={classes.navBarList}>
                  <li>
                    <NavLinkStyled to={"/portal/providers?v=" + version}>Inicio</NavLinkStyled>
                  </li>
                  {maintenance?.estado === false && perms?.includes("ProveedorPago") && (
                    <li>
                      <NavLinkStyled to={"/payments?v=" + version}>Pagos</NavLinkStyled>
                    </li>
                  )}
                  {maintenance?.estado === false && perms?.includes("ProveedorDocumento") && (
                    <li>
                      <NavLinkStyled to={"/documents?v=" + version}>
                        Documentos
                      </NavLinkStyled>
                    </li>
                  )}
                  {maintenance?.estado === false && (
                    <li>
                      <NavLinkStyled to={"/contact?v=" + version}>
                        Contacto
                      </NavLinkStyled>
                    </li>
                  )}
                  {maintenance?.estado === false && perms?.includes("ProveedorAviso") && (
                    <li>
                      <NavLinkStyled to={"/notices?v=" + version}>Avisos</NavLinkStyled>
                    </li>
                  )}
                  {perms?.includes("ProveedorImpuesto") &&
                    (
                      <li>
                        <NavLinkStyled to={"/taxes?v=" + version}>
                          <span>Impuestos</span>
                          {newTax && (<span className={classes.btnWarStyle}>(!)</span>)}
                        </NavLinkStyled>
                      </li>
                    )
                  }
                </ul>
              </nav>
            </>

          }
          <UserMenu />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;