import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "../components/navBar";
import ContactBody from "../components/contactBody";
import CurrentProviderComponent from "../components/currentProviderComponent";
import { useDispatch } from "react-redux";
import { setListRelationsIds, setSelectedRecepciones, setCheckAnticipo, setSelectedNotaPedido, setNotaPedidoList } from "../store/documents/documentsSlice";

const index = 3;

const ContactPage = () => {
  const dispatch = useDispatch();
  
  const limpiarStore = () => {
    dispatch(setSelectedNotaPedido({}))
    dispatch(setNotaPedidoList([]))
    dispatch(setCheckAnticipo(false))
    dispatch(setSelectedRecepciones([]))
    dispatch(setListRelationsIds([]))
  };

  useEffect(() => {
    limpiarStore();
  }, []);

  return (
    <div className="mainBox">
      <Navbar index={index} />
      <CurrentProviderComponent />
      <ContactBody />
    </div>
  );
}

export default ContactPage;