import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import NavbarNoTabs from "../components/navBarNoTabs";
import ProviderSelectBody from "../components/providerSelectBody";

class ProviderSelectPage extends React.Component {
  render() {
    return (
      <div className="mainBox">
        <NavbarNoTabs />
        <ProviderSelectBody />
      </div>
    );
  }
}

export default ProviderSelectPage;