import axios from "axios";
export const apiUrl = `${window.location.protocol}//${window.location.host}/api`;


let getAuthenticationBearerToken = localStorage.getItem("token");
const api = axios.create({
    baseURL: apiUrl,
    headers: { Authorization: `Bearer ${getAuthenticationBearerToken}` }
});

function errorResponseHandler(error: any) {
  // check for errorHandle config
  if (
    error.config.hasOwnProperty("errorHandle") &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  // if has response show the error
  if (error.response) {
    if (error.response.status === 401){
      //If user is not authorized, go to login page.
      window.location.href = '/';
    }
  }
}

api.interceptors.response.use((response) => response, errorResponseHandler);

export default api;