import React, { useEffect } from "react";
import PaymentsFormsContext from "../contexts/paymentsFormsContext";
import VisitsContext from "../contexts/visitsContext";
import StyledTabs from "./UI/StyledTabs";
import MyPayments from "./Payments/MyPayments";
import MyRetentions from "./Payments/MyRetentions";
import { getFileTypeFromBase64 } from "./Utils/files";

export default function PaymentsReportBody() {

  useEffect(() => {
    VisitsContext.createVisit(4).then((e) => {});
  }, []);

  const downloadReceipt = async (id) => {
    const response = await PaymentsFormsContext.downloadReceipt(id);
    if (response && response.data) {
      const file = response.data;
      try {
        const fileType = getFileTypeFromBase64(file.imagen);
        const baseFileName = file.nombre_archivo || (fileType === 'pdf' ? 'pdf' : 'file');
        const fileName = baseFileName.endsWith(`.${fileType}`)
          ? baseFileName
          : `${baseFileName}.${fileType}`;
        const linkSource = `data:image/${fileType};base64,${file.imagen}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const tabsContent = [
    {
      label: "Mis Pagos.",
      content: MyPayments,
    },
    {
      label: "Mis Retenciones Impositivas.",
      content: () => <MyRetentions onDownload={downloadReceipt} />,
    },
  ];

  return (
    <div>
      <StyledTabs tabConfig={tabsContent} />
    </div>
  );
}
