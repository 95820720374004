import { Box, Button, Typography } from "@material-ui/core";
import BasicModal from "../../UI/BasicModal";
import { formatCurrencyDisplay } from "../../Utils/formatDisplay";

const DetalleItemRecepcionModal = ({ open, onClose, item }) => {
  return ( 
    <BasicModal
      open={open}
      onClose={onClose}
    >
      <Button onClick={onClose} style={{ fontWeight: "bold", alignSelf: "flex-end" }}>X</Button>
      <Box>
        <Box>
          <Typography variant="h5" style={{marginBottom: "20px"}}>Detalles del informe</Typography>
        </Box>
        <Box>
          <Typography style={{marginBottom: "20px"}}><b>Descripción: </b>{item ? item.descripcion : "-"}</Typography>
          <Typography style={{marginBottom: "20px"}}><b>Cantidad: </b>{item ? item.cantidad : "-"}</Typography>
          <Typography style={{marginBottom: "20px"}}><b>Precio: </b>{item ? formatCurrencyDisplay(item.precio) : "-"}</Typography>
          <Typography style={{marginBottom: "20px"}}><b>Observaciones: </b>{item ? item.observacion : "-"}</Typography>
        </Box>
      </Box>
    </BasicModal>
   );
}
 
export default DetalleItemRecepcionModal;