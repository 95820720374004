import api from "../services/api";
import { AxiosResponse } from "axios";

export class ConformityProvidedContext {
  static async setConformityProvided(Conformity: any) {
    const response = await api.post<Response, AxiosResponse<Response>>(
      `/conformityprovided`, Conformity);
    if (response?.data?.status) {
      return response.data;
    }
    else{
      console.log(`/conformityprovided`, response?.data);
      return null;
    }
  }
}

export default ConformityProvidedContext;