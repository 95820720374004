import React from "react";
import { Modal, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// Modal básico que muestra a sus children en una box centrada

const useStyles = makeStyles({
  box: {
    background: "white",
    border: 0,
    borderRadius: "12px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
})

const BasicModal = (props) => {

  const classes = useStyles();

  const closeHandler = () => {
      props.onClose();
  }

  return <Modal
    open={props.open}
    onClose={closeHandler}
  >
      <Box className={`${classes.box} ${props.className}`} style={props.style}>
          {props.children}
      </Box>
  </Modal>
};

export default BasicModal;
