import React from "react";
import { Modal, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import BasicButton from "../../UI/BasicButton";

const useStyles = makeStyles({
	box: {
		background: "white",
		border: 0,
		borderRadius: "12px",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		padding: "20px 30px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	text: {

	}
})

const CancelModal = (props) => {

	const classes = useStyles();

	const closeHandler = () => {
		props.onClose();
	}

	const cancelHandler = () => {
		props.cancelConfirmation()
	}

	return <Modal
		open={props.open}
		onClose={closeHandler}
	>
		<Box className={`${classes.box} ${props.className}`} style={props.style}>
			<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" alignContent="center">
				<Typography variant="h6">
					El documento no fue incorporado. ¿Está seguro de cancelar?
				</Typography>
			</Box>
			<Box mt={1} display="flex" style={{ gap: 10 }}>
				<BasicButton color="primary" variant="contained" onClick={() => cancelHandler()}>Si</BasicButton>
				<BasicButton color="primary" variant="contained" onClick={() => closeHandler()}>No</BasicButton>
			</Box>
		</Box>
	</Modal>
};

export default CancelModal;
