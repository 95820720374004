import { configureStore } from '@reduxjs/toolkit'
import contactsSlice from './contacts/contactsSlice'
import documentsSlice from './documents/documentsSlice'
import noticesSlice from './notices/noticesSlice'
import paymentsSlice from './payments/paymentsSlice'

export const store = configureStore({
  reducer: {
    documents: documentsSlice,
    notices: noticesSlice,
    contacts: contactsSlice,
    payments: paymentsSlice
  },
})