import React, { useState, useEffect } from 'react';
import api from "../services/api";
import jwt_decode from "jwt-decode";
import UserContext from '../contexts/userContext';
import NotificationContext from '../contexts/notificationsContext';
import SettingsContext from "../contexts/settingsContext";
import logoImage from "../resources/images/logo-tagline.png";
import "../resources/styles/loadingLogo.css"

export default function Auth() {
  let [reload, setReload] = useState(0);
  let [queryStr, setQueryStr] = useState("");
  const queryString = queryStr;

  useEffect(() => {
    if (queryStr === "") {
      routes();
    }
    else {
      if (!window.localStorage.getItem("status")) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        localStorage.setItem("status", "queryString");
        setReload(1);
      }
      else if (window.localStorage.getItem("status") === "queryString") {
        request();
      }
      else if (window.localStorage.getItem("status") === "tokenResponse") {
        getToken();
      }
    }
  }, [reload, queryStr]);

  async function routes() {
    await api.get(`/auth/routes`).then((e) => {
      setQueryStr(e.data);
    })
      .catch((e) => {
        console.error(e);
      });
  }

  function badLogin() {
    localStorage.setItem("status", "");
    window.location.href = "/auth";
  }

  function request() {
    localStorage.setItem("status", "tokenResponse");
    window.location.href = queryString;
  }

  async function getToken() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const mastelloneToken = params.mast;
    const token = await api.post(`/auth/token`, { mastelloneToken });
    let converted = "";
    if (token.data.includes("unauthorized") || token.data === "") {
      localStorage.clear();
      badLogin();
    }
    else {
      window.localStorage.setItem("token", token.data); //Backend JWT token
      setTimeout(function () { }, 1000);
      const mastelloneToken = jwt_decode(token.data);
      converted = mastelloneToken.unique_name; //Mastellone Token
      window.localStorage.setItem("data", converted);
      if (mastelloneToken.iss === "WebProveedoresMH") {
        window.localStorage.setItem("abbreviation", "MH");
      }
      if (mastelloneToken.iss === "WebProveedoresSL") {
        window.localStorage.setItem("abbreviation", "MSL");
      }
      const currentUser = await UserContext.fetchProviderUsersByMail((jwt_decode(converted)).unique_name);
      if (currentUser) {
        if (currentUser.tipo !== 1) {
          badLogin();
          return;
        }
        let permissions = (jwt_decode(converted)).Funciones;
        if (currentUser.usersAssignments.length > 0) {
          window.localStorage.setItem("tknUsr", (jwt_decode(converted)).unique_name);
          window.localStorage.setItem("tknPms", permissions);
          window.localStorage.setItem("cuit", jwt_decode(token.data).cuit);
          localStorage.setItem("status", "");
          window.localStorage.setItem("user", JSON.stringify(currentUser));
          await NotificationContext.setUserLogTime();

          if (permissions.includes("AccesoSPAC")) {
            let spacBaseUrl = await UserContext.fetchSpacUrl();
            window.localStorage.setItem('AccesoSPAC', spacBaseUrl);
          }

          const settings = await SettingsContext.fetchSettings();
          if (settings) {
            window.localStorage.setItem("mantenimiento", JSON.stringify(settings[0]));
          }
          if (!currentUser.acepto_Terminos && currentUser.tipo === 1) {
            window.location.href = "/termsAndConditions";
          } else {
            if (currentUser?.usersAssignments.length === 1) {
              window.localStorage.setItem("provider", JSON.stringify(currentUser.usersAssignments[0].providers))
              window.location.href = "/portal/providers";
            } else if (currentUser?.usersAssignments.length > 1) {
              window.location.href = "/portal/providerSelect";
            }
          }
        }
        else if (currentUser.usersAssignments.length === 0) {/*If user not assigned as provider*/
          localStorage.setItem("status", "");
          window.location = "/unassigneduser";
        }
      }
      else if (currentUser === null) { /*If user does not exist in database*/
        localStorage.setItem("status", "");
        let user = jwt_decode(converted);
        let objUser = {
          unique_name: user.unique_name,
          tipoUsuario: user.TipoUsuario
        };
        UserContext.createUser(objUser, token.data).then(() => {
          window.location = "/unassigneduser";
        });
      }
      if (localStorage.getItem("version")) {
        localStorage.removeItem("version")
      }
      localStorage.setItem("version", JSON.stringify(jwt_decode(token.data).aud));
    }
  }

  return <div className="mainBox" style={{ height: "700px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
    <img className="loadingLogo" src={logoImage} alt="Logo La Serenísima" />
    <p style={{ fontSize: "24px" }}><strong>Autenticando...</strong></p>
  </div>
}