import React, { useCallback, useEffect, useState } from "react";
import { Box, makeStyles, Snackbar, Typography } from "@material-ui/core";
import StatesContext from "../contexts/statesContext";
import NoticesContext from "../contexts/noticesContext";
import PaymentsContext from "../contexts/paymentsContext";
import NotificationContext from "../contexts/notificationsContext";
import ContactContext from "../contexts/contactContext"
import MuiAlert from '@material-ui/lab/Alert';

import moment from "moment";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  textLine: {
    marginTop: "1em",
  },
  scroller: {
    height: "200px",
    overflowY: "auto",
  },
});

export default function UserDataComponent(props) {
  const user = props;
  const [allStates, setAllStates] = useState([]);
  const [allNotices, setAllNotices] = useState([]);
  const [newPayments, setNewPayments] = useState([]);
  const [newness, setNewness] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasContact, setHasContact] = useState(false);

  const classes = useStyles();

  const { id_proveedor } = user?.props;

  const fetchData = useCallback(async () => {
    const [notifications, notices, states, payments, contact] = await Promise.all([
      NotificationContext.getNewness(),
      NoticesContext.fetchNoticesByCuit(),
      StatesContext.fetchStates(),
      PaymentsContext.fetchPayments(),
      ContactContext.alertNewContactProv(),
    ]);

    setAllStates(states);
    setHasContact(contact?.status)

    if (contact?.error) {
      console.error(contact?.error);
    }

    const notificationData = notifications?.filter(
      (notif) => notif.id_proveedor === id_proveedor
    );
    if (notificationData) setNewness(notificationData);

    const lastLogin = new Date(JSON.parse(window.localStorage.getItem("user")).fecha_ult_ingreso);
    const newPays = payments?.filter(
      (pay) => lastLogin <= new Date(pay.fecha_disponible)
    );
    if (newPays) setNewPayments(newPays);

    const filteredNotices = notices
      ?.filter(
        (notice) =>
          (checkPeriod(notice.periodo_vigencia) &&
            notice.destacado === "destacado") ||
          (notice.destacado !== "destacado" &&
            lastLogin <= new Date(notice.fecha_aviso))
      )
      .sort((a, b) => new Date(b.fecha_aviso) - new Date(a.fecha_aviso));
    if (filteredNotices) setAllNotices(filteredNotices);

    setIsLoading(false);
  }, [id_proveedor]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatString = (str) => {
    return str.length > 100 ? str.substring(0, 100) + "..." : str;
  };

  function getState(column) {
    if (allStates !== null && allStates !== undefined && allStates !== "") {
      let state = allStates.filter((state) => state.id_estado === column)[0]
        .descripcion_abreviada;
      return state;
    }
    return "No definido";
  }

  function checkPeriod(vigencia) {
    var check = new Date();
    var dates = vigencia.split("-");
    var from = new Date(dates[2], dates[1] - 1, dates[0]);
    var to = new Date(dates[5], dates[4] - 1, dates[3]);
    return check <= to && check >= from;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setHasContact(false);
  };

  return (
    <Box mt={4}>
      {newness?.length > 0 ||
        allNotices?.length > 0 ||
        newPayments?.length > 0 ? (
        <Typography variant="h6" component="h2">
          Novedades.
        </Typography>
      ) : (
        <Typography className={classes.textLine}>
          Sin novedades para este proveedor.
        </Typography>
      )}
      {isLoading && <Typography>Cargando novedades...</Typography>}
      <Box className={classes.scroller}>
        {allNotices && (
          <>
            {allNotices.map((column, index) => (
              <Typography key={index} className={classes.textLine}>
                - El aviso <strong>{formatString(column.titulo_aviso)}</strong>{" "}
                se encuentra disponible.
              </Typography>
            ))}
          </>
        )}
        {newness && (
          <>
            {newness.map((column) => (
              <Typography
                className={classes.textLine}
                key={column.id_documento}
              >
                - El documento{" "}
                <strong>
                  {column.letra_documento +
                    "-" +
                    column.prefijo_documento.toString().padStart(5, "0") +
                    "-" +
                    column.numero_documento.toString().padStart(8, "0")}
                </strong>{" "}
                se encuentra en estado{" "}
                <strong>{getState(column.id_estado)}</strong> a partir del{" "}
                <strong>
                  {moment(column.fecha_documento).format("DD/MM/YYYY")}
                </strong>
              </Typography>
            ))}
          </>
        )}
        {newPayments && (
          <>
            {newPayments.map((column) => (
              <Typography className={classes.textLine} key={column.id_pago}>
                - El documento{" "}
                <strong>
                  {column.prefijo_pago.toString() +
                    "-" +
                    column.numero_pago.toString()}
                </strong>{" "}
                se encuentra en estado{" "}
                <strong>{getState(column.id_estado)}</strong> a partir del{" "}
                <strong>
                  {moment(column.fecha_disponible).format("DD/MM/YYYY")}
                </strong>
              </Typography>
            ))}
          </>
        )}
      </Box>
      <Box mt={4}>
        <Typography variant="h6" component="h2">
          Tus datos.
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
        <Box mr={10}>
          <Typography className={classes.textLine}>
            <strong>Nombre de usuario: </strong>
            {user.props.razon_social}
          </Typography>
          <Typography className={classes.textLine}>
            <strong>CUIT asociado: </strong>
            {user.props.cuit}
          </Typography>
          <Typography className={classes.textLine}>
            <strong> Razón social: </strong>
            {user.props.razon_social}
          </Typography>
          <Typography className={classes.textLine}>
            <strong> Domicilio: </strong>
            {user.props.domicilio}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.textLine}>
            <strong> Localidad: </strong>
            {user.props.localidad}
          </Typography>
          <Typography className={classes.textLine}>
            <strong> Código Postal: </strong>
            {user.props.codigo_postal}
          </Typography>
          <Typography className={classes.textLine}>
            <strong> Teléfono: </strong>
            {user.props.telefono}
          </Typography>
        </Box>
      </Box>
      {hasContact &&
        <Snackbar open={hasContact} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="info">
            Tiene respuestas a sus consultas en Contactos
          </Alert>
        </Snackbar>
      }
    </Box>
  );
}
