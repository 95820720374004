import api from "../services/api";

export class PaymentsContext {

  static async fetchPayments() {
    let providerId: number = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response = await api.get(
      `/payments/getById/${providerId}`);
    if (response && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async fetchPaymentsWithState() {
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response = await api.get(
      `/payments/getByIdView`, {params:{ providerId }});
    if (response && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async fetchPaymentsWithStatePagination(pageNumber: number = 1, pageSize: number = 10, filter: any = null) {
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;

    const response = await api.get(
      `/payments/getByIdViewPag`, {params:{ pageSize,pageNumber,providerId }});
    if (response && response.data) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async fetchDetailedReport() {
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response = await api.get(
      `/payments/getDetailedReport`, {params:{ providerId }});
    if (response && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async fetchDetailedReportPagination(pageNumber: number = 1, pageSize: number = 1, filter: any = null) { 
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response = await api.get(
      `/payments/getDetailedReportPag`, {params:{ pageSize,pageNumber,providerId }});
      if (response && response.data) {
        return response.data;
      }
      else {
        return null;
      }
  }

  static async fetchPaymentsWithFormDetailsPagination(pageNumber: number = 1, pageSize: number = 10, filter: any = null) {
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const stateId = filter?.estadoId || null;
    const operationNumber = filter?.numeroOp || null;
    const response = await api.get(
      `/payments/getByIdFormDetailsPagination`, {params:{ pageSize,pageNumber,stateId, operationNumber, providerId }});
      if (response && response.data) {
        return response.data;
      }
      else {
        return null;
      }
  }
}

export default PaymentsContext;