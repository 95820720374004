import React, { useEffect, useState } from "react";
import '../resources/styles/footer.css';

export default function Footer() {
  const [version, setVersion] = useState("");
  useEffect(() => {
    const versionStorage = localStorage.getItem("version");
    if (versionStorage) {
      setVersion(JSON.parse(versionStorage));
    }
  }, [localStorage.getItem("version")]);

  return (
    <footer className="footer">
      <div  className="footer-container">
        <p className="footer-brand">LA SERENISIMA</p>
        <div>
          <p className="footer-copyright">TODOS LOS DERECHOS RESERVADOS MASTELLONE HERMANOS S.A.</p>
          <p className="footer-version">Versión: {version ? version : "cargando..."}</p>
        </div>
      </div>
    </footer>
  );
}