import { useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Paper,
  Badge,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedRecepciones,
  setNotaPedidoForContact,
  setReceptionsBalanced,
} from "../../store/documents/documentsSlice";
import RecepcionContext from "../../contexts/recepcionContext";
import {
  convertDate,
  convertDateHour,
  formatCurrencyDisplay,
} from "../Utils/formatDisplay";
import ItemsRecepcionModal from "./Modals/ItemsRecepcionModal";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
  },
  tableContainerFooter: {
    border: "1px solid #212529",
  },
  tableCell: {
    fontSize: "1rem",
    fontWeight: "400",
  },
  scroll: {
    maxHeight: "220px",
    border: "2px solid #212529",
  },
  icon: {
    cursor: "pointer",
  },
  colorSuccess: {
    color: "green",
  },
  colorError: {
    color: "red",
  },
});

const StepRecepcion = ({
  setContinueStep,
  setBalanceReception,
  setAdjustmentsBalance,
  setListTaxes,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setListTaxes([]);
  }, []);

  const selectedNotaPedido = useSelector((state) => {
    return state.documents.selectedNotaPedido;
  });
  const notaPedidoListStore = useSelector((state) => {
    return state.documents.notaPedidoList;
  });
  const qrData = useSelector((state) => {
    return state.documents.qrForm;
  });
  const tolerancia = useSelector((state) => {
    return state.documents.tolerancia;
  });

  const [recepcionData, setRecepcionData] = useState([]);
  const [recepcionList, setRecepcionList] = useState([]);
  const [notaPedido, setNotaPedido] = useState(0);
  const [notaPedidoDisabled, setNotaPedidoDisabled] = useState(false);
  const [notaPedidoList, setNotaPedidoList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [diffOk, setDiffOk] = useState(false);
  const [errorNotaPedidoRepetida, setErrorNotaPedidoRepetida] = useState(false);
  const [openItems, setOpenItems] = useState(false);
  const [recepcionSeleccionada, setRecepcionSeleccionada] = useState(null);

  const getRecepciones = async (idNotaPedido) => {
    let result = [];
    if (idNotaPedido > 0) {
      result = await RecepcionContext.getRecepcionById(idNotaPedido);
    }
    if (idNotaPedido === 0) {
      result = await RecepcionContext.getAllAvailable();
    }
    setRecepcionData(result);
    setIsLoading(false);
  };

  useEffect(() => {
    getRecepciones(selectedNotaPedido.id);
    setNotaPedido(selectedNotaPedido.id);
    setNotaPedidoDisabled(selectedNotaPedido.id !== 0);
    setNotaPedidoList(notaPedidoListStore);
    setContinueStep(true);
  }, [selectedNotaPedido, notaPedidoListStore]);

  const valorizacionTotal = useMemo(() => {
    return recepcionList.reduce((acc, recepcion) => {
      return acc + parseFloat(recepcion.valorizacion) || 0;
    }, 0);
  }, [recepcionList]);

  useEffect(() => {
    let dif = valorizacionTotal - parseFloat(qrData.importeTotalPesosNoTax);
    console.log({
      valorizacionTotal,
      importeTotalPesosNoTax: parseFloat(qrData.importeTotalPesosNoTax),
      dif,
      tolerancia,
      pasa: diffOk,
      cantDeRecepciones: recepcionList.length,
    });
    if (dif === 0) {
      setBalanceReception(true);
      setDiffOk(true);
      setContinueStep(true);
      dispatch(setReceptionsBalanced(true));
    } else if (
      (dif > 0 && dif <= tolerancia) ||
      (dif < 0 && dif >= -tolerancia) ||
      recepcionList.length === 0
    ) {
      setDiffOk(false);
      setBalanceReception(false);
      setAdjustmentsBalance(valorizacionTotal);
      dispatch(setReceptionsBalanced(false));
    } else {
      setDiffOk(false);
      setBalanceReception(false);
      setAdjustmentsBalance(valorizacionTotal);
      dispatch(setReceptionsBalanced(true));
    }

    if (recepcionList.length === 0) {
      setDiffOk(false);
      setContinueStep(false);
    }
  }, [valorizacionTotal, qrData.importeTotalPesosNoTax, tolerancia]);

  useEffect(() => {
    dispatch(setSelectedRecepciones(recepcionList));
    let np = {};
    if (notaPedidoList.length > 0) {
      if (recepcionList.length > 0) {
        np = notaPedidoList.find(
          (x) => x.id === recepcionList[0]?.notaPedidoId
        ) || { id: 0, numero: "No conozco NP" };
      } else {
        np = notaPedidoList.find((x) => x.id === notaPedido);
      }
      dispatch(setNotaPedidoForContact(np));
    }
    if (
      !recepcionList.every(
        (recepcion) =>
          recepcion.notaPedidoNumero === recepcionList[0].notaPedidoNumero
      )
    ) {
      setContinueStep(false);
      setErrorNotaPedidoRepetida(true);
    } else if (recepcionList.length === 0) {
      setContinueStep(false);
    } else {
      setContinueStep(true);
      setErrorNotaPedidoRepetida(false);
    }
  }, [recepcionList]);

  const handleAddButton = (recepcionValue) => {
    setRecepcionList((prev) => [...prev, recepcionValue]);
    setRecepcionData((prev) =>
      prev.filter((recepcion) => recepcion.id !== recepcionValue.id)
    );
  };

  const handleDetailButton = (recepcionValue) => {
    setRecepcionSeleccionada(recepcionValue);
    setOpenItems(true);
  };

  const handleDeleteClick = (recepcionValue) => {
    setRecepcionList((prev) =>
      prev.filter((recepcion) => recepcion.id !== recepcionValue.id)
    );
    setRecepcionData((prev) => [...prev, recepcionValue]);
  };

  const handleNotaPedidoChange = (value) => {
    setNotaPedido(value);
    getRecepciones(value);
    setRecepcionList([]);
    const np = notaPedidoList.find((x) => x.id === value);
    dispatch(setNotaPedidoForContact(np));
  };

  return (
    <Box display={"flex"} flexDirection={"column"} style={{ gap: 15 }}>
      <Typography variant="h4">Seleccionar recepción</Typography>
      <Box display="flex" style={{ gap: 10 }}>
        <Select
          style={{ width: "500px" }}
          label="Nota de Pedido"
          value={notaPedido}
          disabled={notaPedidoDisabled}
          onChange={(e) => handleNotaPedidoChange(e.target.value)}
        >
          {notaPedidoList.map((option, index) => {
            return (
              <MenuItem key={index + option.numero} value={option.id}>
                {option.fechaEmision &&
                  `Fecha de Emisión: ${convertDate(
                    option.fechaEmision
                  )} - Número:`}{" "}
                {option.numero}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box
        style={{ width: "80%", gap: 15 }}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Recepciones disponibles</Typography>
          <TableContainer className={classes.scroll}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>N° Nota Pedido</TableCell>
                  <TableCell>Remito</TableCell>
                  <TableCell>N° Informe</TableCell>
                  <TableCell>Fecha Informe</TableCell>
                  <TableCell>Valorizacion</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  recepcionData.length > 0 ? (
                    recepcionData.map((recepcion, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {recepcion.notaPedidoNumero}
                        </TableCell>
                        <TableCell>{recepcion.numeroRemito}</TableCell>
                        <TableCell>{recepcion.numeroRecepcion}</TableCell>
                        <TableCell>
                          {convertDateHour(recepcion.fechaRecepcion)}
                        </TableCell>
                        <TableCell>
                          {formatCurrencyDisplay(recepcion.valorizacion)}
                        </TableCell>
                        <TableCell>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <Tooltip arrow title="Agregar recepcion">
                              <AddCircleIcon
                                className={classes.icon}
                                onClick={() => handleAddButton(recepcion)}
                              />
                            </Tooltip>
                            <Tooltip
                              arrow
                              title={
                                recepcion.items.length === 0 ||
                                  recepcion.items.every(
                                    (item) => item.rechazos.length === 0
                                  )
                                  ? "Ver informe de Recepción"
                                  : "Existen rechazos en el informe"
                              }
                            >
                              <Badge
                                badgeContent="!"
                                invisible={
                                  recepcion.items.length === 0 ||
                                  recepcion.items.every(
                                    (item) => item.rechazos.length === 0
                                  )
                                }
                                color="error"
                              >
                                <FormatListBulletedOutlinedIcon
                                  className={classes.icon}
                                  onClick={() => handleDetailButton(recepcion)}
                                />
                              </Badge>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Box
                          display={"flex"}
                          justifyContent="center"
                          width="100%"
                          alignItems={"center"}
                        >
                          <Typography>
                            No hay recepciones disponibles
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box
                        display={"flex"}
                        justifyContent="center"
                        width="100%"
                        alignItems={"center"}
                      >
                        <CircularProgress size={30} thickness={8} />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="h6">Recepciones seleccionadas</Typography>
          <TableContainer className={classes.scroll}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>N° Nota Pedido</TableCell>
                  <TableCell>Remito</TableCell>
                  <TableCell>N° Informe</TableCell>
                  <TableCell>Fecha Informe</TableCell>
                  <TableCell>Valorizacion</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recepcionList.length > 0 ? (
                  recepcionList.map((recepcion, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {recepcion.notaPedidoNumero}
                      </TableCell>
                      <TableCell>{recepcion.numeroRemito}</TableCell>
                      <TableCell>{recepcion.numeroRecepcion}</TableCell>
                      <TableCell>
                        {convertDateHour(recepcion.fechaRecepcion)}
                      </TableCell>
                      <TableCell>
                        {formatCurrencyDisplay(recepcion.valorizacion)}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Borrar recepcion">
                          <DeleteIcon
                            className={classes.icon}
                            onClick={() => handleDeleteClick(recepcion)}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box
                        display={"flex"}
                        justifyContent="center"
                        width="100%"
                        alignItems={"center"}
                      >
                        <Typography>
                          No hay recepciones seleccionadas
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {errorNotaPedidoRepetida && (
            <Typography color="error">
              No se puede seleccionar recepciones con nota de pedido diferente
            </Typography>
          )}
          {recepcionList.length === 0 && (
            <Typography color="error">
              Debe seleccionar al menos una recepción para poder continuar
            </Typography>
          )}
        </Box>
      </Box>
      <Box style={{ width: "40%", gap: 15 }}>
        <TableContainer
          component={Paper}
          className={classes.tableContainerFooter}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  Total sin impuestos:
                </TableCell>
                <TableCell align="right">
                  <b>
                    $ {parseFloat(qrData?.importeTotalPesosNoTax).toFixed(2)}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  Valorización total:
                </TableCell>
                <TableCell align="right">
                  <b>$ {valorizacionTotal.toFixed(2)}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={
                    diffOk
                      ? `${classes.colorSuccess} ${classes.tableCell}`
                      : `${classes.colorError} ${classes.tableCell}`
                  }
                >
                  Diferencia:
                </TableCell>
                <TableCell
                  align="right"
                  className={diffOk ? classes.colorSuccess : classes.colorError}
                >
                  <b>
                    ${" "}
                    {(
                      valorizacionTotal -
                      parseFloat(qrData?.importeTotalPesosNoTax)
                    ).toFixed(2)}
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ItemsRecepcionModal
        open={openItems}
        onClose={() => setOpenItems(false)}
        recepcion={recepcionSeleccionada}
      />
    </Box>
  );
};

export default StepRecepcion;
