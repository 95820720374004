import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, AppBar, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const MastTabs = withStyles({
  indicator: {
    top: "0",
  },
})(Tabs);
const MastTab = withStyles({
  root: {
    minWidth: "15em",
    width: "40%",
    maxWidth: "37em",
    background: "lightgray",
    "&.Mui-selected": {
      background: "white",
    },
    textTransform: "none",
  },
})(Tab);

const useStyles = makeStyles(() => ({
  tabPanel: {
    padding: "2em 0",
  },
}));

function TabPanel(props) {
  const { children, propValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={propValue !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {propValue === index && <Box>{children?.()}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  propValue: PropTypes.any.isRequired,
};

const StyledTabs = (props) => {
  const { tabConfig, tabStyle, currentTab } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(currentTab ?? 0);
  }, [currentTab])

  const handleChange = (event, newValue) => {
    if(props.setCurrentTab){
      props.setCurrentTab(newValue);
    }
    setValue(newValue);
  };

  return (
    <Box marginTop={2} display="flex" justifyContent="center">
      <Box width="90%">
        <AppBar elevation={0} color="transparent" position="static">
          <MastTabs
            className={classes.tabs}
            indicatorColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabConfig.map((el, index) => (
              <MastTab
                style={tabStyle}
                label={
                  <Typography variant="h5" component="p">
                    {el.label}
                  </Typography>
                }
                key={index}
              />
            ))}
          </MastTabs>
        </AppBar>
        {tabConfig.map((el, index) => (
          <TabPanel
            className={classes.tabPanel}
            propValue={value || 0}
            index={index}
            key={index}
          >
            {el.content}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default StyledTabs;
