import api from "../services/api";
import { formatDateObjectToUS } from "../components/Utils/formatDisplay";

export class PaymentsFormsContext {
  static async fetchPaymentsForms(PageNumber: number, PageSize: number, currentDetailPayment: any) {
    let token = currentDetailPayment || "";
    let paymentFormId;
    if (token.id_pago !== undefined) {
      paymentFormId = token.id_pago;
      const response = await api.get(`/paymentsforms/getById`, { params: { PageSize, PageNumber, paymentFormId } });
      if (response && response.data) {
        return response.data;
      } else {
        return null;
      }
    }
  }

  static async fetchPaymentsFormsByProvider(PageNumber: number = 1, PageSize: number = 10, filter: any = null) {
    let ProviderId = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor || "";
    const fromDateRaw = filter?.fromDate || null;
    const toDateRaw = filter?.toDate || null;
    const Search = filter?.search?.toLowerCase() || null;
    const FromDate = fromDateRaw ? formatDateObjectToUS(new Date(fromDateRaw)) : null;
    const ToDate = toDateRaw ? formatDateObjectToUS(new Date(toDateRaw)) : null;
    const response = await api.get(`/paymentsforms/getAllByProvPays`, { params: { PageSize, PageNumber, ProviderId, Search, FromDate, ToDate } });
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }

  }

  static async downloadReceipt(paymentFormId: number) {
    const response = await api.get(`/paymentsforms/download`, { params: { paymentFormId } });
    if (response) return response;
    else return null;
  }

  static async fetchPaymentsFormsNoPagination(paymentFormId: number) {
    const response = await api.get(`/paymentsforms/getByIdNoPag`, { params: { paymentFormId } });
    if (response !== undefined && response.data !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }
}

export default PaymentsFormsContext;
