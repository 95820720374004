import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "../components/navBar";
import ContactViewBody from "../components/contactViewBody";

const index = 3;

class ContactViewPage extends React.Component {
    render() {
        return (
            <div className="mainBox">
                <Navbar index={index} />
                <ContactViewBody />
            </div>
        );
    }
}
export default ContactViewPage;