import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const useStyles = makeStyles({
  dropdownStyle: {
    "&:hover,&:focus,&:active": {
      backgroundColor: "transparent",
      color: "#000000",
    },
    backgroundColor: "transparent",
    display: "inlineBlock",
    cursor: "pointer",
    textTransform: "capitalize",
    fontFamily: "Montserrat, Arial, sans-serif",
    fontSize: 20,
    border: "none",
  },
  dropdowncontainerStyle: {
    maxWidth: "20%",
  },
});

const SectionSelector = (props) => {
  const { options, toggle, open, selected } = props;

  const history = useHistory();

  const classes = useStyles();

  const redirect = (route) => {
    if (route.startsWith('http://') || route.startsWith('https://')) {
      if (route.toLowerCase().includes('spac')) {
        const token = window.localStorage.getItem('token');
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = route;

        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'ivstrJWT';
        input.value = token;

        form.appendChild(input);

        const newTab = window.open('', '_blank');
        newTab.document.body.appendChild(form);
        form.submit();
      } else {
        window.open(route, '_blank');
      }
    } else {
      history.push(route);
    }
  };

  return (
    <Dropdown
      isOpen={open}
      toggle={toggle}
      className={classes.dropdowncontainerStyle}
    >
      <DropdownToggle
        color="transparent"
        caret
        className={classes.dropdownStyle}
      >
        {selected}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((el, index) => (
          <DropdownItem key={index} onClick={() => redirect(el.route)}>
            {el.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SectionSelector;
