import { useEffect, useState } from "react";
import { Box, Checkbox, MenuItem, Select, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedNotaPedido, setNotaPedidoList, setCheckAnticipo, setNotaPedidoForContact, setCheckSinNotaPedido } from "../../store/documents/documentsSlice";
import NotaPedidoContext from "../../contexts/notaPedidoContext"
import { convertDate } from '../Utils/formatDisplay';


const StepNotaPedido = ({ setContinueStep }) => {
  const checkboxEnabled = JSON.parse(window.localStorage.getItem('provider')).sinNotaPedidoHabilitado
  setContinueStep(true)
  const dispatch = useDispatch();
  const [notaPedido, setNotaPedido] = useState(0);
  const [notasPedidoList, setNotasPedidoList] = useState([])

  const checkedAnticipo = useSelector(state => state.documents.checkAnticipo)
  const checkedSinNotaPedido = useSelector(state => state.documents.checkSinNotaPedido) ?? false
  const [permiteAnticipo, setPermiteAnticipo] = useState(false)

  useEffect(() => {
    getNotaPedido()
    setPermiteAnticipo(checkedAnticipo)
    dispatch(setNotaPedidoForContact({ id: 0, numero: 'No conozco NP' }))
  }, [])

  const getNotaPedido = async () => {
    const result = await NotaPedidoContext.getNotaPedidoById()
    const primerElemento = { id: 0, numero: 'No conozco NP' }
    setNotasPedidoList([primerElemento, ...result]);
    setNotaPedido(primerElemento);
    dispatch(setSelectedNotaPedido(primerElemento))
    dispatch(setNotaPedidoList([primerElemento, ...result]))
  }

  const handleChange = (value) => {
    setNotaPedido(value)
    dispatch(setSelectedNotaPedido(value))
    dispatch(setNotaPedidoForContact(value))
    setPermiteAnticipo(false)
    dispatch(setCheckAnticipo(false))
  }

  const handleSinNotaPedido = (value) => {
    setPermiteAnticipo(value)
    dispatch(setCheckSinNotaPedido(value))
  }

  const handleAnticipo = (value) => {
    setPermiteAnticipo(value)
    dispatch(setCheckAnticipo(value))
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      {notaPedido?.permiteAnticipo}
      <Typography variant="h4">Seleccionar Nota de pedido</Typography>
      <Box width={'500px'}>
        <Select
          style={{ width: '100%' }}
          value={notaPedido}
          onChange={(e) => handleChange(e.target.value)}
        >
          {notasPedidoList?.map((notaPedido) => {
            return <MenuItem key={notaPedido.id} value={notaPedido}>
              {notaPedido.fechaEmision ? `Número: ${notaPedido.numero} - Fecha de Emisión: ${convertDate(notaPedido.fechaEmision)}` :
                "No conozco NP"}
            </MenuItem>
          })}
        </Select>
      </Box>
      {checkboxEnabled && (
        <Box display={'flex'}>
          <Checkbox checked={checkedSinNotaPedido} onClick={(e) => handleSinNotaPedido(e.target.checked)}>Sin nota pedido</Checkbox>
          <Typography style={{ display: 'flex', alignItems: 'center' }}>Sin nota de pedido</Typography>
        </Box>
      )}
      {
        notaPedido?.permiteAnticipo && (
          <Box display={'flex'}>
            <Checkbox checked={permiteAnticipo} onClick={(e) => handleAnticipo(e.target.checked)}>Permite anticipo</Checkbox>
            <Typography style={{ display: 'flex', alignItems: 'center' }}>Permite anticipo</Typography>
          </Box>
        )
      }
    </Box>
  );
}
export default StepNotaPedido;