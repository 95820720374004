import api from "../services/api";
import { AxiosResponse } from "axios";
import axios from "axios";
import { User } from "../../types/user";

interface Response {
  data: IUser;
}

export interface IUser {
  Id_usuario: any;
  Tipo: any;
  Mail: any;
  Fecha_registro: any;
  Fecha_ult_ingreso: any;
}

export class UserContext {
  static async setTerms(userId: number) {
    const response = await api.put<Response, AxiosResponse<Response>>(
      `/users/setTerms/${userId}`
    );
    if (response !== undefined && response.data !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async createUser(user: any, token: any) {
    const apiUrl = `${window.location.protocol}//${window.location.host}/api`;
    const currentApi = axios.create({
      baseURL: apiUrl,
      headers: { Authorization: `Bearer ${token}` }
    });
    const response = await currentApi.post<Response, AxiosResponse<Response>>(`/users/create`, { UserName: user.unique_name, UserType: user.tipoUsuario });
    return response;
  }

  static async fetchProviderUsersByMail(userMail: string) {
    const response: any = await api.get<Response, AxiosResponse<Response>>(
      `/users/userByMail`, { params: { userMail } }
    );
    if (response && response.data) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async fetchSpacUrl() {
    const response: any = await api.get<Response, AxiosResponse<Response>>(`/users/spac`);
    if (response && response.data) {
      return response.data;
    }
    else {
      return null;
    }
  }

  static async getInternalUserById(user: any) {
    const userId = user.id_usuario
    const response: any = await api.get<Response, AxiosResponse<Response>>(`/users/user/${userId}`);
    if (response && response.data) {
      return response.data;
    }
    else {
      return null;
    }
  }
}

export default UserContext;