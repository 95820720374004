import api from "../services/api";
import { AxiosResponse } from "axios";

interface Response {
  data: ISetting;
}

export interface ISetting {
  Id_configuracion: any;
  Accion: any;
  Estado: any;
  Descripccion: any;
  Id_usuario: any;
}

export class SettingsContext {
  static async fetchSettings() {
    try {
      const response = await api.get<Response, AxiosResponse<Response>>(
        `/settings`
      );
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      }
      else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default SettingsContext;