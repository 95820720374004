import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  navLink: {
    textTransform: "none",
    minWidth: "auto",
    color: "black",
    marginRight: "1em",
    fontFamily: "Montserrat, Arial, sans-serif",
    fontSize: "1.1vw",
    fontWeight: 500,
    "&:hover": {
      color: "black",
    },
  },
  navLinkActive: {
    fontWeight: "bold",
    borderBottom: "3px solid green",
  },
});

const NavLinkStyled = (props) => {
  const classes = useStyles();

  return (
    <NavLink
      to={props.to}
      className={classes.navLink}
      activeClassName={classes.navLinkActive}
      style={{ textDecoration: "none" }}
    >
      {props.children}
    </NavLink>
  );
};

export default NavLinkStyled;
