import api from "../services/api";

export class ImpuestoDocumentoAfipContext {
  static async saveDocumentTaxesAfip(listTaxes: any) {
    const response: any = await api.post(`/ImpuestoDocumentoAfip/SaveDocumentTaxesAfip`, listTaxes);
    if (response?.data?.status) {
      return response.data;
    } else {
      console.log(`/ImpuestoDocumentoAfip/SaveDocumentTaxesAfip`, response.data)
      return null;
    }
  }
}

export default ImpuestoDocumentoAfipContext;