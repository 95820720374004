import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import CommonNoticesView from "../components/commonNoticesView";
import Navbar from "../components/navBar";

const index = 4;

class NoticesCommonViewPage extends React.Component {
  render() {
    return (
      <div className="mainBox">
        <Navbar index={index} />
        <CommonNoticesView />
      </div>
    );
  }
}
export default NoticesCommonViewPage;
