import api from "../services/api";

export class StatesContext {
  static async fetchStates() {
    const response = await api.get(`/states`);
    if (response !== undefined && response.data[0] !== undefined) {
      return response.data;
    }
    else {
      return null;
    }
  }
}

export default StatesContext;