import React, { Fragment, useState } from "react";
import {
  makeStyles,
  Typography,
  Select,
  Box,
  Menu,
  MenuItem,
  Input,
  TextField,
} from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import TuneIcon from "@material-ui/icons/Tune";
import BasicButton from "./BasicButton";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "2em",
  },
  tuneButton: {
    cursor: "pointer",
  },
  searchInput: {
    width: "17em",
    marginLeft: "1em",
    backgroundColor: "transparent",
  },
  filterMenuBox: {
    display: "flex",
    flexDirection: "column",
  },
  filterMenuButtonBox: {
    display: "flex",
    flexDirection: "row",
  },
  filterMenuSelect: {
    marginBottom: "1em",
  },
  filterMenuButton: {
    marginRight: "0.5em",
  },
  datePicker: {
    width: "100%",
  },
  searchIcon: {
    color: "gray",
  },
});

const TableFilterMenu = (props) => {
  const classes = useStyles();

  const convertStringDate = (stringDate) => {
    if (stringDate) {
      const year = stringDate.slice(0, 4);
      const month = stringDate.slice(5, 7);
      const day = stringDate.slice(8);
      return new Date(year, month - 1, day, 0, 0, 0, 0);
    } else return "";
  };

  const convertDate = (date) => {
    return date ? date.toISOString().split("T")[0] : "";
  };

  const optionChangeHandler = (e) => {
    props.onChange(e.target.value);
  };

  const startDateChangeHandler = (value) => {
    props.onStartChange(value);
  };

  const endDateChangeHandler = (value) => {
    props.onEndChange(value);
  };

  const confirmHandler = () => {
    props.onConfirm();
  };

  const clearHandler = (e) => {
    props.onClear(e);
  };

  return (
    <div>
      <Menu
        className={classes.documentFilterMenu}
        anchorEl={props.anchorEl}
        keepMounted
        open={props.open}
        onClose={() => props.onClose()}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Box p={3} className={classes.filterMenuBox}>
          {props.options.length > 0 && (
            <Fragment>
              <Typography variant="h6" component="p">
                {props.optionLabel ?? "Estado"}
              </Typography>

              <Select
                className={classes.filterMenuSelect}
                value={props.filter}
                onChange={optionChangeHandler}
                displayEmpty
              >
                {props.options.map((opt, index) => (
                  <MenuItem
                    key={index}
                    value={typeof opt === "object" ? opt.value : opt}
                  >
                    {typeof opt === "object" ? opt.label : opt}
                  </MenuItem>
                ))}
              </Select>
            </Fragment>
          )}

          {props.showDatePicker && (
            <Box mb={1}>
              <Typography variant="h6" component="p">
                Fecha
              </Typography>
              <Box mb={1}>
                <Typography>Desde</Typography>
                <TextField
                  className={classes.datePicker}
                  value={props.startDate}
                  type="date"
                  onChange={(e) => {startDateChangeHandler(e.target.value)}}
                />
              </Box>
              <Box mb={3}>
                <Typography>Hasta</Typography>
                <TextField
                  className={classes.datePicker}
                  value={props.endDate}
                  type="date"
                  onChange={(e) => {endDateChangeHandler(e.target.value)}}
                />
              </Box>
            </Box>
          )}

          <Box className={classes.filterMenuButtonBox}>
            <BasicButton
              className={classes.filterMenuButton}
              onClick={clearHandler}
              variant="outlined"
            >
              Limpiar
            </BasicButton>
            <BasicButton
              className={classes.filterMenuButton}
              onClick={confirmHandler}
            >
              Filtrar
            </BasicButton>
          </Box>
        </Box>
      </Menu>
    </div>
  );
};

const TableFilter = (props) => {
  const {
    options = [],
    optionLabel,
    currentSearch = "",
    searchPlaceholder,
    showDatePicker,
    defaultStartDate,
    defaultEndDate,
    hideSearch,
  } = props;

  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [filterOption, setFilterOption] = useState(
    options.length > 0
      ? typeof options[0] === "object"
        ? options[0].value
        : options[0]
      : ""
  );
  const [startDate, setStartDate] = useState(
    defaultStartDate ? defaultStartDate : ""
  );
  const [endDate, setEndDate] = useState(defaultEndDate ? defaultEndDate : "");


  const classes = useStyles();

  const filterMenuHandler = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenFilterMenu((previousState) => !previousState);
  };
  const filterChangeHandler = (newValue) => {
    setFilterOption(newValue);
  };
  const filterConfirmHandler = () => {
    const formattedStartDate = startDate ? new Date(startDate).getTime() : "";
    const formattedEndDate = endDate
      ? new Date(endDate).setHours(23, 59, 59)
      : "";
    props.onFilterChange({
      status: filterOption,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    setOpenFilterMenu(false);
  };
  const filterClearHandler = () => {
    const clearedFilter =
      options.length > 0
        ? typeof options[0] === "object"
          ? options[0].value
          : options[0]
        : "";
    const clearedStartDate = defaultStartDate ? defaultStartDate : "";
    const clearedEndDate = defaultEndDate ? defaultEndDate : "";
    setFilterOption(clearedFilter);
    setStartDate(clearedStartDate);
    setEndDate(clearedEndDate);
    props.onFilterChange({
      status: clearedFilter,
      startDate: clearedStartDate,
      endDate: clearedEndDate,
    });
    setOpenFilterMenu(false);
  };
  const searchChangeHandler = (e) => {
    props.onSearchChange(e.target.value);
  };
  const startDateChangeHandler = (newDate) => {
    if(newDate.length > 10) return;
    setStartDate(newDate);
    if(moment(newDate).isAfter(moment(endDate)))
    {
      setEndDate(newDate);
    }
  };
  const endDateChangeHandler = (newDate) => {
    if(newDate.length > 10) return;
    setEndDate(newDate);
    if(newDate && newDate[0] !== "0" && moment(newDate).isBefore(moment(startDate)))
    {
      setStartDate(newDate);
    }
  };

  return (
    <div className={`${classes.root} ${props.className}`}>
      {(options.length > 0 || showDatePicker) && (
        <Fragment>
          <TuneIcon
            className={classes.tuneButton}
            fontSize="large"
            onClick={filterMenuHandler}
          />
          <TableFilterMenu
            anchorEl={anchorEl}
            open={openFilterMenu}
            filter={filterOption}
            optionLabel={optionLabel}
            options={options}
            startDate={startDate}
            endDate={endDate}
            showDatePicker={showDatePicker}
            onChange={filterChangeHandler}
            onStartChange={startDateChangeHandler}
            onEndChange={endDateChangeHandler}
            onConfirm={filterConfirmHandler}
            onClear={filterClearHandler}
            onClose={() => setOpenFilterMenu(false)}
          />
        </Fragment>
      )}
      {!hideSearch && (
        <Fragment>
          <Input
            placeholder={searchPlaceholder}
            className={classes.searchInput}
            value={currentSearch}
            onChange={searchChangeHandler}
          />
          <SearchRoundedIcon
            className={classes.searchIcon}
            fontSize="large"
          />
        </Fragment>
      )}
    </div>
  );
};

export default TableFilter;
