import React from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import image from "../../resources/images/logo-tagline.png";

const useStyles = makeStyles({
  logoStyle: {
    width: "100%",
    height: "5vw",
    objectFit: "cover"
  },
  disableRipple: {
    backgroundColor: "transparent !important",
    width: "15%",
  },
});

const Logo = (props) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.disableRipple} disableRipple>
      <img className={classes.logoStyle} src={image} alt="logo" />
    </IconButton>
  );
};

export default Logo;