import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import DocumentUploadBody from "../components/documentUploadBody";
import Navbar from "../components/navBar";
import CurrentProviderComponent from "../components/currentProviderComponent";

const index = 2;
class DocumentUploadPage extends React.Component {
  render() {
    return (
      <div className="mainBox">
        <Navbar index={index} />
        <CurrentProviderComponent />
        <DocumentUploadBody />
      </div>
    );
  }
}
export default DocumentUploadPage;