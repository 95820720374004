import React, { useEffect, useState } from 'react';
import {
  Typography, makeStyles, Box, Button,
  Card, CardActions, CardContent, Tooltip, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from 'react-router';
import ContactContext from '../contexts/contactContext';
import LoadingIcon from "./UI/LoadingIcon";
import BackButton from "./UI/BackButton";
import { convertDateHour } from "./Utils/formatDisplay";
import { useDispatch, useSelector } from 'react-redux';
import { setContactoRelacionado, setCurrentContact } from '../store/contacts/contactsSlice';
import BasicButton from './UI/BasicButton';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DocumentoContactoContext from '../contexts/DocumentoContactoContext';
import JSZip from 'jszip';

const useStyles = makeStyles({
  topBox: {
    margin: "2em 5em",
  },
  detailBox: {
    margin: "2em 0 3em 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    maxWidth: "70em",
  },
  tableContainer: {
    maxHeight: '190px',
    maxWidth: "70em",
    border: 'none',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  table: {
    width: "50%",
  },
  textCell: {
    padding: "0",
    border: "none",
    background: "none",
    fontSize: 16,
    fontWeight: 600,
    color: "gray",
  },
  text: {
    fontSize: 16,
    fontWeight: 600,
    color: "gray",
  },
  iconButton: {
    color: "#797a7a",
    "&:hover": {
      cursor: "pointer",
      color: "black",
    },
  },
  cardSize: {
    maxWidth: 500,
  },
  title: {
    color: "black",
    fontWeight: "bold",
    fontSize: 20,
  },
  mainText: {
    marginBottom: "1rem",
    color: "black"
  },
  cardBkPreg: {
    backgroundColor: "#c7e7c7"
  },
  cardBkRes: {
    backgroundColor: "#efefb7"
  },
  attachmentText: {
    color: "black",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: "darkblue"
    },
    marginBottom: "1rem",
  }
});

export default function ContactViewBody() {
  const dispatch = useDispatch()
  const currentContact = useSelector(state => state.contacts.currentContact)

  const history = useHistory();
  const classes = useStyles();
  const [viewContact, setViewContact] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const version = window.localStorage.getItem("version");

  useEffect(() => {
    GetContact();
  }, [currentContact]);

  const GetContact = async () => {
    const id_contacto = currentContact;
    const contact = await ContactContext.fetchContactById(id_contacto);
    setViewContact(contact);
    setIsLoading(false);
  }

  const goToContactPage = () => {
    dispatch(setCurrentContact(null))
    history.push("/contact?v=" + version);
  }

  const newContact = () => {
    dispatch(setCurrentContact(null))
    dispatch(setContactoRelacionado(viewContact))
    history.push("/contact/create?v=" + version);
  }

  function relatedContact(id) {
    dispatch(setCurrentContact(id))
    history.push("/contact/contactView?v=" + version);
  }

  const descargarAdjuntos = async () => {
    const response = await DocumentoContactoContext.fetchDocumentsNoticesById(viewContact.id);
    if (response && response.length > 0) {
      let zip = new JSZip();
      for (const doc of response) {
        const formattedName = doc.nombreArchivo.endsWith(".pdf")
          ? doc.nombreArchivo.slice(0, -4)
          : doc.nombreArchivo;
        zip.file(
          `${formattedName}_${doc.idContacto}.pdf`,
          doc.imagen,
          { base64: true }
        );
      }
      const zipFile = await zip.generateAsync({ type: "base64" });
      const linkSource = `data:application/zip;base64,${zipFile}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "Documentacion_Contacto.zip";
      downloadLink.click();
    }
  }

  return (
    <Box m={6}>
      <BackButton onClick={goToContactPage} />
      {viewContact &&
        <Box className={classes.topBox}>
          <Typography variant="h5" component="h1">
            Contacto realizado:
          </Typography>
          <Box className={classes.detailBox}>
            <Box>
              <Typography className={classes.text}>
                <strong>Area consultada</strong>
              </Typography>
              <Typography className={classes.text}>
                {viewContact.contactoMotivo?.area?.denominacion}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.text}>
                <strong>Estado</strong>
              </Typography>
              <Typography className={classes.text}>
                {viewContact.estado?.descripcion}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.text}>
                <strong>Derivado a</strong>
              </Typography>
              <Typography className={classes.text}>
                {viewContact.areaDerivada?.denominacion ? viewContact.areaDerivada?.denominacion : "-"}
              </Typography>
            </Box>

            <Box>
              <Typography className={classes.text}>
                <strong>Fecha de creación</strong>
              </Typography>
              <Typography className={classes.text}>
                {viewContact.fechaCreacion ? convertDateHour(viewContact.fechaCreacion) : "-"}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.text}>
                <strong>Fecha de cierre</strong>
              </Typography>
              <Typography className={classes.text}>
                {viewContact.fechaCierre ? convertDateHour(viewContact.fechaCierre) : "-"}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.text}>
                <strong>Relacionado con otro Contacto</strong>
              </Typography>
              {viewContact.contactoRelacionado ?
                <Typography className={classes.text}>
                  Sí - {
                    <Tooltip title="Ver Contacto Relacionado">
                      <VisibilityIcon
                        onClick={() => relatedContact(viewContact.contactoRelacionado.id)}
                        className={classes.iconButton}
                        mr={2}
                      />
                    </Tooltip>}
                </Typography>
                :
                <Typography className={classes.text}>
                  No
                </Typography>
              }
            </Box>
          </Box>
          {viewContact?.contactoNotaPedidoRecepcion?.length > 0 &&
            <Box margin="2em 0 3em 0">
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.textCell}><strong>Nota de Pedido</strong></TableCell>
                      <TableCell align="center" className={classes.textCell}><strong>Recepción</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {viewContact?.contactoNotaPedidoRecepcion.map((npr, idx) => (
                      <TableRow key={idx}>
                        <TableCell align="center" className={classes.textCell}>{npr.notaPedido?.numero}</TableCell>
                        <TableCell align="center" className={classes.textCell}>{npr.recepcion?.numero ? npr.recepcion?.numero : "-"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          }
        </Box>
      }
      {viewContact &&
        <Box display="flex" justifyContent="flex-start" >
          <Card className={classes.cardSize}>
            <CardContent className={classes.cardBkPreg}>
              <Typography className={classes.title} gutterBottom>
                Consulta
              </Typography>
              <Typography variant="body2" component="p" className={classes.mainText}>
                {viewContact.pregunta}
              </Typography>
              <Typography variant="body2" component="p" color="textSecondary">
                Solicitud hecha por: {viewContact.usuarioAsignacionPregunta?.users?.mail}
              </Typography>
              <Typography variant="body2" component="p" color="textSecondary">
                Proveedor: {viewContact?.usuarioAsignacionPregunta?.providers?.razon_social}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      }
      {(viewContact && viewContact.estado.descripcion === 'Cerrado') &&
        <Box display="flex" justifyContent="flex-end" >
          <Card className={classes.cardSize}>
            <CardContent className={classes.cardBkRes}>
              <Typography className={classes.title} gutterBottom>
                Respuesta
              </Typography>
              <Typography variant="body2" component="p" className={classes.mainText}>
                {viewContact.respuesta}
              </Typography>
              {viewContact.documentosContacto &&
                <Box onClick={descargarAdjuntos}>
                  <Typography variant="body2" component="p" className={classes.attachmentText}>
                    Archivos adjuntos
                    <DescriptionOutlinedIcon/>
                  </Typography>
                </Box>
              }
              <Typography variant="body2" component="p" color="textSecondary">
                Solicitud respondida por: {viewContact?.usuarioRespuesta?.nombre ? viewContact?.usuarioRespuesta?.nombre : viewContact?.usuarioRespuesta?.mail}
              </Typography>
              {!viewContact.tieneRelacion &&
                <CardActions>
                  <BasicButton size="small" onClick={newContact}>Realizar nueva consulta</BasicButton>
                </CardActions>
              }
            </CardContent>
          </Card>
        </Box>
      }
      {isLoading && <LoadingIcon />}
    </Box>
  )
}
