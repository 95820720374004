import api from "../services/api";
import { AxiosResponse } from "axios";

export class DocumentsNoticesContext {
  static async createDocumentNotice(document: any) {
    const response = await api.post(
      `/documentsNotices`, document);
    return response?.data
  }

  static async fetchDocumentsNoticesById(noticeId: number) {
    try {
      const response = await api.get<Response, AxiosResponse<Response>>(
        `/documentsNotices/getById/${noticeId}`
      );
      if (response !== undefined && response.data !== undefined) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default DocumentsNoticesContext;