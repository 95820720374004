import { AxiosResponse } from "axios";
import api from "../services/api";

export class ReceiptTypeContext {
  static async GetAll() {
    const response: any = await api.get<Response, AxiosResponse<Response>>(`/receiptType/getReceiptTypes`);
    if (response !== undefined && response.data !== undefined) {
      return response.data;
    } else {
      return null;
    }
  }
}