import { createSlice } from "@reduxjs/toolkit";

export const documentsSlice = createSlice({
  name: "documents",
  initialState: {
    selectedQr: {},
    qrForm: {},
    selectedNotaPedido: {},
    notaPedidoList: [],
    notaPedidoForContact: {},
    selectedRecepciones: [],
    checkSinNotaPedido: false,
    checkAnticipo: false,
    listRelationsIds: [],
    tolerancia: 0,
    receptionsBalanced: false,
    idDocument: null,
  },
  reducers: {
    setSelectedDocumentId: (state, action) => {
      state.idDocument = action.payload;
    },
    setSelectedQr: (state, action) => {
      state.selectedQr = action.payload;
    },
    setQrForm: (state, action) => {
      state.qrForm = action.payload;
    },
    setSelectedNotaPedido: (state, action) => {
      state.selectedNotaPedido = action.payload;
    },
    setNotaPedidoForContact: (state, action) => {
      state.notaPedidoForContact = action.payload;
    },
    setNotaPedidoList: (state, action) => {
      state.notaPedidoList = action.payload;
    },
    setSelectedRecepciones: (state, action) => {
      state.selectedRecepciones = action.payload;
    },
    setCheckAnticipo: (state, action) => {
      state.checkAnticipo = action.payload;
    },
    setCheckSinNotaPedido: (state, action) => {
      state.checkSinNotaPedido = action.payload;
    },
    setListRelationsIds: (state, action) => {
      state.listRelationsIds = action.payload;
    },
    setTolerancia: (state, action) => {
      state.tolerancia = action.payload;
    },
    setReceptionsBalanced: (state, action) => {
      state.receptionsBalanced = action.payload;
    },
  },
});

export const {
  setSelectedDocumentId,
  setSelectedQr,
  setQrForm,
  setSelectedNotaPedido,
  setNotaPedidoList,
  setSelectedRecepciones,
  setNotaPedidoForContact,
  setCheckSinNotaPedido,
  setCheckAnticipo,
  setListRelationsIds,
  setTolerancia,
  setReceptionsBalanced,
} = documentsSlice.actions;
export default documentsSlice.reducer;
