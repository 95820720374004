import { AxiosResponse } from "axios";
import api from "../services/api";

export class ContactoMotivoContext {

  static async fetchContactReasons() {
    const response: any = await api.get<Response, AxiosResponse<Response>>(
      `/contactoMotivo`
    );
    if (response !== undefined && response.data !== undefined) {
      return response.data;
    } else {
      return null;
    }
  }
}