import api from "../services/api";
import { AxiosResponse } from "axios";

export class documentoElectronicoQrContext {

    static async fetchDigDocsQrd(providerId: number) {
        const response: any = await api.get<Response, AxiosResponse<Response>>(
            `/documentoElectronicoQr/${providerId}`);
        if (response && response.data) {
            return response.data;
        } else {
            return null;
        }
    }

    static async validateQrDocument(idQr: number) {
        const response = await api.post(`/documentoElectronicoQr/${idQr}`);
        if (response && response?.data && response?.data?.status) {
            return response?.data;
        } else {
            console.log("/documentoElectronicoQr/",response?.data?.message);    
            return null;
        }
    }

    static async delete(idQr: number, idDocument: number) {
        const response = await api.delete(`/documentoElectronicoQr`, {params:{ idQr, idDocument }});
        if (response && response.data.status) {
            return response.data;
        } else {
            console.log("/documentoElectronicoQr/delete",response.data.message);    
            return null;
        }
    }

    static async updateQr(Qr: any, Id_documento_electronico: number){
        const response = await api.put(`/documentoElectronicoQr`,{Qr,Id_documento_electronico});
        if(response && response.data) {
            return response.data;
        } else {
            console.log(`/documentoElectronicoQr/updateQr`, response.data);
            return null;
        }
    }

}

export default documentoElectronicoQrContext;