import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import DocumentoAfipContext from "../../contexts/documentoAfipContext";
import BasicButton from "../UI/BasicButton";
import LoadingIcon from "../UI/LoadingIcon";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { parse } from "qs";

const useStyles = makeStyles((theme) => ({
  scroll: {
    overflow: "auto",
    maxHeight: "50vh",
  },
  table: {
    minWidth: 650,
  },
  icon: {
    cursor: "pointer",
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  colorError: {
    color: theme.palette.error.main,
  },
  communicationAdvice: {
    fontSize: "0.75rem",
  },
}));

const DiferenciaForm = ({
  qrData,
  balanceModal,
  adjustmentsBalance,
  listRelations,
  setListRelations,
  handleSaveChanges,
  handleCloseDiferenciaForm,
  diffOk,
  setDiffOk,
  tolerancia,
  adjustments,
  setAdjustments,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdj, setIsLoadingAdj] = useState(false);
  const [adjustedAmountNoTax, setAdjustedAmountNoTax] = useState(
    parseFloat(qrData?.importeTotalPesosNoTax) || 0
  );
  const [montoTotal, setMontoTotal] = useState(0);

  const fetchAdjustments = async () => {
    if (balanceModal) {
      setIsLoading(true);
      let data = await DocumentoAfipContext.getAdjustmentByProvider();
      if (data) {
        data.forEach((element) => {
          element.agregado = false;
        });
        setAdjustments(data);
      }
      setIsLoading(false);
    }
  };

  const handleAdjustmentClick = (e) => {
    setIsLoadingAdj(true);
    let monto = 0;
    // Nota de credito = 1 , Nota de debito = 2
    if (e.tipoComprobante?.comprobante?.id === 1) {
      if (!e.agregado) {
        setAdjustedAmountNoTax(adjustedAmountNoTax - e.importeTotalPesosNoTax);
        monto = adjustedAmountNoTax - e.importeTotalPesosNoTax;
        setListRelations([...listRelations, e.id]);
      }
      if (e.agregado) {
        setAdjustedAmountNoTax(adjustedAmountNoTax + e.importeTotalPesosNoTax);
        monto = adjustedAmountNoTax + e.importeTotalPesosNoTax;
        let updatedList = listRelations.filter((x) => x !== e.id);
        setListRelations(updatedList);
      }
    }
    if (e.tipoComprobante?.comprobante?.id === 2) {
      if (!e.agregado) {
        setAdjustedAmountNoTax(adjustedAmountNoTax + e.importeTotalPesosNoTax);
        monto = adjustedAmountNoTax + e.importeTotalPesosNoTax;
        setListRelations([...listRelations, e.id]);
      }
      if (e.agregado) {
        setAdjustedAmountNoTax(adjustedAmountNoTax - e.importeTotalPesosNoTax);
        monto = adjustedAmountNoTax - e.importeTotalPesosNoTax;
        let updatedList = listRelations.filter((x) => x !== e.id);
        setListRelations(updatedList);
      }
    }
    setMontoTotal(monto);
    //Le cambiamos el valor al estado.
    let newAdjustment = adjustments?.map((adj) => {
      if (adj.id === e.id) adj.agregado = !adj.agregado;
      return adj;
    });
    setAdjustments(newAdjustment);
    setIsLoadingAdj(false);
    calcularDiferencia();
  };

  const calcularDiferencia = (monto = adjustedAmountNoTax) => {
    if (balanceModal) {
      // let dif = difference(adjustmentsBalance, monto);
      let dif = parseFloat(adjustmentsBalance) - parseFloat(monto);
      if (
        (parseFloat(adjustmentsBalance) > parseFloat(monto) &&
          dif <= tolerancia) ||
        (parseFloat(adjustmentsBalance) < parseFloat(monto) &&
          dif >= -tolerancia) ||
        dif === 0
      ) {
        setDiffOk(true);
      } else {
        setDiffOk(false);
      }
    }
  };

  const saveChanges = () => {
    handleSaveChanges();
    setAdjustedAmountNoTax(parseFloat(qrData?.importeTotalPesosNoTax) || 0);
  };

  const difference = (a, b) => {
    let dif = parseFloat(a) - parseFloat(b);
    return dif;
  };

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  useEffect(() => {
    fetchAdjustments();
    if (balanceModal) {
      console.log({
        adjustedAmountNoTax,
        adjustmentsBalance,
      });
      calcularDiferencia();
    }
  }, [balanceModal, montoTotal]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Typography variant="h5">
          Hay diferencias con los valores totales de Recepciones para revisar.
        </Typography>
        <Box
          style={{ width: "100%", gap: 15 }}
          display={"flex"}
          flexDirection={"column"}
        >
          <TableContainer className={classes.scroll}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Tipo de Comprobante</TableCell>
                  <TableCell align="center">Numero de Comprobante</TableCell>
                  <TableCell align="center">
                    Importe total S/ Impuestos
                  </TableCell>
                  <TableCell align="center">Accion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && adjustments?.length > 0 ? (
                  adjustments?.map((adjustment) => (
                    <TableRow key={adjustment.id}>
                      <TableCell align="center">
                        {adjustment?.tipoComprobante?.descripcion}
                      </TableCell>
                      <TableCell align="center">{`${zeroPad(
                        adjustment?.puntoVenta,
                        4
                      )}-${zeroPad(
                        adjustment?.numeroComprobante,
                        8
                      )}`}</TableCell>
                      <TableCell align="center">
                        $ {adjustment?.importeTotalPesosNoTax?.toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        {isLoadingAdj && <LoadingIcon size={20} />}
                        {!isLoadingAdj && adjustment.agregado ? (
                          <RemoveCircleIcon
                            className={classes.icon}
                            onClick={() => handleAdjustmentClick(adjustment)}
                          />
                        ) : (
                          <AddCircleIcon
                            className={classes.icon}
                            onClick={() => handleAdjustmentClick(adjustment)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      No hay comprobantes disponibles.
                    </TableCell>
                  </TableRow>
                )}
                {isLoading && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      {" "}
                      <LoadingIcon />{" "}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography variant="h6">
          Total sin impuestos:{" "}
          <b>$ {parseFloat(adjustedAmountNoTax).toFixed(2)}</b>
        </Typography>
        <Typography variant="h6">
          Valorización total: <b>$ {adjustmentsBalance?.toFixed(2)}</b>
        </Typography>
        <Typography
          variant="h6"
          className={diffOk ? classes.colorSuccess : classes.colorError}
        >
          Diferencia:{" "}
          <b>
            $ {difference(adjustmentsBalance, adjustedAmountNoTax)?.toFixed(2)}
          </b>
        </Typography>
        {(adjustmentsBalance < 0 ||
          difference(adjustmentsBalance, adjustedAmountNoTax)?.toFixed(2) <
            -tolerancia ||
          diffOk === false) && (
          <Typography variant="h6" className={classes.communicationAdvice}>
            Si se mantiene la diferencia, por favor comuníquese con su comprador
          </Typography>
        )}
      </Box>
      <Box mt={1} display="flex" style={{ gap: 10 }}>
        {adjustmentsBalance > 0 &&
          difference(adjustmentsBalance, adjustedAmountNoTax)?.toFixed(2) >
            -tolerancia && (
            <BasicButton
              disabled={!diffOk}
              color="primary"
              variant="contained"
              onClick={() => saveChanges()}
            >
              Continuar
            </BasicButton>
          )}
        <BasicButton
          disabled={!diffOk}
          color="primary"
          variant="contained"
          onClick={() => saveChanges()}
        >
          Guardar Ajustes
        </BasicButton>
        <BasicButton onClick={() => handleCloseDiferenciaForm()}>
          Cerrar
        </BasicButton>
      </Box>
    </>
  );
};

export default DiferenciaForm;
