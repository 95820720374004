import { AxiosResponse } from "axios";
import api from "../services/api";

export class NotaPedidoContext {
  static async getNotaPedidoById() {
    let providerId: any = JSON.parse(window.localStorage.getItem("provider") || "").id_proveedor;
    const response: any = await api.get<Response, AxiosResponse<Response>>(`/notaPedido/${providerId}`);
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  }
}

export default NotaPedidoContext;