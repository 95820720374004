import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles, Typography, Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Tooltip, Paper,
} from "@material-ui/core";
import { GrDocumentDownload } from "react-icons/gr";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import Visibility from "@material-ui/icons/Visibility";
import StyledPagination from "../UI/StyledPagination";
import LoadingIcon from "../UI/LoadingIcon";
import BasicButton from "../UI/BasicButton";
import BasicModal from "../UI/BasicModal";
import DigitalDocumentsContext from "../../contexts/digitalDocumentsContexts";
import moment from "moment";

const useStyles = makeStyles({
  actionBar: {
    display: "flex",
    flexDirection: "row",
  },
  actionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "2em",
  },
  actionButton: {
    cursor: "pointer",
  },
  actionSearch: {
    flexGrow: "1",
    justifyContent: "flex-end",
  },
  searchInput: {
    maxWidth: "16em",
  },
  filterMenuBox: {
    display: "flex",
    flexDirection: "column",
  },
  filterMenuButtonBox: {
    display: "flex",
    flexDirection: "row",
  },
  filterMenuSelect: {
    width: "12em",
    marginBottom: "1em",
  },
  filterMenuButton: {
    marginRight: "0.5em",
  },
  tableHeaderText: {
    color: "gray",
    fontSize: 16,
    fontWeight: "bold",
  },
  tableCellText: {
    color: "gray",
    fontSize: 16,
    fontWeight: 600,
  },
  pages: {
    display: "flex",
    justifyContent: "center",
    margin: "3em auto",
  },
  pagesNumbers: {
    fontWeight: "bold",
  },
  modalRec: {
    minWidth: "35em",
    minHeight: "15em",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
  },
  modalRel: {
    minWidth: "35em",
    minHeight: "15em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  documentSearchResultIcon: {
    cursor: 'pointer',
    display: 'inline-block',
    width: '20px',
    height: '20px',
  },
  buttonDownload: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalBottomBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: "1em 0"
  },
  scroller: {
    maxHeight: "170px",
    overflowY: "auto",
  }
});

const columns = [
  {
    id: "Fecha_carga",
    label: "Fecha de carga",
    minWidth: 150,
    align: "left",
  },
  {
    id: "Estado",
    label: "Estado",
    minWidth: 150,
    align: "left",
  },
  {
    id: "nombre_archivo",
    label: "Nombre del archivo",
    minWidth: 150,
    align: "left",
  },
  {
    id: "Cargado_por",
    label: "Cargado por",
    minWidth: 150,
    align: "left",
  },
  {
    id: "tiene_relacion",
    label: "Tiene Relación",
    minWidth: 150,
    align: "left",
  },
  {
    id: "Descarga",
    label: "Descarga",
    minWidth: 150,
    align: "right",
  },
];

const TAB_STORAGE = "tab_documents";

const initialValues = {
  type: 0,
  doc: {}
};

const MyDigitalDocuments = (props) => {
  const [digDocs, setDigDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [docDetail, setDocDetail] = useState(initialValues);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (type, row) => {
    setDocDetail({ type: type, doc: row });
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setDocDetail(initialValues);
    setOpenModal(false);
  }

  const rowsPerPage = 7;
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  const classes = useStyles();
  const history = useHistory();
  const version = window.localStorage.getItem("version");
  const uploadDocumentUrl = "/documents/upload?v=" + version;
  const searchDocumentUrl = "/documents/search?v=" + version;

  const fetchData = useCallback(async (pageNumber) => {
    setIsLoading(true);
    const docsData = await DigitalDocumentsContext.fetchDocumentsViewById(
      pageNumber || 1,
      rowsPerPage
    );

    if (docsData && docsData.data) {
      const docPageCount = Math.ceil(docsData.totalRecords / rowsPerPage);
      setPageCount(docPageCount);

      setDigDocs(docsData.data);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData(page);
  }, [fetchData, page]);

  const pageChangeHandler = (e, newPage) => {
    setPage(newPage);
  };

  const documentUploadRedirect = () => {
    sessionStorage.setItem(TAB_STORAGE, 1);
    history.push(uploadDocumentUrl);
  };
  const documentSearchRedirect = () => {
    history.push(searchDocumentUrl);
  };

  const downloadDocumentHandler = (id) => {
    props.onDownload(id);
  };

  return (
    <div>
      <Box mt={1} mb={2} className={classes.actionBar}>
        <div
          className={`${classes.actionItem} ${classes.actionButton}`}
          onClick={documentUploadRedirect}
        >
          <ControlPointIcon fontSize="large" />
          <Typography variant="body2">Cargar</Typography>
        </div>
        <div
          className={`${classes.actionItem} ${classes.actionButton}`}
          onClick={documentSearchRedirect}
        >
          <SettingsEthernetIcon fontSize="large" />
          <Typography variant="body2">Consultar</Typography>
        </div>
      </Box>

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  className={classes.tableHeaderText}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {digDocs &&
              digDocs.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell className={classes.tableCellText}>
                    {`${moment(row.fecha_carga, 'MM/DD/YYYY').format('DD-MM-YYYY')} - ${row.hora_carga.substring(0, 5)} Hs`}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    <Box display="flex">
                      {row.estado}
                      {row.descripcion_rechazo &&
                        <Tooltip title="Ver motivo de rechazo">
                          <Visibility className={classes.documentSearchResultIcon} onClick={() => handleOpenModal(1, row)} />
                        </Tooltip>
                      }
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.nombre_archivo}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.usuario}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    {row.documentoAfipRelacion?.length > 0 ?
                      <Tooltip title="Ver documentos relacionados">
                        <AspectRatioIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenModal(2, row)}
                        />
                      </Tooltip>
                      : "No"}
                  </TableCell>
                  <TableCell className={classes.tableCellText}>
                    <Box display="flex" justifyContent="start">
                      <Tooltip title="Descargar Documento" placement="top" arrow>
                        <Box className={classes.buttonDownload}>
                          <GrDocumentDownload
                            className={classes.documentSearchResultIcon}
                            cursor='pointer'
                            onClick={() => downloadDocumentHandler(row.id_documento_electronico)}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            {digDocs.length === 0 && !isLoading && (
              <TableRow hover role="checkbox">
                <TableCell colSpan={columns.length}>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ textAlign: "center" }}
                  >
                    No existen documentos electrónicos para este proveedor.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <LoadingIcon />}

      {digDocs.length > 0 && (
        <StyledPagination
          page={page}
          pageCount={pageCount}
          onChange={pageChangeHandler}
        />
      )}

      <BasicModal
        className={docDetail?.type === 1 ? classes.modalRec : classes.modalRel}
        open={openModal}
        onClose={handleCloseModal}
      >
        <Typography variant="h5">{docDetail?.type === 1 ? "Documento Rechazado" : "Documento/s relacionado/s"}</Typography>
        {docDetail?.type === 1 ?
          <>
            <Box display="flex">
              <Typography><strong>Motivo de rechazo:&nbsp;</strong></Typography>
              <Typography>{docDetail?.doc?.descripcion_rechazo}</Typography>
            </Box>
            <Box display="flex">
              <Typography><strong>Observaciones:&nbsp;</strong></Typography>
              <Typography>{docDetail?.doc?.observaciones}</Typography>
            </Box>
          </>
          :
          <div className={classes.modalBottomBox}>
            <TableContainer component={Paper} className={classes.scroller}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeaderText} align="center">Fecha de carga</TableCell>
                    <TableCell className={classes.tableHeaderText} align="center">Nombre del archivo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {docDetail?.doc?.documentoAfipRelacion?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCellText} scope="row" align="center">{`${moment(row.relacion.digitalDocument.fecha_carga, 'MM/DD/YYYY').format('DD-MM-YYYY')} - ${row.relacion.digitalDocument.hora_carga.substring(0, 5)} Hs`}</TableCell>
                      <TableCell className={classes.tableCellText} align="left">{row.relacion.digitalDocument.nombre_archivo}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }
        <Box style={{ margin: "0 auto" }}>
          <BasicButton onClick={handleCloseModal}>Cerrar</BasicButton>
        </Box>
      </BasicModal>
    </div>
  );
};

export default MyDigitalDocuments;
