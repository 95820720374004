import React from "react";
import "../resources/styles/globalStyles.css";
import "bootstrap/dist/css/bootstrap.css";
import AfipUploadBody from "../components/afipUploadBody";
import Navbar from "../components/navBar";

export default function AfipUploadTaxRates({ caminoCompleto }) {
  return (
    <div className="mainBox">
      <Navbar />
      <AfipUploadBody caminoCompleto={caminoCompleto} />
    </div>
  )
}
