import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "../components/navBar";
import ContactFormBodyNew from "../components/contactFormBodyNew";

const index = 3;

class ContactCreatePage extends React.Component {
  render() {
    return (
      <div className="mainBox">
        <Navbar index={index} />
        <ContactFormBodyNew modal={false} />
      </div>
    );
  }
}
export default ContactCreatePage;