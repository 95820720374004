import { AxiosResponse } from "axios";
import api from "../services/api";
import QueryString from "qs";

export class DocumentosImpuestoContext {
  static async getDocumentosImpuestoActive() {
    const response: any = await api.get<Response, AxiosResponse<Response>>(
      `/documentosImpuesto/active`);
    if (response !== undefined && response.data[0] !== undefined) {
      return response.data;
    } else {
      return null;
    }
  }

  static async downloadDocuments(documentsIds: any) {
    const response = await api.get(`/documentosImpuesto/download`,{
    params:{ documentsIds },
    paramsSerializer: params => {return QueryString.stringify(params, {arrayFormat: 'repeat'})}});
    if (response) return response.data;
    else return null;
  }

  static async hasNewTaxes() {
    let userId: any = JSON.parse(window.localStorage.getItem("user") ?? "").id_usuario;;
    let providerId = JSON.parse(window.localStorage.getItem("provider") ?? "").id_proveedor;
    const response = await api.get(`/documentosImpuesto/newTaxes`, {params:{userId, providerId}});
    if (response) return response.data;
    else return null;
  }
}

export default DocumentosImpuestoContext;