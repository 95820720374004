import { makeStyles, Box, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    margin: "2em",
  },
});

const LoadingIcon = ({ className, size = 60 }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.loadingBox} ${className}`}>
      <CircularProgress size={size} thickness={8} />
    </Box>
  );
};

export default LoadingIcon;
