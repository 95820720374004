import moment from "moment";

const currencyFormat = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "ARS",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const formatCurrencyDisplay = (amount) => currencyFormat.format(amount);

export const formatCuitDisplay = (cuit) => {
  const stringCuit = cuit.toString();
  return (
    stringCuit.slice(0, 2) +
    "-" +
    stringCuit.slice(2, 10) +
    "-" +
    stringCuit.slice(-1)
  );
};

export const formatDateObjectDisplay = (date, separator = "") => {
  const dateString = date
    .toLocaleString("es-AR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split(" ")[0];
  return separator ? dateString.split("/").join(separator) : dateString;
};

export const formatDateObjectToUS = (date) => {
  const dateString = date
    .toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split(" ")[0];
  return dateString;
};

export const convertDateToAR = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const convertDateHour = (dateString) => {
  const day = dateString.slice(8, 10);
  const month = dateString.slice(5, 7);
  const year = dateString.slice(0, 4);
  const hourMinute = dateString.slice(11, 16);
  let date = `${day}-${month}-${year} - ${hourMinute}hs`;
  return date;
};

export const convertDate = (dateString) => {
  let convertDate = dateString.split("T")[0];
  let day = convertDate.slice(8, 10);
  let month = convertDate.slice(5, 7);
  let year = convertDate.slice(0, 4);
  let date = `${day}-${month}-${year}`;
  return date;
}

export const convertDateHourAfip = (dateString) => {
  const day = dateString.slice(6, 8);
  const month = dateString.slice(4, 6);
  const year = dateString.slice(0, 4);
  const hour = dateString.slice(8, 10)
  const minute = dateString.slice(10, 12)
  let date = `${day}/${month}/${year} - ${hour}:${minute}hs`;
  return date;
};