import React, { useEffect, useState } from "react";
import DigitalDocumentsContext from "../contexts/digitalDocumentsContexts";
import VisitsContext from "../contexts/visitsContext";
import StyledTabs from "./UI/StyledTabs";
import MyDocuments from "./Documents/MyDocuments";
import MyDigitalDocuments from "./Documents/MyDigitalDocuments";

const TAB_STORAGE = "tab_documents";

export default function DocumentBody() {
  const [initialTab, setInitialTab] = useState(0);

  const downloadDocument = async (id) => {
    const response = await DigitalDocumentsContext.downloadDocuments([id]);
    if (response && response.length === 1) {
      const file = response[0];
      const linkSource = `data:application/pdf;base64,${file.imagen}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = file.nombre_archivo;
      downloadLink.click();
    }
  };

  useEffect(() => {
    VisitsContext.createVisit(5).then((e) => {});
    const lastTabSelected = sessionStorage.getItem(TAB_STORAGE);
    if (lastTabSelected) {
      setInitialTab(+lastTabSelected);
      sessionStorage.removeItem(TAB_STORAGE);
    }
  }, []);

  const tabsContent = [
    {
      label: "Mis Documentos.",
      content: MyDocuments,
    },
    {
      label: "Documentos electrónicos.",
      content: () => <MyDigitalDocuments onDownload={downloadDocument} />,
    },
  ];

  return (
    <div>
      <StyledTabs tabConfig={tabsContent} currentTab={initialTab} />
    </div>
  );
}
