import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PortalHomeProvidersPage from '../pages/portalHomeProvidersPage';
import ProviderSelectPage from '../pages/providerSelectPage';

import NotFoundPage from '../pages/notFoundPage';
import Auth from '../auth/auth';
import LoadingLogo from '../components/UI/LoadingLogo';

import ContactPage from '../pages/contactPage';
import NoticesPage from '../pages/noticesPage';
import NoticesCommonViewPage from '../pages/noticesCommonViewPage';
import DocumentPage from '../pages/documentPage';
import PaymentsReportPage from '../pages/paymentsReportPage';
import TaxPage from '../pages/taxPage';
import DocumentUploadPage from '../pages/documentUploadPage';
import AfipUploadTaxRates from '../pages/afipUploadTaxRatesPage';
import AfipFormPage from '../pages/afipFormPage';
import ContactViewPage from '../pages/contactViewPage';
import ContactCreatePage from '../pages/contactCreatePage';

const NoticesViewPage = React.lazy(() => import('../pages/noticesViewPage'));
const NoticesViewPageExt = React.lazy(() => import('../pages/noticesViewPageExt'));
const DocumentReportPage = React.lazy(() => import('../pages/documentReportPage'));
const DocumentSearchPage = React.lazy(() => import('../pages/documentSearchPage'));
const PaymentsReportDetailsPage = React.lazy(() => import('../pages/paymentsReportDetailsPage'));
const PaymentsReportNoDetailsPage = React.lazy(() => import('../pages/paymentsReportNoDetailsPage'));
const PaymentsFormsPage = React.lazy(() => import('../pages/paymentsFormsPage'));
const termsAndConditionsPage = React.lazy(() => import('../pages/termsAndConditionsPage'));
const UnassignedUserPage = React.lazy(() => import('../pages/unassignedUserPage'));

export default function Router() {
  const perms = window.localStorage.getItem("tknPms");
  const mantenimiento = JSON.parse(window.localStorage.getItem("mantenimiento"));
  const user = JSON.parse(window.localStorage.getItem("user"));

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingLogo />}>
        <Switch>
          <Route exact path='/' component={Auth} />
          <Route exact path='/auth' component={Auth} />
          <Route exact path='/portal/providerSelect' component={ProviderSelectPage} />
          <Route exact path='/portal/providers' component={PortalHomeProvidersPage} />
          {user?.acepto_Terminos === false && <Route exact path='/termsAndConditions' component={termsAndConditionsPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorContacto")) && <Route exact path='/contact' component={ContactPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorContacto")) && <Route exact path='/contact/contactView' component={ContactViewPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorContacto")) && <Route exact path='/contact/create' component={ContactCreatePage} />}

          {(mantenimiento?.estado === false && perms?.includes("ProveedorAviso")) && <Route exact path='/notices' component={NoticesPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorAviso")) && <Route exact path='/notices/view' component={NoticesViewPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorAviso")) && <Route exact path='/notices/viewExt' component={NoticesViewPageExt} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorAviso")) && <Route exact path='/notices/common-view' component={NoticesCommonViewPage} />}

          {(mantenimiento?.estado === false && perms?.includes("ProveedorDocumento")) && <Route exact path='/documents' component={DocumentPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorDocumento")) && <Route exact path='/documents/report' component={DocumentReportPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorDocumento")) && <Route exact path='/documents/search' component={DocumentSearchPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorDocumento")) && <Route exact path='/documents/document-validation-pie-impositivo' component={() => <AfipUploadTaxRates caminoCompleto={false} />} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorDocumento")) && <Route exact path='/documents/document-validation-nota-pedido' component={() => <AfipUploadTaxRates caminoCompleto={true} />} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorDocumento")) && <Route exact path='/documents/upload' component={DocumentUploadPage} />}

          {(mantenimiento?.estado === false && perms?.includes("ProveedorPago")) && <Route exact path='/payments' component={PaymentsReportPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorPago")) && <Route exact path='/payments/report/detail' component={PaymentsReportDetailsPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorPago")) && <Route exact path='/payments/report/nodetail' component={PaymentsReportNoDetailsPage} />}
          {(mantenimiento?.estado === false && perms?.includes("ProveedorPago")) && <Route exact path='/payments/forms' component={PaymentsFormsPage} />}

          {(mantenimiento?.estado === false && perms?.includes("ProveedorImpuesto")) && <Route exact path='/taxes' component={TaxPage} />}
          <Route exact path='/afip' component={AfipFormPage} />
          <Route exact path='/unassigneduser' component={UnassignedUserPage} />
          <Route path='*' component={NotFoundPage} /> {/* Pagina 404 - Esta ruta siempre tiene que estar al final */}
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}