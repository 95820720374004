import { Box, Typography } from "@material-ui/core";
import ContactFormBodyNew from "../contactFormBodyNew";

const ContactoForm = ({ notaPedidoForContact, selectedRecepciones}) => {
  return ( 
    <Box>
      <Typography variant="h4" style={{ marginBottom: "1rem" }} >Formulario de contacto</Typography>
      <Box>
        {notaPedidoForContact && <Typography variant="body2" ><strong>Nota de pedido asociada:</strong> {notaPedidoForContact.numero}</Typography>}
        {notaPedidoForContact && <Typography variant="body2" ><strong>Usuario Comprador:</strong> {notaPedidoForContact.usuarioComprador}</Typography>}
        <Box>
          <Typography variant="body2" gutterBottom style={{ fontWeight: "bold" }}>Recepciones asociadas: </Typography>
          {selectedRecepciones.length > 0 ?
            selectedRecepciones.map((rec, index) => (
              <Box>
                <Typography key={index} variant="body2" gutterBottom ><strong>Numero:</strong> {rec.numeroRecepcion}</Typography>
                <Typography key={rec.usuarioRecepcion} variant="body2" gutterBottom ><strong>Usuario Recepción:</strong> {rec.usuarioRecepcion}</Typography>
              </Box>

            ))
            :
            <Typography variant="body2" gutterBottom style={{ fontWeight: "bold" }}>No hay recepciones Seleccionadas</Typography>
          }
        </Box>
      </Box>
      <ContactFormBodyNew modal={true} />
    </Box>
   );
}
 
export default ContactoForm;